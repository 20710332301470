import { TestTypes } from "constants/TestTypes";
import LabAddCheckupSiteSpecs from "./Lab/LabAddCheckupSiteSpecs";
import FieldAddCheckupSiteSpecs from "./Field/FieldAddCheckupSiteSpecs";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import useGetTestsTemplatesForFieldTest from "hooks/useGetTestsTemplatesForFieldTest";
import { useLocation } from "react-router-dom";
import { _scrollToTop } from "utils/DOM/DOM";
import { AlertTypes } from "constants/AlertTypes";
import { checkStationsOverlapping } from "utils/Station/Station";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { useState } from "react";
import PropTypes from "prop-types";

const AddLicenseDetails = (props) => {
  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    unregister,
    setError,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      ...raiseNewRequestData?.[`stage-${props.timeline.currentStage}-formData`],
    },
  });
  const formData = watch();

  const dispatch = useDispatch();

  const location = useLocation();

  const state = location.state;

  const [isTestsTemplatesFetching, setIsTestsTemplatesFetching] =
    useState(false);

  const selectedLicenseData = state?.selectedLicenseData;

  const isLab = raiseNewRequestData.testType === TestTypes.LAB_TEST;
  const isField = raiseNewRequestData.testType === TestTypes.FIELD_TEST;

  const { getTestTemplateField } = useGetTestsTemplatesForFieldTest(formData);

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    if (isField && checkStationsOverlapping(formData)?.length > 0) {
      _scrollToTop();
      props.indexShowAlertHandler({
        show: true,
        message: ErrorCodes.STATIONS_STARTPOINT_IS_OVERLAPPING,
        type: AlertTypes.ERROR,
      });
      return;
    }

    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          [`stage-${props.timeline.currentStage}-formData`]: formData,
        },
      })
    );

    if (isField) {
      setIsTestsTemplatesFetching(true);

      getTestTemplateField(formData)
        .then((isReadyToGo) => {
          setIsTestsTemplatesFetching(false);

          return isReadyToGo;
        })
        .then((isReadyToGo) => {
          if (isReadyToGo) {
            props.indexHideAlertHandler();
            props.timeline.goNext();
          } else {
            _scrollToTop();
            props.indexShowAlertHandler({
              show: true,
              message: ErrorCodes.UNKNOWN_ERROR,
              type: AlertTypes.ERROR,
            });
          }
        });
    }
    if (isLab) {
      props.indexHideAlertHandler();
      props.timeline.goNext();
    }
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const sharedProps = {
    formData,
    setValue,
    clearErrors,
    unregister,
    setError,
    errors,
    control,
    register,
    raiseNewRequestData,
    timeline: props.timeline,
    selectedLicenseData,
    isTestsTemplatesFetching,
  };

  const render = () => {
    if (isLab) {
      return <LabAddCheckupSiteSpecs {...props} {...sharedProps} />;
    } else if (isField) {
      return <FieldAddCheckupSiteSpecs {...props} {...sharedProps} />;
    } else {
      return <BaseLoader />;
    }
  };

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
    >
      {render()}
    </form>
  );
};

AddLicenseDetails.propTypes = {
  timeline: PropTypes.object,
  indexShowAlertHandler: PropTypes.func,
  indexHideAlertHandler: PropTypes.func,
};

export default AddLicenseDetails;
