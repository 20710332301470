import { Box, Typography, useMediaQuery } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import { formatDateYYYYMMDD, renderDateTimeInAMPM } from "utils/Date/Date";
import BaseModal from "core-ui/BaseModal/BaseModal";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import { useState } from "react";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import { defaultEmptyValue } from "constants/Empty";
import {
  contractorExaminationDetailsTests,
  contractorExaminationDetailsTestsVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import LicenseLink from "components/LicenseLink/LicenseLink";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { useTheme } from "@emotion/react";
import BaseTableDoubleCardResponsive from "core-ui/BaseTable/BaseTableDoubleCardResponsive/BaseTableDoubleCardResponsive";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

const ExaminationDataField = ({
  stations,
  dataId,
  request,
  labtestappointment,
  isFetching,
  canReschedule,
  isStationsFetching,
  columns,
}) => {
  const { t } = useTranslation(["dashboard"]);

  const { breakpoints } = useTheme();

  const md = useMediaQuery(breakpoints.down("md"));

  const inspectionLayer = request?.inspectionLayersList?.[0];

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const [showStation, setShowStation] = useState(false);

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const createdAtDateObject = new Date(request?.createdAt);

  const requestCreatedAtTime = renderDateTimeInAMPM(
    `${createdAtDateObject.getHours()}:${createdAtDateObject.getMinutes()}:00`
  );

  const onLeavePageHandler = () => setShowStation(false);

  const { hasVirtual, tableContent } = useGetVirtualStations(stations);

  const tableHeader = hasVirtual
    ? contractorExaminationDetailsTestsVS
    : contractorExaminationDetailsTests({
        show: request?.status !== "SKIPPED",
        isAsphalt,
      });

  const isTechnicianConfirmed = tableContent?.some(
    (station) =>
      station.maxProctorConfirmation === true ||
      station.maxProctorConfirmation === false
  );

  const isRequestDone =
    request?.status === "SUCCEED" || request?.status === "FAILED";

  const singleLicense = request?.licenses?.[0];

  const tableProps = {
    title: "الاختبارات",
    description:
      "تم تحديد الاختبارات التالية بناء على نوع اختبار مواد الأعمال المدنية و نوع مسار الفحص",
    content: tableContent || [],
    tableHeader,
    isLoading: isStationsFetching,
    emptyData: {
      title: t("No new license"),
      desc: t("No new license desc"),
      img: publicAssetsPath("images/documents.svg"),
    },
    injectProps: {
      tableId: TABLE_ID.SUB_TEST_STATUS,
      isRequestDone,
      extraStatus: request?.status,
      mySpanColumns,
      setMySpanColumns,
      myHiddenColumns,
      setMyHiddenColumns,
      shouldDisplayTheResult: isTechnicianConfirmed,
      submittedFromLabAt: request?.submittedAt,
    },
  };

  return (
    <Box>
      <BaseModal
        show={showStation}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>

      <Box className={"request-data-details"} mt={4}>
        <BaseAlert
          show={canReschedule}
          type={AlertTypes.WARNING}
          labelSx={{ fontWeight: "bold", color: "black" }}
          message={"REQUEST_IS_OUTDATED"}
          icon={false}
        />
        <Box className={"request-data-details__wrapper"} mb={5}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.id}
              </Typography>
            </Box>
            <LicenseLink license={singleLicense} />
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الفحص
              </Typography>
              <BaseStatus
                status={request?.status}
                dataId={dataId}
                testType={request?.testType}
                appointment={request?.appointment}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإنشاء
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(request?.createdAt)}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                وقت الإنشاء
              </Typography>
              <Typography className={"request-data-details__value"}>
                {requestCreatedAtTime}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                {"اختبار مواد الأعمال المدنية"}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{ direction: "rtl" }}
              >
                {request?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                مسار الفحص
              </Typography>
              <BaseTypeTag type={request?.testType} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.contractorEstimatedTime || "لايوجد"} أيام
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نقاط الـ Stations
              </Typography>
              <Typography
                className={"request-data-details__value"}
                onClick={() => setShowStation(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  cursor: "grab",
                }}
              >
                نقاط الـ Stations{" "}
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                  alt="stations-popout"
                />
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        {request?.cancellationReason && (
          <>
            <BaseAlert
              mb={5}
              mt={5}
              show={true}
              labelSx={{ fontWeight: "bold", color: "black" }}
              type={AlertTypes.WARNING}
              icon={false}
            >
              <Typography
                sx={{
                  fontSize: "inherit !important",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                سبب إلغاء الطلب:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "normal",
                }}
              >
                {request?.cancellationReason}
              </Typography>
            </BaseAlert>
            <hr />
          </>
        )}
        {request?.techName && request?.techMobile && request?.techEmail && (
          <>
            <Box className={"request-data-details__wrapper"}>
              <Typography className={"request-data-details__title"}>
                بيانات فني الرقابة
              </Typography>
              <WrapperContentGrid gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    الاسم
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {request?.techName || defaultEmptyValue}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    البريد الإلكتروني
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {request?.techEmail || defaultEmptyValue}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الجوال
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatPhoneNumber(request?.techMobile) ||
                      defaultEmptyValue}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
            <hr />
          </>
        )}
        <Box className={"request-data-details__wrapper"} mb={5}>
          <Typography className={"request-data-details__title"}>
            موعد الفحص الحقلي
          </Typography>

          <Typography
            sx={{ fontSize: "1.6rem" }}
            color={"grayDark.color1"}
            mb={5}
          >
            يجب التنسيق والاتفاق مع الاستشاري خارج المنصة لحضور موعد الفحص
            الحقلي
          </Typography>

          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"} pb={5}>
              <Typography className={"request-data-details__label"}>
                وقت الفحص
              </Typography>
              <Typography className={"request-data-details__value"}>
                {renderDateTimeInAMPM(labtestappointment?.scheduledTime)}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الفحص
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(labtestappointment?.scheduledDate)}
              </Typography>
            </Box>
          </WrapperContentGrid>
          <hr />
        </Box>
      </Box>

      <Box mt={5}>
        {isFetching || isStationsFetching ? (
          <BaseLoader />
        ) : (
          <SmartDoubleCardTable
            tableProps={tableProps}
            mobile={{
              injectProps: {
                ...tableProps.injectProps,
                longColumnGridDirection: "row",
              },
            }}
            desktop={{
              injectProps: {
                ...tableProps.injectProps,
                longColumnGridDirection: "column",
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

ExaminationDataField.propTypes = {
  stations: PropTypes.array,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  request: PropTypes.object,
  labtestappointment: PropTypes.object,
  isFetching: PropTypes.bool,
  canReschedule: PropTypes.bool,
  isStationsFetching: PropTypes.bool,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ExaminationDataField;
