import BaseTableDoubleCardResponsive from "core-ui/BaseTable/BaseTableDoubleCardResponsive/BaseTableDoubleCardResponsive";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import useGetBreakpoint from "hooks/useGetBreakpoint";

import PropTypes from "prop-types";

const SmartDoubleCardTable = (props) => {
  const lg = useGetBreakpoint();

  return lg ? (
    <BaseTableDoubleCardResponsive {...props.tableProps} {...props.mobile} />
  ) : (
    <BaseTableDoubleCard {...props.tableProps} {...props.desktop} />
  );
};

SmartDoubleCardTable.propTypes = {
  tableProps: PropTypes.object,
  mobile: PropTypes.object,
  desktop: PropTypes.object,
};

export default SmartDoubleCardTable;
