import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const useGetBreakpoint = (size = "lg") => {
  const { breakpoints } = useTheme();
  const breakpoint = useMediaQuery(breakpoints.down(size));

  return breakpoint;
};

export default useGetBreakpoint;
