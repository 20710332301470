import { Box, Typography } from "@mui/material";
import { SELF_SUPERVISION, UsersRolesEnum } from "constants/UsersTypes";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import PropTypes from "prop-types";
import React from "react";
import { formatPhoneNumber } from "utils/Numbers/Numbers";

const FieldConsultantData = ({ data, requestTypeId }) => {
  const consultantInfo = data?.consultantEmail || data?.consultantMobile;
  const isHasPendingRequest = data?.hasPendingRequest;
  const valueSx = {
    fontSize: "1.6rem",
    color: "base.black",
    wordBreak: "break-word",
  };

  const isConsultant = requestTypeId === UsersRolesEnum.CONSULTANT;
  const isSelfSupervision = requestTypeId === SELF_SUPERVISION;

  if (!data && isConsultant) {
    return (
      <Wrapper>
        <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
          {"يرجى تحديد الرخصة حتى يتم عرض بيانات الاستشاري."}
        </Typography>
      </Wrapper>
    );
  }

  if (data && !isSelfSupervision && !isHasPendingRequest && !data?.consultant) {
    return (
      <Wrapper variant={"error"}>
        <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
          {
            "لا يمكن رفع طلب فحص على هذه الرخصة بسبب عدم إسنادها لاستشاري، يرجى التواصل مع الاستشاري المسؤول وطلب إسنادها."
          }
        </Typography>
      </Wrapper>
    );
  }

  if (data && isConsultant && isHasPendingRequest) {
    return (
      <Wrapper variant={"error"}>
        <Typography sx={{ fontSize: "1.6rem", textAlign: "center" }}>
          {"يوجد طلب إسناد سابق لهذه الرخصة، يرجى قبوله لتتمكن من رفع الطلب."}
        </Typography>
      </Wrapper>
    );
  }

  if (data && consultantInfo && (data?.consultant || data?.isSelfSupervision)) {
    return (
      <Wrapper>
        <BaseCardTitle mb={3} sx={{ fontSize: "1.6rem" }}>
          {isConsultant ? "بيانات الاستشاري" : "بيانات المشرف"}
        </BaseCardTitle>
        <Box
          variant={"secondary"}
          px={"3rem"}
          py={"3rem"}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            columnGap: "6rem",
            rowGap: "4rem",
          }}
        >
          <BaseDataItem
            label={"الاسم"}
            valueComponentAs={"div"}
            value={<Typography sx={valueSx}>{data?.consultantName}</Typography>}
          />
          <BaseDataItem
            label={"رقم الجوال"}
            valueComponentAs={"div"}
            value={
              <Typography sx={valueSx}>
                {formatPhoneNumber(data?.consultantMobile)}
              </Typography>
            }
          />
          <BaseDataItem
            label={"البريد الإلكتروني"}
            valueComponentAs={"div"}
            value={
              <Typography sx={valueSx}>{data?.consultantEmail}</Typography>
            }
          />
        </Box>
      </Wrapper>
    );
  }
};

function Wrapper(props) {
  return (
    <BaseCard py="2rem" px="2rem" variant={props.variant || "secondary"}>
      {props.children}
    </BaseCard>
  );
}

Wrapper.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

FieldConsultantData.propTypes = {
  data: PropTypes.object,
  requestTypeId: PropTypes.string,
  consultantName: PropTypes.string,
};

export default FieldConsultantData;
