import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import LongLength from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongLength/LongLength";
import ValueUpdatedScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/ValueUpdatedScore/ValueUpdatedScore";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import DoubleLayerData from "./DoubleLayerData";
import { KeyboardArrowDown } from "@mui/icons-material";
import ValueCurrentScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/ValueCurrentScore/ValueCurrentScore";
import PropTypes from "prop-types";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

function TestTableLab({ data, isAsphalt }) {
  const subTests = data
    .map((subTest) => subTest.inspectionSubTestEdits)
    .filter((subTestEdits) => subTestEdits && subTestEdits.length > 0);

  const adminEditRequestLabTests = useMemo(
    () => [
      { serverKey: "testName", name: "Test" },
      {
        serverKey: "testStandard",
        hasComponent: { customProps: "testStandard", comp: <LongLength /> },
        fallback: "لا يوجد",
        name: "testـstandards",
      },
      {
        serverKey: "currentScore",
        name: "النتيجة الحالية",
        hasComponent: {
          customProps: "currentScore",
          comp: <ValueCurrentScore />,
        },
      },
      {
        serverKey: "updatedScore",
        name: "النتيجة البديلة",
        hasComponent: {
          customProps: "updatedScore",
          comp: <ValueUpdatedScore />,
        },
      },
    ],
    [data]
  );

  const tableProps = {
    title: "الاختبارات",
    description:
      "تم تحديد الاختبارات التالية بناء على نوع اختبار مواد الأعمال المدنية و نوع مسار الفحص",
    tableHeader: adminEditRequestLabTests,
    content: data || [], // Use empty array if data is undefined
    // You can uncomment isLoading if needed
    // isLoading: isFetching,
    injectProps: {
      tableId: TABLE_ID.ADMIN_EDITED_REQUESTS_TESTS,
    },
    doubleLayer: () => ({
      visibility: true,
      component: (element) => (
        <DoubleLayerData
          subTests={subTests || []}
          asphalet={isAsphalt}
          element={element}
        />
      ),
    }),
    withSpecificAction: (element, triggeredElements) => {
      return {
        condition: element?.inspectionSubTestEdits.length > 0,
        actionType: "dropdown",
        component: (
          <KeyboardArrowDown
            sx={{
              fontSize: 38,
              cursor: "pointer",
              color: "#347A7C",
              transition: "all .5s ease",
              transform: triggeredElements?.[element.id]
                ? "rotate(180deg)"
                : "",
              marginRight: "10px",
            }}
          />
        ),
      };
    },
  };

  return (
    <Box>
      <SmartDoubleCardTable tableProps={tableProps} />
    </Box>
  );
}

TestTableLab.propTypes = {
  data: PropTypes.object,
  isAsphalt: PropTypes.bool,
};

export default TestTableLab;
