import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stationInputsActions } from "store/staions-inputs/station-inputs";

const MAX_STATIONS_NUMBER = 5;

const useStationsTable = ({ defaultStations, unregister }) => {
  const dispatch = useDispatch();
  const [stations, setStations] = useState(defaultStations || []);

  const [stationsHasErrors, setStationsHasErrors] = useState([]);
  const [lengthErr, setlengthErr] = useState([]);

  const defaultstationNumber = useSelector(
    (state) => state.stationInputs.stations
  );

  const areDefaultStationsValid =
    Array.isArray(defaultStations) && defaultStations.length > 0;

  useEffect(() => {
    if (areDefaultStationsValid) {
      const initialStations = defaultStations?.map((station) => ({
        ...station,
        removable: false,
      }));
      setStations(initialStations);
    }
  }, [areDefaultStationsValid]);

  const handleAddRow = () => {
    if (stations.length >= MAX_STATIONS_NUMBER) return;
    setStations((prevStations) => {
      const newId = crypto.randomUUID().substring(0, 5);
      const newStation = {
        id: newId,
        startPoint: "",
        endPoint: "",
        layerNumber: "",
        layerTest: "",
        length: "",
      };
      const updatedStations = [...prevStations, newStation];
      dispatch(stationInputsActions.setStations(updatedStations));
      return updatedStations;
    });
  };

  const handleRemoveRow = ({ id }) => {
    if (id === 0) return;
    setStations((prevStations) => {
      const updatedStations = [...prevStations];
      const itemToRemoveIndex = updatedStations.findIndex(
        (el) => el?.id === id
      );

      if (stationsHasErrors?.length > 0 && stationsHasErrors.includes(id)) {
        setStationsHasErrors((prevStations) => {
          const updatedEroorStations = [...prevStations];

          updatedEroorStations.splice(prevStations.indexOf(id), 1);
          return updatedEroorStations;
        });
      }

      if (unregister) {
        unregister(`station-${id}-from`);
        unregister(`station-${id}-to`);
        unregister(`station-${id}-layerNumber`);
        unregister(`station-${id}-layerTest`);
        unregister(`station-${id}-length`);
      }

      updatedStations.splice(itemToRemoveIndex, 1);
      dispatch(stationInputsActions.setStations(updatedStations));
      return updatedStations;
    });
  };

  useEffect(() => {
    if (!areDefaultStationsValid) {
      setStations(defaultstationNumber);
    }
  }, [defaultstationNumber, areDefaultStationsValid]);

  return {
    handleAddRow,
    handleRemoveRow,
    stations,
    stationsHasErrors,
    setStationsHasErrors,
    lengthErr,
    setlengthErr,
    isExceededTheLimit: stations.length >= MAX_STATIONS_NUMBER,
  };
};

export default useStationsTable;
