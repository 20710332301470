import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { defaultEmptyValue } from "constants/Empty";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { renderLabAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import { formatPhoneNumber, formattedNumber } from "utils/Numbers/Numbers";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import BaseModal from "core-ui/BaseModal/BaseModal";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import { publicAssetsPath } from "utils/Paths/Paths";
import {
  useGeStationNumberQuery,
  useGetAppointmentDetailsForLabQuery,
  useLazyGetTechnicianDataQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";

const FieldAssignedRequestDetails = (props) => {
  const { inspection } = props;

  const [getTechnicianData, technicianData] = useLazyGetTechnicianDataQuery();

  const { data: appointmentData } = useGetAppointmentDetailsForLabQuery(
    inspection?.id
  );

  const { data: stations } = useGeStationNumberQuery({ id: inspection?.id });

  useEffect(() => {
    if (inspection) {
      getTechnicianData(inspection?.id);
    }
  }, [inspection]);

  const [showStation, setShowStation] = useState(false);
  const onLeavePageHandler = () => setShowStation(false);

  return props.isInspectionFetching ? (
    <BaseLoader />
  ) : (
    <Box>
      <BaseModal
        show={showStation}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>
      <Box className={"request-data-details"} mt={4}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          {/* Request-data-details */}
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.id || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ القبول
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(inspection?.labAssignedAt) ||
                  defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                وقت القبول
              </Typography>
              <Typography className={"request-data-details__value"}>
                {displayFormattedTimeHHMM(inspection?.labAssignedAt) ||
                  defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اسم الاختبار
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تكلفة الاختبار
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labPrice
                  ? `${formattedNumber(inspection?.labPrice)} ريال`
                  : defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نوع الفحص
              </Typography>
              <BaseTypeTag type={inspection?.testType || defaultEmptyValue} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الطلب
              </Typography>
              <BaseStatus
                status={inspection?.status}
                dataId={props.dataId}
                testType={inspection?.testType}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة العينة
              </Typography>
              <BaseStatus
                status={inspection?.samplestatus}
                extraStatus={props.requestDetails?.samplestatus}
                dataId={props.dataId}
              />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                1 أيام
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الفني
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labTechName}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نقاط الـ Stations
              </Typography>
              <Typography
                className={"request-data-details__value"}
                onClick={() => setShowStation(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  cursor: "grab",
                }}
              >
                نقاط الـ Stations{" "}
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                  alt="stations-popout"
                />
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            موعد الفحص
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الموقع
              </Typography>
              <LocationField location={inspection?.licenses[0]?.locationUrl} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الوقت
              </Typography>
              <Typography className={"request-data-details__value"}>
                {renderLabAppointmentTimeLabel(appointmentData?.scheduledTime)}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                التاريخ
              </Typography>
              <Typography className={"request-data-details__value"}>
                {`${formatDateYYYYMMDD(appointmentData?.scheduledDate)}`}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            بيانات الاستشاري
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.licenses[0]?.consultantName || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatPhoneNumber(inspection?.licenses[0]?.consultantMobile) ||
                  defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.licenses[0]?.consultantEmail || defaultEmptyValue}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            بيانات فني الرقابة
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم
              </Typography>
              <Typography className={"request-data-details__value"}>
                {technicianData?.data?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني
              </Typography>
              <Typography className={"request-data-details__value"}>
                {technicianData?.data?.email || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatPhoneNumber(technicianData?.data?.mobile) ||
                  defaultEmptyValue}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
    </Box>
  );
};

FieldAssignedRequestDetails.propTypes = {
  inspection: PropTypes.object,
  requestDetails: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isInspectionFetching: PropTypes.bool,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FieldAssignedRequestDetails;
