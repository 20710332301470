import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import {
  assignedRequestTestsFeildSubmitted,
  assignedRequestTestsFeildSubmittedVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import BaseVerticalTimeline from "core-ui/BaseVerticalTimeline/BaseVerticalTimeline";
import useVerticalTimeline from "hooks/useVerticalTimeline";
import RaiseResults from "modules/Laboratory/pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Lab/RaiseResults/RaiseResults";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

const AssignedRequestTestsFeild = (props) => {
  const { hasVirtual, tableContent } = useGetVirtualStations(props.stations);

  const isAsphalt = inspectionLayerFlagChecker(
    props.inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const tableHeader = hasVirtual
    ? assignedRequestTestsFeildSubmittedVS({ user: "lab", isAsphalt })
    : assignedRequestTestsFeildSubmitted({ user: "lab", isAsphalt });

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const isSubmitted = props.inspection?.submittedAt;

  const { reanimate, onHide, vTimelineRef } = useVerticalTimeline();

  useEffect(() => {
    if (vTimelineRef?.current) {
      if (isSubmitted) {
        onHide();
      }
    }
    if (!props.isStationsFetching) {
      reanimate();
    }
  }, [vTimelineRef, isSubmitted, props.isStationsFetching]);

  if (props.isStationsFetching) return <BaseLoader />;

  const tableProps = {
    content: tableContent,
    tableHeader: tableHeader,
    injectProps: {
      mySpanColumns,
      setMySpanColumns,
      myHiddenColumns,
      setMyHiddenColumns,
      submittedFromLabAt: true,
    },
  };

  return (
    <>
      <Box mt={5}>
        <SmartDoubleCardTable
          tableProps={tableProps}
          desktop={{
            injectProps: {
              ...tableProps.injectProps,
              longColumnGridDirection: "column",
            },
          }}
          mobile={{
            injectProps: {
              ...tableProps.injectProps,
              longColumnGridDirection: "row",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          marginInlineStart: isSubmitted ? "" : "5rem",
        }}
      >
        <BaseVerticalTimeline sx={{ marginBlock: 5 }} ref={vTimelineRef}>
          {!isSubmitted && (
            <RaiseResults form={"field"} isSubmitted={isSubmitted} />
          )}
        </BaseVerticalTimeline>
      </Box>
    </>
  );
};

AssignedRequestTestsFeild.propTypes = {
  inspection: PropTypes.object,
  stations: PropTypes.array,
  isStationsFetching: PropTypes.bool,
};

export default AssignedRequestTestsFeild;
