import { Box } from "@mui/system";
import PropTypes from "prop-types";

const WrapperContentGrid = (props) => {
  const gap = `calc(${props.gap || 0.2} * 2vw)`;
  const gapX = `calc(${props.gapX || 0.2} * 2vw)`;
  const gapY = `calc(${props.gapY || 0.2} * 2vw)`;

  const wrapperContentGridSx = {
    display: "flex",
    flexWrap: props.flexWrap ? props.flexWrap : "wrap",
    gap: gap,
    rowGap: props.gapY ? gapY : "auto",
    columnGap: props.gapX ? gapX : "auto",
  };

  return (
    <Box sx={{ ...props.sx, ...wrapperContentGridSx }}>{props.children}</Box>
  );
};

WrapperContentGrid.propTypes = {
  gap: PropTypes.number,
  gapX: PropTypes.number,
  gapY: PropTypes.number,
  flexWrap: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default WrapperContentGrid;
