import { AlertTypes } from "constants/AlertTypes";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useInspectionCertificatesMutation } from "modules/Contractor/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { previwPDFFromBlobToFile } from "utils/ApiHelpers";

const ViewCertificateButton = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [certificatesView, certificatesViewResponse] =
    useInspectionCertificatesMutation();

  const openCertificateInNewTab = () => {
    certificatesView({
      licenseNumber: decodeURIComponent(props.licenseData?.licenseNumber),
      licenseYear: props.licenseData?.licenseYear,
    })
      .unwrap()
      .then((response) => {
        const pdfData = response.base64;
        const { newTab, pdfContent } = previwPDFFromBlobToFile(pdfData);
        newTab.document.write(`
                  <html>
                  <head>
                  <title>شهادة إتمام الفحص</title>
                </head>
                    <body>
                    <embed src="${pdfContent}" width="100%" height="100%" type="application/pdf">
                    </body>
                  </html>
                `);
      })
      .catch((error) => {
        console.error(error);
        props.showAlertHandler({
          show: true,
          message: error.data.errorCode,
          type: AlertTypes.WARNING,
        });
      });
  };
  return (
    <BaseButton
      sx={{ width: "18rem" }}
      onClick={openCertificateInNewTab}
      isLoading={certificatesViewResponse?.isLoading}
    >
      {t("view certificate")}
    </BaseButton>
  );
};

ViewCertificateButton.propTypes = {
  licenseData: PropTypes.object,
  showAlertHandler: PropTypes.func,
};

export default ViewCertificateButton;
