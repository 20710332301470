import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, FormHelperText } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { Controller, useForm } from "react-hook-form";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch } from "react-redux";
import { technicianActions } from "store/technician-slice/technician-slice";
import {
  useGetInspectionForTechnicianQuery,
  usePostCollectedSamplesDataMutation,
  useSaveSampleAttachmentMutation,
} from "modules/Technician/apis/inspection-apis/inspection-api";
import BaseModal from "core-ui/BaseModal/BaseModal";
import CollectSampleDataModal from "components/AllModalContent/CollectSampleDataModal/CollectSampleDataModal";
import {
  MaterialSource,
  PlaceOfTakeSample,
} from "services/StaticLookup/CollectData";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { fromPage } from "constants/FromPages";
import BaseCamera from "core-ui/BaseCamera/BaseCamera";
import { convertBlobToFile, base64ToBlob } from "utils/ApiHelpers";

function CollectSampleForm() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();

  const [photo, setPhoto] = useState(null);

  const [postCollectedSamplesData, postCollectedSamplesResponse] =
    usePostCollectedSamplesDataMutation();

  const [saveSampleAttachment, saveSampleAttachmentResponse] =
    useSaveSampleAttachmentMutation();

  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const params = useParams();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [showCamera, setShowCamera] = useState(false);

  const { data: inspection } = useGetInspectionForTechnicianQuery(params.id);

  const openCameraHandler = () => {
    setShowCamera(true);
  };
  const closeCameraHandler = () => {
    setShowCamera(false);
  };
  const onChangePhoto = (photo) => {
    setPhoto(photo);
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const { completeStage } = useOutletContext();

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    const blob = base64ToBlob(photo.photo);

    let fileOfBlob = convertBlobToFile(blob);

    const formData = new FormData();

    formData.append("file", fileOfBlob);

    const idealData = {
      // type: data.type.name,
      numberOfSamples: data.numberOfSamples,
      materialSource: data.materialSource.name,
      location: data.location.name,
      weatherCondition: data.weatherCondition,
      contractorPresent: !!+data.contractorPresent,
      inspectionId: inspection.id, // WARNING
      assignmentId: inspection.id,
    };

    postCollectedSamplesData(idealData)
      .unwrap()
      .then((responseData) => {
        dispatch(technicianActions.saveCollectedSampleData({ collectedSampleData: {...idealData} }));
        return responseData;
      }).then(responseData => {
        saveSampleAttachment({
          body: formData,
          uid: responseData.code,
        }).unwrap().then( _ => {
          closeCameraHandler();
          completeStage(1);
          
          navigate(`/collect-samples-requests/${params.id}`, {
            state: { fromPageId: fromPage.COLLECT_TECH_DATA_UPDATE_SUCCESS },
          });
        }).catch(err => {
          showAlertHandler({show: true, message: err.data.errorCode, type: AlertTypes.ERROR})
        })
      })
      .catch((err) => {
        showAlertHandler({show: true, message: err.data.errorCode, type: AlertTypes.ERROR})
      }).finally(_ => window.scrollTo(0, 0))

    //prettier-ignore
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
    // submit values
  };

  const onCatchLeavingThePageHandler = (data, e) => {
    setShowConfirm(true);
  };

  const onLeavePageHandler = () => {
    // ADD RESET TO COLLECTED SAMPLE DATA HERE FROM REDUX
    setShowConfirm(false);
  };

  return (
    <Box className="register-form">
      <BaseModal
        show={showConfirm}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <CollectSampleDataModal onHide={onLeavePageHandler} />
      </BaseModal>
      <BaseAlert
        mb={5}
        show={alert.show}
        type={alert.type}
        message={alert.message}
        destroy={hideAlertHandler}
      />
      <form
        aria-label="form"
        onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      >
        {/* تفاصيل العينة */}
        <BaseCardTitle>تفاصيل العينة</BaseCardTitle>
        <WrapperContentGrid
          flexWrap="nowrap"
          sx={{ marginBottom: "4rem", flexDirection: "row", mt: "3rem" }}
          gap={5}
        >
          <BaseDropdown
            control={control}
            name="materialSource"
            required
            errors={errors}
            label={t("MaterialSource")}
            labelStyles={{
              fontWeight: "bold",
              color: "grayDark.color",
            }}
            placeholder={t("MaterialSource")}
            data={MaterialSource}
            selectMenuStyles={{ height: "12rem !important" }}
          />
          <BaseInput
            htmlFor="numberOfSamples"
            label={t("sampleNo")}
            labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
            name="numberOfSamples"
            type="number"
            min="1"
            register={{
              ...register("numberOfSamples", {
                required: {
                  value: true,
                  message: `${t("field")} ${t("required")}`,
                },
                min: {
                  value: 1,
                  message: `${t("الرقم غير صحيح")}`,
                },
                max: {
                  value: 10,
                  message: `لقد تجاوت الحد المسموح به`,
                },
                pattern: {
                  value: /[0-9]$/,
                  message: `${t(" يجب ان يكون رقم صحيح")}`,
                },
              }),
            }}
            errors={errors}
          />
        </WrapperContentGrid>

        {/* تفاصيل الجمع */}
        <BaseCardTitle>تفاصيل الجمع</BaseCardTitle>
        <WrapperContentGrid
          sx={{ marginBottom: "4rem", flexDirection: "row", mt: "3rem" }}
          gap={5}
          flexWrap="nowrap"
        >
          <BaseInput
            htmlFor="weatherCondition"
            label={t("Temperature")}
            labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
            name="weatherCondition"
            step="0.1"
            type="number"
            register={{
              ...register("weatherCondition", {
                pattern: {
                  value: /[0-9]$/,
                  message: `${t(" يجب ان يكون رقم صحيح")}`,
                },
              }),
            }}
            errors={errors}
          />
          <BaseDropdown
            control={control}
            name="location"
            required
            errors={errors}
            label={t("placeSampleTaken")}
            labelStyles={{
              fontWeight: "bold",
              color: "grayDark.color",
            }}
            placeholder={t("placeSampleTaken")}
            data={PlaceOfTakeSample}
            selectMenuStyles={{ height: "12rem !important" }}
          />
        </WrapperContentGrid>

        <Typography
          sx={{
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          هل كان المقاول متواجد في الموقع عند جمع العينة؟
        </Typography>
        <Box sx={{ mb: "5rem" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5} mt={1}>
            <Controller
              name="contractorPresent"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `يجب عليك الاختيار`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <RadioGroup row name="accept" onChange={onChange}>
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checkedIcon={<CheckCircleIcon />}
                          sx={{
                            color: "primary.colorSoft6",
                            "&.Mui-checked": {
                              color: "secondary.main",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: "1.4rem" }}>نعم</Typography>
                      }
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checkedIcon={<CheckCircleIcon />}
                          sx={{
                            color: "primary.colorSoft6",
                            "&.Mui-checked": {
                              color: "secondary.main",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: "1.4rem" }}>لا</Typography>
                      }
                    />
                  </RadioGroup>

                  {errors.contractorPresent && (
                    <FormHelperText
                      error
                      variant="error"
                      sx={{ fontSize: "1.1rem" }}
                    >
                      {errors.contractorPresent.message}
                    </FormHelperText>
                  )}
                </Box>
              )}
            />
          </Stack>
        </Box>

        <Box sx={{ mt: "2rem", mb: 7 }}>
          <Controller
            control={control}
            name="attachmentUrl"
            rules={{
              required: {
                value: true,
                message: `${t("field")} ${t("uploadedFile")} ${t("required")}`,
              },
            }}
            render={({ field: { onChange, value, name, ref } }) => {
              return showCamera ? (
                <BaseCamera
                  name={name}
                  onFormChange={onChange}
                  show={showCamera}
                  onHide={closeCameraHandler}
                  mainContainerSx={{ width: "130rem" }}
                  onChange={onChangePhoto}
                  // onUpload={onUploadPhoto}
                  isSuccessUpload={saveSampleAttachmentResponse.isSuccess}
                />
              ) : (
                <BaseButton onClick={openCameraHandler}>تصوير</BaseButton>
              );
            }}
          />

          {errors.attachmentUrl && (
            <FormHelperText error variant="error" sx={{ fontSize: "1.1rem" }}>
              {errors.attachmentUrl.message}
            </FormHelperText>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box display={"flex"} flexWrap={"wrap"} gap={3}>
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              variant={"secondary"}
              onClick={onCatchLeavingThePageHandler}
              disabled={postCollectedSamplesResponse.isLoading}
            >
              {t("تراجع")}
            </BaseButton>
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              type={"submit"}
              isLoading={postCollectedSamplesResponse.isLoading}
            >
              {t("إرسال")}
            </BaseButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default CollectSampleForm;
