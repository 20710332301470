import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import BaseRecaptcha from "core-ui/BaseRecaptcha/BaseRecaptcha";
import { useRef } from "react";

const AssignLabRequestModal = (props) => {
  const { t } = useTranslation(["dashboard"]);
  const recaptchaRef = useRef();

  return (
    <>
      <Controller
        control={props.control}
        name="recaptcha"
        rules={{
          required: {
            value: true,
            message: `${t("field", { ns: "auth" })} ${t("Recaptcha", {
              ns: "auth",
            })} ${t("required", { ns: "auth" })}`,
          },
        }}
        render={({ field: { onChange, value, name } }) => {
          return (
            <BaseRecaptcha
              ref={recaptchaRef}
              name={name}
              onChange={onChange}
              errors={props.errors}
            />
          );
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
          gap: "2rem",
          mt: 3,
        }}
      >
        <BaseButton
          sx={{
            minWidth: "11.2rem",
          }}
          variant={"secondary"}
          onClick={props.onHide}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          isLoading={props.isLoading}
          sx={{ minWidth: "11.2rem" }}
          type="submit"
        >
          {t("تأكيد الإسناد")}
        </BaseButton>
      </Box>
    </>
  );
};

AssignLabRequestModal.propTypes = {
  step: PropTypes.number,
  control: PropTypes.object,
  errors: PropTypes.object,
  labTechniciansData: PropTypes.array,
  onHide: PropTypes.func,
  selectedLabTechnician: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  nextStepHandler: PropTypes.func,
  confirmMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default AssignLabRequestModal;
