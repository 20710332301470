import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import DashboardDrillingLicensesData from "components/Dashboard/DashboardDrillingLicensesData/DashboardDrillingLicensesData";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useTranslation } from "react-i18next";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import AddConsultantForm from "components/Forms/AddConsultantForm/AddConsultantForm";
import { SELF_SUPERVISION } from "constants/UsersTypes";
import FieldConsultantData from "./FieldConsultantData/FieldConsultantData";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import { fromPage } from "constants/FromPages";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { customValidateMobileNumber } from "utils/Forms/Forms";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import {
  containsArabicLetters,
  customValidateLicenseNumber,
} from "utils/Strings/Strings";
import { isProduction, isUAT } from "utils/Env/Env";

const FieldAddLicenseDetails = (props) => {
  const { t } = useTranslation(["auth", "dashboard", "common"]);

  const location = useLocation();

  const isSearchVisible =
    location?.state?.fromPage !== fromPage.RAISE_NEW_REQUEST_LICENSE_DETAILS;

  const {
    setValue,
    formData,
    selectedLicenseData,
    requestTypeId,
    licenseErrorMessage,
    dispatch,
    raiseNewRequestData,
    alert,
    consultantName,
    getContractorLicenseHandler,
    contractorLicenseResponse,
    dropdownRef,
    initialMainDropdownData,
    currentStage,
    errors,
  } = props;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const isFlagged = isProduction || isUAT;

  const isSearchBtnDisabled =
    !!errors.licenseNumber ||
    !formData.licenseNumber ||
    contractorLicenseResponse.isLoading ||
    (isFlagged && !formData.licenseYear);

  useEffect(() => {
    if (!isSearchVisible) {
      dispatch(
        contractorActions.saveRaiseNewRequestData({
          data: {
            licenseData: selectedLicenseData,
          },
        })
      );
    }
    if (selectedLicenseData?.contractorMobileNumber) {
      setValue(
        "contractorMobileNumber",
        selectedLicenseData?.contractorMobileNumber
      );
    }
  }, [selectedLicenseData, isSearchVisible]);

  useEffect(() => {
    setValue("licenseNumber", currentStage?.licenseNumber);
    setValue("licenseYear", currentStage?.licenseYear);
  }, [currentStage]);

  const isValidLicenseData = !!raiseNewRequestData?.licenseData;
  const hasConsultant = !!raiseNewRequestData?.licenseData?.consultant;
  const isHasPendingRequest =
    raiseNewRequestData?.licenseData?.hasPendingRequest;
  const isSelfSupervision = requestTypeId === SELF_SUPERVISION;

  const hasNoConsultantAndSupervision =
    isValidLicenseData &&
    !isSelfSupervision &&
    !isHasPendingRequest &&
    !hasConsultant;

  return (
    <>
      <Grid container>
        <Grid item lg={5} xs={12}>
          {isSearchVisible && (
            <Box>
              <BaseCardTitle mb={5}>البحث عن رخصة</BaseCardTitle>
              <Stack
                direction={"row"}
                flexWrap={"wrap"}
                alignItems={"flex-start"}
                gap="2rem"
              >
                <BaseInput
                  inputContainerStyles={{
                    flexGrow: "1",
                    flexBasis: "24rem",
                    marginBottom: errors.licenseNumber ? -2.5 : 0,
                    alignSelf: "end",
                  }}
                  htmlFor="licenseNumber"
                  label={"رقم الرخصة"}
                  placeholder={"ادخل رقم الرخصة"}
                  name="licenseNumber"
                  inputStyles={{
                    direction: "ltr",
                    textAlign: "end",
                  }}
                  register={{
                    ...props.register("licenseNumber", {
                      required: {
                        value: true,
                        message: `${t("field")} ${t("license number", {
                          ns: "dashboard",
                        })} ${t("required")}`,
                      },
                      maxLength: {
                        value: 15,
                        message: `لا يمكن لرقم الرخصة ان يتجاوز 15 حرف`,
                      },
                      minLength: {
                        value: 4,
                        message: `يجب ان لا يقل رقم الرخصة عن 4 احرف`,
                      },
                      validate: customValidateLicenseNumber,
                    }),
                  }}
                  errors={errors}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                    flexGrow: 1,
                  }}
                >
                  <BaseDropdown
                    control={props.control}
                    withReset
                    required={isFlagged}
                    name="licenseYear"
                    dropdownRef={dropdownRef}
                    pickup={initialMainDropdownData.pickup}
                    value={formData?.licenseYear || {}}
                    errors={initialMainDropdownData.errors}
                    label={t(initialMainDropdownData.label)}
                    placeholder={initialMainDropdownData.placeholder}
                    data={initialMainDropdownData.data}
                    width={"14rem"}
                    labelStyles={{
                      color: "grayDark.color",
                      fontSize: "1.4rem",
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                    mainStyles={{
                      flexGrow: 1,
                    }}
                  />
                  <BaseButton
                    sx={{
                      minWidth: "8rem",
                      alignSelf: "end",
                      flexGrow: 1,
                      height: "4rem",
                    }}
                    variant={isSearchBtnDisabled ? "disabled" : "secondary"}
                    disabled={isSearchBtnDisabled}
                    isLoading={contractorLicenseResponse.isLoading}
                    onClick={() =>
                      getContractorLicenseHandler(undefined, undefined)
                    }
                  >
                    بحث
                  </BaseButton>
                </Box>
              </Stack>

              {licenseErrorMessage && (
                <Box mt={3}>
                  <BaseAlert
                    show={alert.show}
                    type={alert.type}
                    message={alert.message}
                    icon={false}
                    mt={2}
                  />
                </Box>
              )}
            </Box>
          )}

          {/* Selected SELF_SUPERVISION */}
          <Box mt={isSearchVisible ? 7 : 5}>
            {!raiseNewRequestData?.licenseData?.consultant &&
            !raiseNewRequestData?.licenseData?.isSelfSupervision &&
            requestTypeId === SELF_SUPERVISION ? (
              <AddConsultantForm
                register={props.register}
                errors={errors}
                control={props.control}
                consultantName={consultantName}
              />
            ) : (
              <Box mt={isSearchVisible ? 7 : 5}>
                <FieldConsultantData
                  requestTypeId={requestTypeId}
                  isSearchVisible={isSearchVisible}
                  data={raiseNewRequestData?.licenseData}
                />
              </Box>
            )}
          </Box>
          <BaseCard py="2rem" px="2rem" mt={"4rem"} variant={"secondary"}>
            <BaseCardTitle mb={3} sx={{ fontSize: "1.6rem" }}>
              {"بيانات المقاول"}
            </BaseCardTitle>
            <Box variant={"secondary"} px={"3rem"} py={"3rem"}>
              {raiseNewRequestData?.licenseData?.contractorMobileNumber ? (
                <BaseDataItem
                  label={"رقم جوال المقاول"}
                  value={
                    raiseNewRequestData?.licenseData?.contractorMobileNumber
                  }
                />
              ) : (
                <BaseInput
                  htmlFor="contractorMobile"
                  label={"رقم جوال المقاول"}
                  placeholder="05 xxx xxxx"
                  labelStyles={{
                    fontWeight: "bold",
                    color: "grayDark.color",
                  }}
                  name="contractorMobile"
                  register={{
                    ...props.register("contractorMobile", {
                      required: {
                        value: true,
                        message: "حقل رقم جوال المقاول مطلوب",
                      },
                      validate: customValidateMobileNumber,
                    }),
                  }}
                  errors={errors}
                />
              )}
            </Box>
          </BaseCard>
        </Grid>
        <Grid item lg={1} md={1} xs={12}>
          {/* I am offset column!.🔥 */}
        </Grid>
        <Grid item lg={6} xs={12} mt={matches ? 5 : 0}>
          <BaseCard
            variant="secondary"
            py={"0rem"}
            px={"0rem"}
            borderRadius=".9rem"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ minHeight: "55vh" }}
          >
            <DashboardDrillingLicensesData
              licenseErrorMessage={licenseErrorMessage}
              data={raiseNewRequestData?.licenseData}
              columns={11}
            />
          </BaseCard>
        </Grid>
      </Grid>
      <TimelineCallToActions
        timeline={props.timeline}
        isPrevButtonDisabled={contractorLicenseResponse.isLoading}
        isNextButtonDisabled={
          hasNoConsultantAndSupervision ||
          !raiseNewRequestData?.licenseData ||
          raiseNewRequestData?.licenseData?.hasPendingRequest
        }
      />
    </>
  );
};

FieldAddLicenseDetails.propTypes = {
  formData: PropTypes.object,
  timeline: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
  requestTypeId: PropTypes.string,
  licenseErrorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  raiseNewRequestData: PropTypes.object,
  alert: PropTypes.object,
  consultantName: PropTypes.string,
  contractorLicenseResponse: PropTypes.object,
  dropdownRef: PropTypes.object,
  initialMainDropdownData: PropTypes.object,
  currentStage: PropTypes.object,
  setValue: PropTypes.func,
  selectedLicenseData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dispatch: PropTypes.func,
  register: PropTypes.func,
  getContractorLicenseHandler: PropTypes.func,
};

export default FieldAddLicenseDetails;
