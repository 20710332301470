import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import { formattedNumber } from "utils/Numbers/Numbers";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import { baseCalendarDateFormatter } from "utils/Date/Date";
import { VAT_PERCENT } from "constants/Vat";
import { licenseFieldTestsSummary } from "services/StaticLookup/TableHeader/TableHeader";
import { renderAppointmentTimeLabel } from "utils/BookAppointment/BookAppointment";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import TimelineCallToActions from "modules/Contractor/components/TimelineCallToActions/TimelineCallToActions";
import RaiseNewRequestService from "../../RaiseNewRequestService";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";

const FieldAddCheckupSummary = (props) => {
  const { appointmentTime } = props;

  const { breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));

  const appointmentTimeHours =
    appointmentTime.$H < 10 ? `0${appointmentTime.$H}` : appointmentTime.$H;

  const appointmentTimeMinutes =
    appointmentTime.$m < 10 ? `0${appointmentTime.$m}` : appointmentTime.$m;

  const formatedTime = `${appointmentTimeHours}:${appointmentTimeMinutes}:00`;
  const timeLabel = renderAppointmentTimeLabel(formatedTime);

  const textSX = {
    fontSize: "1.6rem",
  };

  const flexClasses = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const alignedText = {
    width: "50%",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  };

  const justifyEnd = {
    justifyContent: "flex-end",
    paddingInlineStart: sm ? "10rem" : "",
    paddingInlineEnd: sm ? "" : "10rem",
  };

  const mainTableContent = props.testsTemplates.map((test) => ({
    category: test?.category,
    ...test.subTestTemplateDTOList[0],
  }));

  const estTime = props.testsTemplates?.reduce((prev, cur) => {
    return prev && prev?.estimatedTime > cur?.estimatedTime ? prev : cur;
  }, 0);

  const { fieldTotalTestsCost: totalTestsCost } =
    RaiseNewRequestService.getTotalCostForTest(props.raiseNewRequestData);

  return (
    <Box>
      <BaseDataWrapper mt={1} title={"ملخص الطلب"}>
        <BaseDataItem
          label={"مسار الفحص"}
          valueComponentAs={"div"}
          value={<BaseTypeTag type={props?.testType} />}
        />
        <BaseDataItem
          label={"تاريخ الفحص"}
          valueComponentAs={"p"}
          value={`${baseCalendarDateFormatter(
            props?.appointmentDate,
            "dddd, YYYY/MM/DD"
          )}`}
        />

        <BaseDataItem
          label={"وقت الفحص"}
          valueComponentAs={"p"}
          value={`${timeLabel}`}
        />

        <BaseDataItem
          label={" المدة المتوقعة"}
          value={`${estTime?.estimatedTime} أيام`}
        />
      </BaseDataWrapper>

      <BaseCardTitle>التكلفة</BaseCardTitle>
      <Typography sx={{ color: "grayDark.color1", fontSize: "1.6rem" }}>
        {` أسعار الاختبارات لا تشمل ضريبة القيمة المضافة (${VAT_PERCENT}%)`}
      </Typography>

      <Box mt={4}>
        <BaseTableIndex
          justifyColumnsBetween
          tableHeader={licenseFieldTestsSummary}
          content={mainTableContent || []}
          tableSx={{
            border: {
              tableCell: "0.1rem solid",
              color: "#f9f9f9",
              borderInlineColor: "transparent",
            },
          }}
          tableContainerSx={{ maxHeight: "100%" }}
        />
      </Box>

      {/* THIS BOX SHOULD BE A COMPONENT */}
      <Box sx={{ ...flexClasses, mb: 2 }}>
        <Typography
          sx={{ fontSize: "1.6rem", fontWeight: 500 }}
          color={"grayDark.color1"}
          ml={2}
        >
          {"التكلفة الكلية"}
        </Typography>
        <Box
          sx={{
            ...flexClasses,
            ...alignedText,
            ...justifyEnd,
            flexWrap: "nowrap",
          }}
        >
          <Typography
            sx={{
              ...textSX,
              fontSize: "2.2rem",
              ml: 1,
              color: "primary.colorSoft10",
              fontWeight: 500,
            }}
          >
            {formattedNumber(totalTestsCost)}
          </Typography>
          <Typography
            sx={{
              ...textSX,
              fontSize: "1.6rem",
              ml: 1,
              color: "base.black",
              fontWeight: 500,
            }}
          >
            نقطة{" "}
          </Typography>
        </Box>
      </Box>
      <TimelineCallToActions
        timeline={props.timeline}
        isPrevButtonDisabled={props.isLoading}
        isNextButtonDisabled={props.isSuccess || props.isLoading}
      />
    </Box>
  );
};

FieldAddCheckupSummary.propTypes = {
  timeline: PropTypes.object,
  raiseNewRequestData: PropTypes.object,
  appointmentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  appointmentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  testsTemplates: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  testType: PropTypes.string,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

export default FieldAddCheckupSummary;
