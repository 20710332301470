import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetLicenseDetailsQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import DrillingLicensesData from "../../../../components/DataDetails/DrillingLicensesData/DrillingLicensesData";
import { Helmet } from "react-helmet";
import { drillingLicensesdetails } from "services/StaticLookup/Breadcrumb";
import { fromPage } from "constants/FromPages";
import DrillingSites from "modules/Contractor/components/DataDetails/DrillingSites/DrillingSites";
import { renderTabs } from "utils/Tabs/Tabs";
import { DrillingLicensesRequestTabs } from "services/StaticLookup/Tabs";
import DrillingLicensesRequest from "../DrillingLicensesRequest/DrillingLicensesRequest";
import { useSelector } from "react-redux";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { useSubmitCertificateRequestMutation } from "modules/Contractor/apis/inspection-apis/inspection-api";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ConfirmationModal from "components/AllModalContent/ConfirmationModal/ConfirmationModal";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import PageActionButton from "./LicenseDetailsActions/PageActionButton";

const DrillingLicensesDetails = () => {
  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const { userType, userRole } = useSelector((store) => store.auth);

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  const params = useParams();

  //prettier-ignore
  const { data: licenseData, isFetching , isSuccess, refetch} = useGetLicenseDetailsQuery(params.id);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [submitCertificateReques, submitCertificateRequesResponse] =
    useSubmitCertificateRequestMutation();

  const onSubmitHandler = (data, event) => {
    if (event) {
      event.preventDefault();
    }
    submitCertificateReques({
      licenseNumber: decodeURIComponent(licenseData?.licenseNumber),
      licenseYear: licenseData?.licenseYear,
    })
      .unwrap()
      .then((response) => {
        setShowConfirmModal(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESSFUL_REQUEST,
          type: AlertTypes.SUCCESS,
        });
        refetch();
      })
      .catch((error) => {
        console.error(error);
        setShowConfirmModal(false);
        showAlertHandler({
          show: true,
          message: error.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const onClickHandler = () => {
    navigate("/home/request-path", {
      state: {
        fromPage: fromPage.RAISE_NEW_REQUEST_LICENSE_DETAILS,
        selectedLicenseData: licenseData,
      },
    });
  };

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const handleChange = (_, newValue) => {
    setTabsActiveIndex(newValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`رخص الحفر - رقم الرخصة ${licenseData?.licenseNumber}`}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>

      <DashboardIntro
        title={
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "1.8rem",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {t("license number", { ns: "dashboard" })}{" "}
            <Typography
              component={"span"}
              sx={{
                direction: "rtl",
                textAlign: "end",
                display: "block",
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              {licenseData?.licenseNumber || ""}
            </Typography>
          </Typography>
        }
        description={t("drilling licenses page description", {
          ns: "dashboard",
        })}
        action={
          <Box>
            <PageActionButton
              showAlertHandler={showAlertHandler}
              licenseData={licenseData}
              isFetching={isFetching}
              onClick={onClickHandler}
              setShowConfirmModal={setShowConfirmModal}
            />
            <BaseAlert
              show={alert.show}
              type={alert.type}
              message={alert.message}
              destroy={hideAlertHandler}
              autoClose={10}
              closeIcon={false}
            />
          </Box>
        }
        breadcrumbData={drillingLicensesdetails({
          licenseNumber: licenseData?.licenseNumber,
          licenseYear: licenseData?.licenseYear,
        })}
      />
      <BaseModal
        show={showConfirmModal}
        title={"تأكيد طلب إصدار شهادة"}
        onHide={onLeavePageHandler}
        closeIcon
        px={"4rem"}
        containerSx={{
          minHeight: "25rem",
        }}
      >
        <ConfirmationModal
          onConfirm={(event) => onSubmitHandler(event)}
          onHide={onLeavePageHandler}
          isLoading={submitCertificateRequesResponse.isLoading}
          confirmMessage={
            "هل أنت متأكد من طلب إصدار شهادة إتمام الفحص؟ علماً بأنة لا يمكن رفع طلبات فحص بعد رفع طلب إصدار الشهادة؟"
          }
        />
      </BaseModal>
      <BaseCard mt={6} py={"1rem"} px={"1rem"}>
        {isFetching ? (
          <BaseLoader />
        ) : (
          <>
            <Tabs
              value={tabsActiveIndex}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className="tabs"
            >
              {renderTabs(DrillingLicensesRequestTabs).map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  sx={{
                    background:
                      tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                    fontSize: "1.6rem",
                  }}
                />
              ))}
            </Tabs>
            {tabsActiveIndex === 1 && isSuccess && (
              <Box>
                <DrillingLicensesData data={licenseData} />
                <DrillingSites
                  onClickHandler={onClickHandler}
                  location={[licenseData]}
                  selectedLicense={licenseData}
                  userData={{ userType, userRole }}
                />
              </Box>
            )}
            {tabsActiveIndex === 2 && (
              <Box>
                <DrillingLicensesRequest data={licenseData} />
              </Box>
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default DrillingLicensesDetails;
