import BaseCard from "core-ui/BaseCard/BaseCard";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Helmet } from "react-helmet";
import { LabRequestsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { TestTypes } from "constants/TestTypes";
import DeAssignModal from "modules/Admin/components/LabRequestDetails/DeAssignModal";
import { DATA_ID } from "constants/Details";
import useAlert from "hooks/useAlert";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { useGetInspectionForAdminQuery } from "modules/Admin/apis/inspection-apis/inspection-api";
import { useDeassignLabMutation } from "modules/Admin/apis/inspection-apis/lab-apis";
import { useGetAppointmentsForAdminQuery } from "modules/Contractor/apis/appointments-apis/appointments-apis";
import LabRequestDetailsLab from "modules/Admin/components/LabRequestDetails/Lab/LabRequestDetailsLab";
import LabRequestDetailsField from "modules/Admin/components/LabRequestDetails/Field/LabRequestDetailsField";
import DashboardIntroAction from "./DashboardIntroAction";
import RoleBasedComponent from "components/RoleBasedComponent/RoleBasedComponent";
import { UsersRolesEnum } from "constants/UsersTypes";
import { useSelector } from "react-redux";

const LabRequestDetails = () => {
  const { t } = useTranslation(["dashboard"]);
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();

  let deAssignNavigateTimer;

  const { userRole } = useSelector((store) => store.auth);

  const params = useParams();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();
  const [deassignLab, deassignLabResponse] = useDeassignLabMutation();
  const { data: inspection, isSuccess } = useGetInspectionForAdminQuery(
    params.id
  );
  const { data: appointmentData } = useGetAppointmentsForAdminQuery(params.id);

  const isLab = inspection?.testType === TestTypes.LAB_TEST;

  useEffect(() => {
    return () => clearInterval(deAssignNavigateTimer);
  }, []);

  const onLeaveHandler = () => setShowConfirm(false);

  const handelConfirm = () => {
    deassignLab({
      inspectionId: inspection.id,
    })
      .unwrap()
      .then((responseData) => {
        setShowConfirm(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes.LAB_DEASSIGNED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        deAssignNavigateTimer = setTimeout(() => {
          navigate("/lab-requests");
        }, 2000);
      })
      .catch((err) => {
        onLeaveHandler();
        showAlertHandler({
          show: true,
          message: err.data?.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const inspectionLayerId = inspection?.id;

  const description = [
    UsersRolesEnum.SYSADMIN,
    UsersRolesEnum.OP_ADMIN,
  ].includes(userRole) ? (
    <p>
      يمكنك من خلال هذه الصفحة الاطلاع على تفاصيل الطلب، كما يمكنك أيضا إعادة
      الطلب إلى نظام التوزيع حتى يتم قبوله من مختبر آخر وذلك من خلال الضغط على{" "}
      <span style={{ fontWeight: 800 }}>إعادة الطلب لنظام التوزيع.</span>
    </p>
  ) : (
    <p>يمكنك من خلال هذه الصفحة الاطلاع على تفاصيل الطلب</p>
  );
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة للمختبرات - الطلب رقم ${inspectionLayerId}`}</title>
        <link rel="canonical" href="/lab-requests" />
      </Helmet>

      <DeAssignModal
        requestDetails={inspection}
        t={t}
        showConfirm={showConfirm}
        handelConfirm={handelConfirm}
        onLeaveHandler={onLeaveHandler}
        isRequesting={deassignLabResponse.isLoading}
      />
      {inspection && (
        <DashboardIntro
          descriptionSx={{ maxWidth: "70%" }}
          variant="secondary"
          title={`${t("الطلب رقم", { ns: "dashboard" })} ${
            inspectionLayerId || ""
          }`}
          description={description}
          breadcrumbData={LabRequestsDetailsBreadcrumb({
            id: inspectionLayerId,
          })}
          action={
            <RoleBasedComponent
              allowedRoles={[UsersRolesEnum.SYSADMIN, UsersRolesEnum.OP_ADMIN]}
            >
              <DashboardIntroAction
                alert={alert}
                deassignLabResponse={deassignLabResponse}
                hideAlertHandler={hideAlertHandler}
                setShowConfirm={setShowConfirm}
              />
            </RoleBasedComponent>
          }
        />
      )}

      <BaseCard mt={2} py={"1rem"} px={"1rem"}>
        {!isSuccess ? (
          <BaseLoader />
        ) : (
          <>
            {isLab ? (
              <LabRequestDetailsLab
                inspection={inspection}
                isLoading={!isSuccess}
                dataId={DATA_ID.ADMIN_LAB_REQUESTS}
                appointmentData={appointmentData}
              />
            ) : (
              <LabRequestDetailsField
                inspection={inspection}
                isLoading={!isSuccess}
                dataId={DATA_ID.ADMIN_LAB_REQUESTS}
                appointmentData={appointmentData}
              />
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default LabRequestDetails;
