import { Typography } from "@mui/material";
import ResponsiveTable from "../../../../../../core-ui/BaseTable/BaseTableResponsive/BaseTableResponsive";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CombinedField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/CombinedField/CombinedField";

const MultipleLicenseResponsiveTable = (props) => {
  const { t } = useTranslation(["common", "payment", "dashboard"]);

  const tableHeader = [
    {
      serverKey: "licenseNumber",
      header: () => t("license number", { ns: "tableHeader" }),
      value: ({ headerItem, dataItem, index, ...rest }) => {
        return (
          <CombinedField
            sx={{ direction: "rtl", textAlign: "end", fontSize: "1.4rem" }}
            field1={dataItem.licenseYear}
            field2={dataItem.licenseNumber}
          />
        );
      },
    },
    {
      serverKey: "serviceProviderName",
      header: () => t("service provider", { ns: "tableHeader" }),
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <Typography fontSize="1.4rem">
          {dataItem.serviceProviderName}
        </Typography>
      ),
    },
    {
      serverKey: "serviceName",
      header: () => t("Type of service", { ns: "tableHeader" }),
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <Typography fontSize="1.4rem">
          {t(dataItem.serviceName, { ns: "tableHeader" })}
        </Typography>
      ),
    },
    {
      serverKey: "drillingType",
      header: () => t("Type of drilling", { ns: "tableHeader" }),
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <Typography fontSize="1.4rem">
          {t(dataItem.drillingType, { ns: "tableHeader" })}
        </Typography>
      ),
    },
  ];

  return (
    <ResponsiveTable
      tableHeader={tableHeader}
      content={props.content}
      showRemoveButtonFromIndex={0}
      onRemove={props.onRemove}
      title="الرخص المضافة"
      emptyData={{
        title: "ستظهر هنا جميع الرخص التي تتم إضافتها",
      }}
      appendComponent={
        !props.noMultipleLicenses && (
          <Typography
            sx={{
              fontSize: "1.4rem",
              textAlign: "center",
              mt: 2,
              fontWeight: 500,
              color: props.areLicensesExceededTheMaximum
                ? "red"
                : "primary.colorSoft13",
              cursor: "grab",
            }}
          >
            {props.actionRowLabel}
          </Typography>
        )
      }
    />
  );
};

MultipleLicenseResponsiveTable.propTypes = {
  noMultipleLicenses: PropTypes.bool,
  areLicensesExceededTheMaximum: PropTypes.bool,
  actionRowLabel: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
  content: PropTypes.array,
  onRemove: PropTypes.func,
};

export default MultipleLicenseResponsiveTable;
