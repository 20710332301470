import { AlertTypes } from "constants/AlertTypes";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useViewCertificateMutation } from "modules/Admin/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { base64ToBlob } from "utils/ApiHelpers";

const ExportCertificateButton = ({
  licenseData,
  buttonDisabled,
  onClickHandler,
  showAlertHandler,
}) => {
  const [viewCertificate, viewCertificateResponse] =
    useViewCertificateMutation();
  const { t } = useTranslation(["dashboard"]);

  const openPDFHandler = () => {
    if (licenseData?.certificateIssued)
      viewCertificate({
        licenseNumber: decodeURIComponent(licenseData?.licenseNumber),
        licenseYear: licenseData?.licenseYear,
      })
        .unwrap()
        .then((responseData) => {
          let blob = base64ToBlob(responseData?.base64);
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        })
        .catch((err) => {
          showAlertHandler({
            show: true,
            message: ErrorCodes.NO_ATTACHMENT_EXIST_FOR_THIS_LICENSE,
            type: AlertTypes.ERROR,
          });
        });
  };

  return licenseData?.certificateIssued ? (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={openPDFHandler}
      isLoading={viewCertificateResponse.isLoading}
    >
      {t("view certificate", { ns: "dashboard" })}
    </BaseButton>
  ) : (
    <BaseButton
      variant={buttonDisabled ? "disabled" : ""}
      disabled={buttonDisabled || licenseData?.certificateRequestPending}
      sx={{ minWidth: "17.3rem" }}
      onClick={onClickHandler}
    >
      {t("IssuetheCertificate", { ns: "dashboard" })}
    </BaseButton>
  );
};

ExportCertificateButton.propTypes = {
  licenseData: PropTypes.object,
  onClickHandler: PropTypes.func,
  showAlertHandler: PropTypes.func,
  buttonDisabled: PropTypes.bool,
};

export default ExportCertificateButton;
