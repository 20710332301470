import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import PropTypes from "prop-types";

const TechnicianCorrection = ({
  tableHeader,
  renderTechnicianConfirmations,
  editedStation,
  renderScores,
  gridColumnSpanForConfirmation,
}) => {
  const { breakpoints } = useTheme();
  const lg = useMediaQuery(breakpoints.down("lg"));

  return (
    <BaseCard
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${lg ? 3 : tableHeader.length}, 1fr)`,
      }}
      variant="alternative"
      px={"2rem"}
      py={"1rem"}
      mt={2}
    >
      <Box
        sx={{
          gridColumn: lg ? `1 / -1` : gridColumnSpanForConfirmation,
          gridRow: 1,
        }}
      >
        {renderTechnicianConfirmations()}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.5rem",
          gridColumn: lg ? 2 : `${tableHeader.length}`,
          gridRow: lg ? 3 : 1,
          ml: 4,
        }}
      >
        {editedStation?.testStationMappingEdits?.map((edit, idx) => (
          <Typography
            key={new Date().getTime().toString().substring(0, 10)}
            sx={{ fontSize: "1.8rem" }}
          >
            {renderScores(edit.updatedScores)}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          gridColumn: lg ? 3 : `${tableHeader.length - 1}`,
          gridRow: lg ? 2 : 1,
          ml: 4,
        }}
      >
        <Typography sx={{ fontSize: "1.8rem" }}>
          {editedStation?.updatedOptimumProctor}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          gridColumn: lg ? 1 : `${tableHeader.length - 2}`,
          gridRow: lg ? 3 : 1,
          ml: 4,
        }}
      >
        <Typography sx={{ fontSize: "1.8rem" }}>
          {editedStation?.updatedMaxProctor}
        </Typography>
      </Box>
    </BaseCard>
  );
};

TechnicianCorrection.propTypes = {
  gridColumnSpanForConfirmation: PropTypes.string,
  tableHeader: PropTypes.array,
  renderTechnicianConfirmations: PropTypes.func,
  editedStation: PropTypes.object,
  renderScores: PropTypes.func,
};

export default TechnicianCorrection;
