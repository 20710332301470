import { Box } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { _scrollToTop } from "utils/DOM/DOM";

const TimelineCallToActions = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const isFirstStage = props.timeline.currentStage === 0;
  const isLastStage =
    props.timeline.currentStage >= props.timeline.numberOfStages;

  const prevButtonHandler = () => {
    _scrollToTop();

    if (isFirstStage) {
      navigate(-1);
    } else {
      props.timeline.goPrev();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "absolute",
        gap: "1.5rem",
        bottom: "2rem",
        width: "100%",
        left: 0,
        paddingInline: "3rem",
      }}
    >
      <Box display={"flex"} gap={3} flexWrap={"wrap"}>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          onClick={prevButtonHandler}
          variant={props.isPrevButtonDisabled ? "disabled" : "secondary"}
          disabled={!!props.isPrevButtonDisabled}
          isLoading={props.isLoading}
        >
          {isFirstStage
            ? t("تراجع", { ns: "common" })
            : t("السابق", { ns: "common" })}
        </BaseButton>

        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          type="submit"
          variant={props.isNextButtonDisabled ? "disabled" : ""}
          disabled={!!props.isNextButtonDisabled}
          isLoading={
            isLastStage
              ? !!props.isNextButtonDisabled
              : props.isLoading || false
          }
        >
          {isLastStage
            ? t("خصم النقاط", { ns: "common" })
            : t("next", { ns: "common" })}
        </BaseButton>
      </Box>
    </Box>
  );
};

TimelineCallToActions.propTypes = {
  isLoading: PropTypes.bool,
  timeline: PropTypes.object,
  isNextButtonDisabled: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  isPrevButtonDisabled: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

export default TimelineCallToActions;
