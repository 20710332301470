import { Box, Typography } from "@mui/material";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "./DashboardDrillingLicensesData.module.scss";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { publicAssetsPath } from "utils/Paths/Paths";
import LicenseDataFallbackComp from "./LicenseDataFallbackComp";
import CombinedField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/CombinedField/CombinedField";
import { defaultEmptyValue } from "constants/Empty";
import PropTypes from "prop-types";

const DashboardDrillingLicensesData = (props) => {
  if (
    props.licenseErrorMessage === ErrorCodes.NOT_AUTHORIZED ||
    props.licenseErrorMessage === ErrorCodes.NOT_FOUND_ERROR ||
    props.licenseErrorMessage === ErrorCodes.INTEGRATION_SERVICE_ERROR ||
    props.licenseErrorMessage === ErrorCodes.UNKNOWN_ERROR ||
    props.licenseErrorMessage ===
      ErrorCodes.CONTRACTOR_NOT_EXIST_IN_INTEGRATION_SERVICE
  ) {
    return (
      <Box>
        <LicenseDataFallbackComp
          title="لا توجد نتائج تطابق المدخلات"
          desc="الرجاء التحقق من صحة البيانات المدخلة والمحاولة مرة اخرى"
          icon="notFound.svg"
        />
      </Box>
    );
  }

  if (props.licenseErrorMessage === ErrorCodes.LICENSE_EXPIRED) {
    return (
      <Box>
        <LicenseDataFallbackComp
          title="عذراً، صلاحية الرخصة منتهية"
          desc="الرجاء التحقق من صحة البيانات المدخلة والمحاولة مرة اخرى"
          icon="emptySearch.svg"
        />
      </Box>
    );
  }

  if (props.licenseErrorMessage === ErrorCodes.LICENSE_TYPE_NOT_SUPPORTED) {
    return (
      <Box>
        <LicenseDataFallbackComp
          title="عذرا خدمة طلب الفحص لا تدعم رخصة الطوارئ"
          desc="يمكنك طلب فحص على الرخص العادية فقط"
          icon="emptySearch.svg"
        />
      </Box>
    );
  }

  if (!props.data) {
    return (
      <Box>
        <LicenseDataFallbackComp
          title="يرجى البحث عن الرخص وإضافتها إلى قائمة الرخص المضافة"
          desc=""
          icon="emptySearch2.svg"
        />
      </Box>
    );
  }

  if (props.data) {
    return (
      <Box className={classes["drilling-data"]} mt={6}>
        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            {props.title || "الرخصة"}
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                رقم الرخصة
              </Typography>
              <Typography
                component={"div"}
                className={classes["drilling-data__value"]}
              >
                <CombinedField
                  sx={{ direction: "rtl", textAlign: "end" }}
                  field1={props.data.licenseYear}
                  field2={props.data.licenseNumber}
                />
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                النوع
              </Typography>
              <BaseTypeTag type={props.data.typeRefId} />
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                تاريخ الانتهاء
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.expiryDate}
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                مقدم الخدمة
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.serviceProviderName}
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                نوع الحفر{" "}
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.drillingType || ""}
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                نوع الخدمة
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.serviceName}
              </Typography>
            </Box>
            {props.data?.projectName ? (
              <Box className={classes["drilling-data__item"]}>
                <Typography className={classes["drilling-data__label"]}>
                  اسم المشروع{" "}
                </Typography>
                <Typography className={classes["drilling-data__value"]}>
                  {props.data.projectName || "لا يوجد"}
                </Typography>
              </Box>
            ) : null}
            {props.data?.streetWidth ? (
              <Box className={classes["drilling-data__item"]}>
                <Typography className={classes["drilling-data__label"]}>
                  عرض الشارع{" "}
                </Typography>
                <Typography className={classes["drilling-data__value"]}>
                  {props.data.streetWidth || "لا يوجد"}
                </Typography>
              </Box>
            ) : null}
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            بيانات الحفر
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                الطول
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.licenceLength} م
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                العرض
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.boundaryWidth} م
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                العمق
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.boundaryDepth} م
              </Typography>
            </Box>
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                الحجم
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.volumNo}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />

        <Box className={classes["drilling-data__wrapper"]}>
          <Typography className={classes["drilling-data__title"]}>
            مواقع الحفر
          </Typography>
          <Box className={classes["drilling-data__item"]}>
            <Typography
              className={"request-data-details__value"}
              sx={{
                fontWeight: "bold !important",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {props.data?.locationUrl ? (
                <>
                  <a
                    href={props.data?.locationUrl}
                    target="_blank"
                    className="request-data-details__link"
                  >
                    الموقع
                  </a>
                  <img
                    src={publicAssetsPath("icons/popout-svgrepo.svg")}
                    width="auto"
                    alt="popout-dashboard-drilling-license"
                  />
                </>
              ) : (
                defaultEmptyValue
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
};

DashboardDrillingLicensesData.propTypes = {
  data: PropTypes.object,
  licenseErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  title: PropTypes.string,
  columns: PropTypes.number,
};

export default DashboardDrillingLicensesData;
