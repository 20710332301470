import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useRoleBasedPageMiddleware = (userRole, allowedRoles) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkRoleHandler = () => {
      const isArray = Array.isArray(allowedRoles);

      if (isArray && allowedRoles.length === 0) return;

      if (isArray) {
        if (!allowedRoles.includes(userRole)) return navigate(-1);
      } else if (typeof allowedRoles === "string") {
        if (allowedRoles != userRole) return navigate(-1);
      }
    };
    checkRoleHandler();
  }, [userRole, allowedRoles]);
};

useRoleBasedPageMiddleware.defaultProps = {
  allowedRoles: [],
};

useRoleBasedPageMiddleware.propTypes = {
  userRole: PropTypes.string,
  allowedRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default useRoleBasedPageMiddleware;
