import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { defaultEmptyValue } from "constants/Empty";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { formatDateYYYYMMDD, renderDateTimeInAMPM } from "utils/Date/Date";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { publicAssetsPath } from "utils/Paths/Paths";
import FieldExaminationRequestTests from "./FieldExaminationRequestTests";
import BaseModal from "core-ui/BaseModal/BaseModal";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import LicenseLink from "components/LicenseLink/LicenseLink";
import PropTypes from "prop-types";

function RequestDataField({
  inspection,
  contractorCompanyInfo,
  technicianData,
  dataId,
  appointmentData,
  questionnaireRecoord,
  ...props
}) {
  const [showStation, setShowStation] = useState(false);

  const onLeavePageHandler = () => setShowStation(false);
  const { stations } = useGetStationsWithTestData({ id: inspection?.id });

  return (
    <Box>
      <BaseModal
        show={showStation}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>

      <Box className={"request-data-details"} mt={4} ml={4} mr={4}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.id || "لا يوجد "}
              </Typography>
            </Box>
            <LicenseLink license={inspection?.licenses?.[0]} />
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الفحص 
              </Typography>
              <BaseStatus
                status={inspection?.status}
                dataId={dataId}
                testType={inspection.testType}
                appointment={inspection?.appointment}
              />
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإنشاء
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                {formatDateYYYYMMDD(inspection?.createdAt) || "لا يوجد"}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية{" "}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{ direction: "rtl" }}
              >
                {inspection?.name || "لا يوجد "}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نوع المسار
              </Typography>
              <BaseTypeTag type={inspection?.testType} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نقاط الـ Stations
              </Typography>
              <Typography
                className={"request-data-details__value"}
                onClick={() => setShowStation(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  cursor: "grab",
                }}
              >
                نقاط الـ Stations{" "}
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                  alt="popout-stations"
                />
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        {inspection?.cancellationReason && (
          <>
            <BaseAlert
              mb={5}
              mt={5}
              show={true}
              labelSx={{ fontWeight: "bold", color: "black" }}
              type={AlertTypes.WARNING}
              icon={false}
            >
              <Typography
                sx={{
                  fontSize: "inherit !important",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                سبب إلغاء الطلب:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "normal",
                }}
              >
                {inspection?.cancellationReason}
              </Typography>
            </BaseAlert>
            <hr />
          </>
        )}
        <>
          <Box className={"request-data-details__wrapper"} mb={5}>
            <Typography className={"request-data-details__title"}>
              موعد الفحص الحقلي
            </Typography>
            <Box mt={5}>
              <WrapperContentGrid gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    الوقت
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {renderDateTimeInAMPM(appointmentData?.scheduledTime) ||
                      "لا يوجد"}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    التاريخ
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(appointmentData?.scheduledDate) ||
                      "لا يوجد"}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
          </Box>
          <hr />
        </>

        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            الاختبارات
          </Typography>
          <Box mt={4}>
            <FieldExaminationRequestTests
              inspection={inspection}
              stations={stations}
              appointmentData={appointmentData}
              questionnaireRecoord={questionnaireRecoord}
            />
          </Box>
        </Box>

        <hr />

        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات المختبر {" "}
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labName || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موقع المختبر{" "}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{
                  fontWeight: "bold !important",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {inspection?.labLocation ? (
                  <>
                    <a
                      href={inspection?.labLocation}
                      target="_blank"
                      className="request-data-details__link"
                    >
                      الموقع
                    </a>
                    <img
                      src={publicAssetsPath("icons/popout-svgrepo.svg")}
                      width="auto"
                      alt="popout-location"
                    />
                  </>
                ) : (
                  defaultEmptyValue
                )}
              </Typography>
            </Box>

            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإسناد
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(inspection?.labAssignedAt) || "لا يوجد"}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات الاستشاري 
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.licenses[0]?.consultantName || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatPhoneNumber(inspection?.licenses[0]?.consultantMobile) ||
                  "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.licenses[0]?.consultantEmail || "لا يوجد "}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات شركة المقاولات
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اسم شركة المقاولات
              </Typography>
              <Typography className={"request-data-details__value"}>
                {contractorCompanyInfo?.contractorCompany || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatPhoneNumber(contractorCompanyInfo?.contractorMobile) ||
                  "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {contractorCompanyInfo?.contractorEmail || "لا يوجد "}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>

        <hr />
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات فني الرقابة
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {technicianData?.name || "لا يوجد "}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني{" "}
              </Typography>
              <Typography className={"request-data-details__value"}>
                {technicianData?.email || "لا يوجد "}
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
    </Box>
  );
}

RequestDataField.propTypes = {
  inspection: PropTypes.object,
  contractorCompanyInfo: PropTypes.object,
  technicianData: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  appointmentData: PropTypes.object,
  columns: PropTypes.number,
  questionnaireRecoord: PropTypes.object,
};

export default RequestDataField;
