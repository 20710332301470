import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import { CertificateRequestsDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { DATA_ID } from "constants/Details";
import {
  useGetAdminCertificateDetailsQuery,
  useApproveAdminCertificateMutation,
  useRejectAdminCertificateMutation,
} from "modules/Admin/apis/inspection-apis/inspection-api";
import { useState } from "react";
import useAlert from "hooks/useAlert";
import BaseConfimationModal from "core-ui/BaseConfimationModal/BaseConfimationModal";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import RejectionModal from "components/AllModalContent/RejectionModal/RejectionModal";
import DashboardIntroAction from "./DashboardIntroAction";
import LicenseLink from "components/LicenseLink/LicenseLink";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { AlertTypes } from "constants/AlertTypes";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import RoleBasedComponent from "components/RoleBasedComponent/RoleBasedComponent";
import { UsersRolesEnum } from "constants/UsersTypes";

const CertificateRequestsDetails = () => {
  const { t } = useTranslation(["dashboard"]);
  const params = useParams();

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { data: requestsDetailsData, isFetching } =
    useGetAdminCertificateDetailsQuery(params.id);

  const [approveCertificate, approveCertificateResponse] =
    useApproveAdminCertificateMutation();
  const [rejectCertificate, rejectCertificateResponse] =
    useRejectAdminCertificateMutation();

  const confirmAceept = () => {
    approveCertificate({ id: params.id })
      .unwrap()
      .then(() => {
        setShowAcceptModal(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_APPROVE_ISSUANCE_INSPECTION_CERTIFICATE,
        });
      })
      .catch((err) => {
        setShowAcceptModal(false);
        showAlertHandler({
          show: true,
          message:
            err.data?.errorCode === ErrorCodes.UNKNOWN_ERROR
              ? ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE
              : err.data?.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const confirmReject = (rejectReason, reset) => {
    rejectCertificate({
      certificateRequestId: requestsDetailsData?.id,
      rejectReason,
    })
      .unwrap()
      .then(() => {
        setShowRejectionModal(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes.SUCCESS_REJECT_ISSUANCE_INSPECTION_CERTIFICATE,
          type: AlertTypes.SUCCESS,
        });
        reset();
      })
      .catch((err) => {
        setShowRejectionModal(false);
        showAlertHandler({
          show: true,
          message:
            err.data?.errorCode === ErrorCodes.UNKNOWN_ERROR
              ? ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE
              : err.data?.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const canTakeAction =
    requestsDetailsData?.adminDecision === "PENDING_DECISION";

  return (
    <>
      {/* Rejection Modal */}
      <RejectionModal
        title="رفض طلب إصدار الشهادة"
        show={showRejectionModal}
        closeModal={() => setShowRejectionModal(false)}
        confirmReject={confirmReject}
        maxLength={700}
        isLoading={rejectCertificateResponse.isLoading}
      />
      {/* Accept Modal */}
      <BaseConfimationModal
        title="تأكيد قبول طلب إصدار شهادة"
        onConfirm={confirmAceept}
        onHide={() => setShowAcceptModal(false)}
        showModal={showAcceptModal}
        isLoading={approveCertificateResponse.isLoading}
      >
        هل انت متأكد من اصدار شهادة الفحص؟
      </BaseConfimationModal>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{`طلب إصدار الشهادة رقم ${requestsDetailsData?.id}`}</title>
      </Helmet>
      <DashboardIntro
        title={t(`طلب إصدار الشهادة رقم ${requestsDetailsData?.id || ""}`, {
          ns: "dashboard",
        })}
        description="تستعرض الصفحة بيانات طلب الشهادة و طلبات الرخصة المتعلقة بها"
        breadcrumbData={CertificateRequestsDetailsBreadcrumb({
          id: requestsDetailsData?.id || "",
        })}
        action={
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <BaseAlert
              show={alert.show}
              type={alert.type}
              message={alert.message}
              destroy={hideAlertHandler}
              autoClose={10}
            />
            {canTakeAction && (
              <RoleBasedComponent allowedRoles={[UsersRolesEnum.SYSADMIN]}>
                <DashboardIntroAction
                  setShowAcceptModal={setShowAcceptModal}
                  setShowRejectionModal={setShowRejectionModal}
                  disabled={!canTakeAction}
                />
              </RoleBasedComponent>
            )}
          </Box>
        }
      />

      <BaseCard
        py={"2rem"}
        px={"5rem"}
        sx={{
          mt: 5,
          minHeight: "70vh",
          position: "relative",
        }}
      >
        {isFetching ? (
          <BaseLoader />
        ) : (
          <Box className={"request-data-details"} mt={4}>
            <Box className={"request-data-details__wrapper"} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الطلب
              </Typography>

              <WrapperContentGrid gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.id}
                  </Typography>
                </Box>
                <LicenseLink license={requestsDetailsData?.license} />
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    اسم شركة المقاولات
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.contractor?.name}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    تاريخ رفع الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(requestsDetailsData?.dateCreated)}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>

            <hr />
            <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات قرار إصدار الشهادة
              </Typography>

              <WrapperContentGrid gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    قرار مدير النظام
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {t(requestsDetailsData?.adminDecision, { ns: "common" })}
                  </Typography>
                </Box>

                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    حالة الشهادة
                  </Typography>
                  <BaseStatus
                    status={requestsDetailsData?.inspectionCertificateStatus}
                    dataId={DATA_ID?.CONSULTANT_CERTIFICATE_REQUEST}
                  />
                </Box>
                {requestsDetailsData?.adminDecisionTime && (
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      تاريخ القرار النهائي
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {formatDateYYYYMMDD(
                        requestsDetailsData?.adminDecisionTime
                      )}
                    </Typography>
                  </Box>
                )}
              </WrapperContentGrid>
            </Box>

            {requestsDetailsData?.adminRejectReason && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  أسباب رفض مدير النظام
                </Typography>
                <Typography
                  sx={{ fontSize: "1.6rem" }}
                  color={"grayDark.color1"}
                  mb={3}
                >
                  {requestsDetailsData?.adminRejectReason}
                </Typography>
              </Box>
            )}
            <hr />
            <Box className={"request-data-details__wrapper"} mt={5} mb={5}>
              <Typography className={"request-data-details__title"}>
                بيانات الاستشاري
              </Typography>

              <WrapperContentGrid gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    قرار الاستشاري
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.consultantDecision
                      ? t(requestsDetailsData?.consultantDecision, {
                          ns: "common",
                        })
                      : "لا يوجد"}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    الاسم
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.consultant?.name}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    شركة الاستشارات
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestsDetailsData?.consultant?.companyName}
                  </Typography>
                </Box>
              </WrapperContentGrid>
            </Box>
            {requestsDetailsData?.consultantRejectReason && (
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  أسباب رفض الاستشاري
                </Typography>
                <Typography
                  sx={{ fontSize: "1.6rem" }}
                  color={"grayDark.color1"}
                  mb={3}
                >
                  {requestsDetailsData?.consultantRejectReason}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </BaseCard>
    </>
  );
};

export default CertificateRequestsDetails;
