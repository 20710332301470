export const UsersJobs = [
  { id: 1, name: "فني التشغيل", type: "RESAA", role: "SYSADMIN" },
  { id: 2, name: "فني الرقابة", type: "RESAA", role: "TECHNICIAN" },
  { id: 3, name: "مسؤول مركز التوزيع", type: "CENTER", role: "SYSOPS" },
  { id: 4, name: "فني المختبر", type: "LABORATORY", role: "SYSOPS" },
];

export const UsersJobsSupportPage = [
  { id: 1, name: "مقاول", type: "CONTRACTOR", role: "SYSOPS" },
  { id: 2, name: "فني المختبر", type: "LABORATORY", role: "SYSOPS" },
  { id: 3, name: "فني الرقابة", type: "RESAA", role: "TECHNICIAN" },
  { id: 4, name: "ممثل مركز التوزيع", type: "CENTER", role: "SYSOPS" },
  { id: 5, name: "مدير النظام", type: "RESAA", role: "SYSADMIN" },
  { id: 6, name: "استشاري", type: "CONSULTANT", role: "SYSOPS" },
];

export const UsersRoles = [
  {
    role: "SYSOPS",
    type: "CENTER",
    name: "مسؤول مركز التوزيع",
  },
  {
    role: "SYSOPS",
    type: "LABORATORY",
    name: "فني المختبر",
  },
  {
    role: "LAB_TECHNICIAN",
    type: "LABORATORY",
    name: "فني المختبر",
  },
  {
    role: "TECHNICIAN",
    type: "RESAA",
    name: "فني الرقابة",
  },
  {
    role: "SYSADMIN",
    type: "RESAA",
    name: "مدير النظام",
  },
  {
    role: "OP_ADMIN",
    type: "RESAA",
    name: "مدير العمليات",
  },
  {
    role: "OP_SUPP_ADMIN",
    type: "RESAA",
    name: "مدير دعم العمليات",
  },
  {
    role: "QA_QC_ADMIN",
    type: "RESAA",
    name: "مدير تأكيد الجودة / ضبط الجودة",
  },
  {
    role: "QA_QC_SUPP_ADMIN",
    type: "RESAA",
    name: "مدير دعم تأكيد الجودة / ضبط الجودة ",
  },
];
