import RoleBasedComponent from "components/RoleBasedComponent/RoleBasedComponent";
import useRoleBasedPageMiddleware from "hooks/useRoleBasedPageMiddleware";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const RoleBasedRoute = (props) => {
  const { userRole } = useSelector((store) => store.auth);

  useRoleBasedPageMiddleware(userRole, props.allowedRoles);

  return (
    <RoleBasedComponent userRole={userRole} allowedRoles={props.allowedRoles}>
      {props.children}
    </RoleBasedComponent>
  );
};

RoleBasedRoute.propTypes = {
  allowedRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default RoleBasedRoute;
