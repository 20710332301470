const SystemConfigurationCodes = {
  MAXIMUM_REQUESTS_PER_TRIP: "MAXIMUM_REQUESTS_PER_TRIP",
  TRIP_DURATION: "TRIP_DURATION",
  MAXIMUM_REQUESTS_PER_LABORATORY: "MAXIMUM_REQUESTS_PER_LABORATORY",
};
const SystemConfigurationCategories = {
  TECHNICIAN: "TECHNICIAN",
  LABORATORY: "LABORATORY",
};

export { SystemConfigurationCategories, SystemConfigurationCodes };
