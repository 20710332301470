import React from "react";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const LayerTest = (props) => {
  const { raiseNewRequestData, errors, control, id, editableLayersTemplates } =
    props;

  return (
    <Box>
      <BaseDropdown
        control={control}
        name={`station-${id}-layerTest`}
        errors={errors}
        required={{ message: "حقل مطلوب" }}
        labelStyles={{
          color: "grayDark.color",
          fontSize: "1.4rem",
          fontWeight: 500,
        }}
        placeholder="حدد نوع اختبار مواد الأعمال المدنية "
        data={
          editableLayersTemplates ||
          raiseNewRequestData?.fetchedFieldLayersTemplates ||
          []
        }
        emptyListPlaceholder="يرجى تحديد نوع الجهاز أولا"
      />
    </Box>
  );
};

LayerTest.propTypes = {
  raiseNewRequestData: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editableLayersTemplates: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default LayerTest;
