import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseTimeline from "core-ui/BaseTimeline/BaseTimeline";
import BaseTimelineResponsive from "core-ui/BaseTimeline/BaseTimelineResponsive";
import { useTranslation } from "react-i18next";
import { RequestCheckupBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { requestCheckupTimelineStages } from "services/StaticLookup/Timeline";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import useTimeline from "hooks/useTimeline";
import { Helmet } from "react-helmet";
import RaiseNewRequestFeature from "modules/Contractor/features/RaiseNewRequest";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const RequestCheckup = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();

  const state = location.state;

  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const testType = state?.testType || raiseNewRequestData?.testType;

  const { timelineData, goNextStage, goPrevStage } = useTimeline(
    requestCheckupTimelineStages[testType]
  );

  const areStagesMoreThanFive =
    requestCheckupTimelineStages[testType].length > 5;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            requestCheckupTimelineStages[testType][
              timelineData.activeStageIndex
            ]?.title
          }
        </title>
        <link rel="canonical" href="/home/request-path" />
      </Helmet>

      <DashboardIntro
        title={
          requestCheckupTimelineStages[testType][timelineData.activeStageIndex]
            ?.title
        }
        description={
          requestCheckupTimelineStages[testType][timelineData.activeStageIndex]
            ?.subTitle
        }
        action={
          <DashboardIntroAction
            sm={sm}
            fluid={areStagesMoreThanFive}
            sx={{
              marginInlineStart: {
                lg: "3rem",
                md: "3rem",
              },
              marginInlineEnd: {
                lg: "4rem",
                md: "4rem",
              },
            }}
          >
            {sm ? (
              <BaseTimelineResponsive
                activeStageIndex={timelineData.activeStageIndex}
                completedStagesIndexes={timelineData.completedStagesIndexes}
                stages={requestCheckupTimelineStages[testType]}
              />
            ) : (
              <BaseTimeline
                activeStageIndex={timelineData.activeStageIndex}
                completedStagesIndexes={timelineData.completedStagesIndexes}
                stages={requestCheckupTimelineStages[testType]}
              />
            )}
          </DashboardIntroAction>
        }
        breadcrumbData={RequestCheckupBreadcrumb(t)}
      />

      <RaiseNewRequestFeature
        currentStage={timelineData.activeStageIndex}
        goNext={goNextStage}
        goPrev={goPrevStage}
        numberOfStages={requestCheckupTimelineStages[testType].length - 1}
        sm={sm}
      />
    </>
  );
};

export default RequestCheckup;
