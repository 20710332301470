import { Box } from "@mui/material";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseButton from "core-ui/BaseButton/BaseButton";
import ManageUserButtonAction from "./ManageUserButtonAction";
import {
  useActivateUserMutation,
  useDeActivateUserMutation,
  useResendInviteMutation,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useAlert from "hooks/useAlert";
import { useSelector } from "react-redux";
import { shouldRenderUserManagementControls } from "./helpers";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ConfirmationModal from "components/AllModalContent/ConfirmationModal/ConfirmationModal";
import { useState, useEffect } from "react";
import { _void } from "utils/Objects/Objects";
import { useNavigate } from "react-router-dom";
import BaseMUITooltip from "core-ui/BaseTooltip/BaseMUITooltip";

const DashboardIntroAction = ({ user, isFetchingUser, fromPageId }) => {
  const { t } = useTranslation(["dashboard"]);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { userType, userRole } = useSelector((store) => store.auth);

  const [activateUser, activateUserResponse] = useActivateUserMutation();
  const [deActivateUser, deActivateUserResponse] = useDeActivateUserMutation();
  const [resendInvite, resendInviteResponse] = useResendInviteMutation();

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const isUserActionSubmitting =
    activateUserResponse.isLoading ||
    deActivateUserResponse.isLoading ||
    isFetchingUser ||
    resendInviteResponse.isLoading;

  const resendUserInvite = () => {
    resendInvite({ userId: user.id })
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.USER_REINVITED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const changeUserActivationState = (fn, msg) => {
    fn({ userId: user.id })
      .unwrap()
      .then(() => {
        setShowConfirmModal(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes[msg],
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        setShowConfirmModal(false);
        showAlertHandler({
          show: true,
          message: err.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };
  const msg = () => {
    if (!user) {
      return _void;
    } else if (user.isActive) {
      return "USER_DECTIVATED_SUCCESSFULLY";
    } else {
      return "USER_ACTIVATED_SUCCESSFULLY";
    }
  };
  const fn = () => {
    if (!user) {
      return _void;
    } else if (user.isActive) {
      return deActivateUser;
    } else {
      return activateUser;
    }
  };

  useEffect(() => {
    showAlertHandler({
      show: fromPageId === "editUser",
      type: AlertTypes.SUCCESS,
      message: SuccessCodes.USER_UPDATED_SUCCESSFULLY,
    });
  }, [fromPageId]);

  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(`/users-management/${user.id}/edit-user`);
  };

  const cantEdit = !user?.reInvite && !user?.registered;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <BaseAlert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          destroy={hideAlertHandler}
          autoClose={10}
        />
        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
          {shouldRenderUserManagementControls(userType, userRole, user?.role) &&
            user?.role && (
              <>
                {user?.entitytype !== "LABORATORY" && (
                  <BaseMUITooltip
                    title={
                      cantEdit
                        ? "لا يمكنك تعديل بيانات المستخدم لوجود دعوة قيد الانتظار"
                        : ""
                    }
                  >
                    <Box sx={{ display: "inline-block" }}>
                      <BaseButton
                        onClick={handleEditClick}
                        isLoading={!user?.id}
                        disabled={cantEdit}
                        sx={{ minWidth: "17.3rem" }}
                      >
                        {t("edit")}
                      </BaseButton>
                    </Box>
                  </BaseMUITooltip>
                )}

                {user?.registered ? (
                  <ManageUserButtonAction
                    user={user}
                    activateUser={activateUser}
                    isFetchingUser={isFetchingUser}
                    deActivateUser={deActivateUser}
                    showAlertHandler={showAlertHandler}
                    resendInviteResponse={resendInviteResponse}
                    isUserActionSubmitting={isUserActionSubmitting}
                    setShowConfirmModal={setShowConfirmModal}
                  />
                ) : (
                  <BaseButton
                    onClick={resendUserInvite}
                    disabled={!user?.reInvite}
                    variant={!user?.reInvite ? "disabled-fancy" : ""}
                    isLoading={isUserActionSubmitting}
                  >
                    {t("resend invite")}
                  </BaseButton>
                )}
              </>
            )}
        </Box>
      </Box>
      <BaseModal
        show={showConfirmModal}
        title={user?.isActive ? "تعطيل حساب مستخدم" : "تنشيط حساب المستخدم"}
        onHide={onLeavePageHandler}
        closeIcon
        px={"4rem"}
        containerSx={{
          minHeight: "25rem",
        }}
      >
        <ConfirmationModal
          onConfirm={changeUserActivationState.bind(null, fn(), msg())}
          onHide={onLeavePageHandler}
          confirmMessage={
            <span>
              يرجى العلم أنه سيتم
              {user?.isActive ? " تعطيل " : " تنشيط "}
              حساب المستخدم{" "}
              <span style={{ color: "#377F80" }}>{user?.name}</span>، هل أنت
              متأكد من قيامك بذلك؟
            </span>
          }
        />
      </BaseModal>
    </>
  );
};

DashboardIntroAction.propTypes = {
  user: PropTypes.object,
  isFetchingUser: PropTypes.bool,
  fromPageId: PropTypes.string,
};

export default DashboardIntroAction;
