import { isValidPhoneNumber } from "react-phone-number-input";

/**
 *
 * @param {object} formRef - useRef()
 * @returns - It return a filtered list that only contains HTMLInput elements
 */
export const getFormInputs = (formRef) => {
  return Array.from(formRef.current.elements).filter(
    (item) => item.localName === "input"
  );
};

/**
 * @description It resets all input values
 * @param {Array<HTMLInputElement>} formInputs
 */
export const resetFormInputs = (formInputs) => {
  formInputs.forEach((input) => (input.value = ""));
};

/**
 *
 * @param {object} formData - It collect all form input keys
 * @param {string} removeProp - It is a string that will be removed from formData object
 * @returns {boolean} - It returns true in case all inputs have a value
 */
export const checkAllFieldssAreFilled = (formData, removeProp) => {
  let areAllFilled = true;

  if (removeProp) {
    delete formData[removeProp];
  }

  for (let key in formData) {
    if (!formData[key]) {
      return false;
    }
  }
  return areAllFilled;
};

/**
 *
 * @param {string} mobileValue - It is a string value that represents the mobile number
 * @returns {boolean} - It returns true in case the mobile value is valid
 */
export const customValidateMobileNumber = (mobileValue) => {
  if (
    mobileValue?.length === 10 &&
    !isValidPhoneNumber(mobileValue?.replace("0", "+966"))
  ) {
    return "رقم الجوال غير صحيح";
  } else if (mobileValue?.length !== 10) {
    return "رقم الجوال غير صحيح";
  }
  return true;
};
