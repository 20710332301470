import { defaultEmptyValue } from "constants/Empty";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";

const useBaseTableDoubleCard = (doubleCardTableData) => {
  const {
    content,
    doubleLayer,
    tableHeader,
    dataItemKey,
    injectProps,
    withSpecificAction,
  } = doubleCardTableData;

  const [insertedProps, setInsertedProps] = useState({});

  const insertNewPropsHandler = useCallback((newProps) => {
    setInsertedProps((prevState) => ({ ...prevState, ...newProps }));
  }, []);

  const renderTableCell = (dataItem, headerItem, elementIndex, columnIndex) => {
    let renderedTableCell = dataItem[headerItem.serverKey];

    const customFormat = (dataItem) => headerItem.format;
    const _format = customFormat(dataItem);

    if (headerItem.applyNumbers === false)
      return renderedTableCell || headerItem?.fallback || defaultEmptyValue;

    // WE ADDED THIS TO GIVE A FALLBACK VALUE IN CASE IF IS THERE NO hasComponent Key in the headerItem
    if (
      (renderedTableCell === null ||
        renderTableCell === undefined ||
        renderTableCell == "" ||
        !renderTableCell) &&
      !headerItem.hasComponent
    )
      return headerItem?.fallback || defaultEmptyValue;

    const _serverKey = Array.isArray(headerItem.serverKey)
      ? headerItem.serverKey[0]
      : headerItem.serverKey;

    if (headerItem.format) {
      renderedTableCell =
        _format(dataItem[_serverKey], dataItem) ?? headerItem?.fallback;
    }

    if (headerItem.hasComponent) {
      const arePropsArray = Array.isArray(headerItem.hasComponent.customProps);
      const extraProps = headerItem.hasComponent.extraProps;

      let componentProps = {
        ...dataItem,
        ...extraProps,
        tableHeaderItem: { ...headerItem },
        elementId: dataItem[dataItemKey],
        elementIndex,
        tableHeader: tableHeader,
        columnIndex,
        insertProps: {
          props: { ...insertedProps },
          insertNewPropsHandler: insertNewPropsHandler,
        },
      };

      if (arePropsArray) {
        headerItem.hasComponent.customProps.forEach((prop, idx) => {
          componentProps[prop] =
            dataItem[headerItem.serverKey[idx]] ?? headerItem?.fallback;
        });
      } else {
        // 1. CASE SERVER KEY
        componentProps = {
          ...componentProps,
          [headerItem.hasComponent.customProps]:
            dataItem[headerItem.serverKey] ?? headerItem?.fallback,
        };
      }
      if (injectProps) {
        componentProps = {
          ...injectProps,
          ...componentProps,
        };
      }
      renderedTableCell = React.cloneElement(
        headerItem.hasComponent.comp,
        componentProps
      );
    }
    return renderedTableCell;
  };

  const renderedDoubleLayerComponent = (element) => {
    if (!doubleLayer?.(element)?.component) return null;
    const doubleLayerComponent = React.cloneElement(
      doubleLayer?.(element)?.component(element),
      {
        ...insertedProps,
        insertNewPropsHandler,
        props: { ...doubleCardTableData },
        element: { ...element },
      }
    );

    return <Fragment key={element.id}>{doubleLayerComponent}</Fragment>;
  };

  const doubleLayerVisibilityState = doubleLayer?.()?.visibility;

  const [showDoubleLayer, setShowDoubleLayer] = useState(
    doubleLayerVisibilityState
  );

  const createWithSpecificActionComponent = (element, withSpecificAction) => {
    if (!withSpecificAction) return null;
    return React.cloneElement(withSpecificAction.component, {
      onClick: () => {
        if (withSpecificAction?.action) {
          withSpecificAction.action(element);
        }
        if (!withSpecificAction.ignoreState) {
          triggerVisibility(element);
        }
      },
    });
  };

  useEffect(() => {
    if (isContentReady(content) && doubleLayer) {
      content.forEach((contentItem) => {
        let initialVisibilityValue = false;

        if (withSpecificAction) {
          if (doubleLayer(contentItem)?.specificRowId?.length > 0) {
            if (
              doubleLayer(contentItem)?.specificRowId?.includes(contentItem?.id)
            ) {
              initialVisibilityValue = true;
            } else {
              if (
                withSpecificAction(contentItem)?.condition &&
                doubleLayer?.(contentItem)?.visibility
              ) {
                initialVisibilityValue = true;
              }
            }
          } else {
            if (doubleLayer) {
              if (
                doubleLayer(contentItem)?.visibility ||
                withSpecificAction(contentItem)?.actionType === "dropdown"
              ) {
                initialVisibilityValue = true;
              }
            }
          }
        } else if (doubleLayer) {
          if (doubleLayer(contentItem)?.visibility) {
            initialVisibilityValue = true;
          }
        }

        setShowDoubleLayer((state) => ({
          ...state,
          [contentItem.id]: initialVisibilityValue,
        }));
      });
    }
  }, [content, doubleLayer, withSpecificAction]);

  const doubleLayerVisibility = (element) => {
    if (!doubleLayer || !showDoubleLayer?.[element.id]) return false;
    if (
      typeof showDoubleLayer === "boolean" &&
      !showDoubleLayer &&
      doubleLayer &&
      doubleLayer(element)?.specificRowId?.length > 0 &&
      doubleLayer(element)?.specificRowId?.includes(element?.id)
    ) {
      return true;
    }

    return (
      (showDoubleLayer !== null &&
        typeof showDoubleLayer === "object" &&
        element?.id in showDoubleLayer &&
        showDoubleLayer[element.id]) ||
      (typeof showDoubleLayer === "boolean" && showDoubleLayer)
    );
  };

  const triggerVisibility = (element) => {
    setShowDoubleLayer((state) => ({
      ...state,
      [element.id]: !state?.[element.id],
    }));
  };

  return {
    renderTableCell,
    doubleLayerVisibility,
    createWithSpecificActionComponent,
    renderedDoubleLayerComponent,
    triggerVisibility,
    insertedProps,
    showDoubleLayer,
  };

  // ## NEEDS TO BE CALLED INSIDE A LOOP TO REACH DATAITEM
  //   const myWithSpecificAction = props.withSpecificAction
  //     ? props.withSpecificAction(
  //         { ...element, elementIndex: elementIndex },
  //         showDoubleLayer
  //       )
  //     : null;

  // ## NEEDS TO BE CALLED INSIDE A LOOP TO REACH DATAITEM
  //   const WithSpecificActionComponent = createWithSpecificActionComponent(
  //     element,
  //     withSpecificAction
  //   );

  // ## NEEDS TO BE CALLED INSIDE A LOOP TO REACH DATAITEM
  //   const isDropdownSpecificAction = () => {
  //     if (props.withSpecificAction) {
  //       if (
  //         props.withSpecificAction(element).condition &&
  //         props.withSpecificAction(element).actionType === "dropdown"
  //       )
  //         return true;
  //     } else return null;
  //   };
};

export default useBaseTableDoubleCard;
