import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import { isNotValidServerKey } from "utils/Numbers/Numbers";
import PropTypes from "prop-types";

const LongColumn = (props) => {
  const defaultGridDirection = props.longColumnGridDirection || "column";
  const { mySpanColumns } = props;

  const shouldDisplayTheResult =
    props.shouldDisplayTheResult === false ? false : true;

  const checkValuesOfHeaderItemsAfterLongColumn = () => {
    let spanBy = 1;

    if (!shouldDisplayTheResult) return spanBy;

    const afterValues = props.tableHeader.slice(props.columnIndex + 1);

    for (let i = 0; i < afterValues.length; i++) {
      if (
        isNotValidServerKey(
          props.customServerKey && props.customServerKey?.(props) !== true
            ? props.customServerKey?.(props)
            : props[afterValues[i].serverKey]
        )
      ) {
        spanBy++;
      } else {
        break;
      }
    }
    return spanBy;
  };

  useEffect(() => {
    props.insertProps.insertNewPropsHandler({
      tableCellParentSx: mySpanColumns,
    });
  }, [mySpanColumns]);

  const gridDirection =
    defaultGridDirection === "column" ? "gridColumn" : "gridRow";

  useEffect(() => {
    const spanBy = checkValuesOfHeaderItemsAfterLongColumn();
    props.setMySpanColumns((state) => {
      return {
        ...state,
        [`${props.columnIndex}-${props.id}`]: {
          [gridDirection]: `span ${spanBy}`,
        },
      };
    });
  }, []);

  if (!shouldDisplayTheResult) return defaultEmptyValue;

  const fallbackComponent = React.cloneElement(props.fallback, { ...props });

  return (
    <Box>
      {props[props.longColumnDisplayValueSelectedKey] ??
        props[props.tableHeaderItem.serverKey] ??
        fallbackComponent ??
        defaultEmptyValue}
    </Box>
  );
};

LongColumn.propTypes = {
  mySpanColumns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tableHeader: PropTypes.array,
  insertProps: PropTypes.object,
  tableHeaderItem: PropTypes.object,
  customServerKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  columnIndex: PropTypes.number,
  shouldDisplayTheResult: PropTypes.bool,
  id: PropTypes.number,
  fallback: PropTypes.object,
  longColumnDisplayValueSelectedKey: PropTypes.string,
  setMySpanColumns: PropTypes.func,
};

export default LongColumn;
