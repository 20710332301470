import React, { useState } from "react";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import FieldExaminationPointsConfirmation from "components/TableUtilitiesComponents/TableDoubleLayerComponents/FieldExaminationPointsConfirmation";
import {
  adminFieldExaminationRequest,
  adminFieldExaminationRequestVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import { isContentReady } from "utils/Array/Arrays";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

function FieldExaminationRequestTests({
  stations,
  appointmentData,
  questionnaireRecoord,
  ...props
}) {
  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const { tableContent, hasVirtual } = useGetVirtualStations(stations);

  const isAsphalt = inspectionLayerFlagChecker(
    props.inspection?.inspectionLayersList?.[0]?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const tableHeader = hasVirtual
    ? adminFieldExaminationRequestVS({ isAsphalt })
    : adminFieldExaminationRequest({ isAsphalt });

  const isFormFilled =
    questionnaireRecoord?.consultantPresent === true ||
    questionnaireRecoord?.consultantPresent === false
      ? true
      : false;

  const tableProps = {
    tableHeader: tableHeader,
    content: tableContent || [],
    isLoading: props.isFetching,
    injectProps: {
      mainData: props?.testsData || [],
      layersData: props?.layersData,
      tableId: TABLE_ID.SUB_TEST_STATUS,
      mySpanColumns,
      setMySpanColumns,
      myHiddenColumns,
      setMyHiddenColumns,
      hiddenColumnKeyFromList: "labScore",
      skipReasonKey: "labSkipReason",
      submittedFromLabAt: props.inspection?.submittedAt,
      customServerKey: (element) => {
        if (
          element.tableHeaderItem.serverKey === "scoresList" &&
          !isContentReady(
            element?.scoresList
              ?.map((s) => s?.labScore)
              ?.filter((s) => s !== null && s !== undefined)
          )
        ) {
          return null;
        }
        return true;
      },
    },
    doubleLayer: () => {
      return {
        visibility: isFormFilled,
        component: () => (
          <FieldExaminationPointsConfirmation
            scoresList={props}
            displayActualScores={props.inspection?.isApprovedOnce}
            skipReasonKey={"labSkipReason"}
          />
        ),
      };
    },
  };

  return (
    <Box>
      <SmartDoubleCardTable
        tableProps={tableProps}
        mobile={{
          injectProps: {
            ...tableProps.injectProps,
            longColumnGridDirection: "row",
          },
        }}
        desktop={{
          injectProps: {
            ...tableProps.injectProps,
            longColumnGridDirection: "column",
          },
        }}
      />
    </Box>
  );
}

FieldExaminationRequestTests.propTypes = {
  stations: PropTypes.array,
  appointmentData: PropTypes.object,
  questionnaireRecoord: PropTypes.object,
  isFetching: PropTypes.bool,
  inspection: PropTypes.object,
  testsData: PropTypes.array,
  layersData: PropTypes.array,
};

export default FieldExaminationRequestTests;
