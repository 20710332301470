import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import EmptyTable from "core-ui/BaseTable/EmptyTable/EmptyTable";
import useBaseTableDoubleCard from "hooks/useBaseTableDoubleCard";
import PropTypes from "prop-types";
import { _void } from "utils/Objects/Objects";
import { useTranslation } from "react-i18next";

const BaseTableDoubleCardResponsive = (props) => {
  const { t } = useTranslation(["common", "payment", "dashboard"]);

  const {
    createWithSpecificActionComponent,
    doubleLayerVisibility,
    renderedDoubleLayerComponent,
    triggerVisibility,
    insertedProps,
    showDoubleLayer,
    renderTableCell,
  } = useBaseTableDoubleCard(props);

  const isDataEmpty = props.content.length === 0;

  return (
    <Box
      sx={{
        ...props.mainWrapperSx,
      }}
    >
      {props.title && <BaseCardTitle mb={1}>{props.title}</BaseCardTitle>}

      {props.description && (
        <Typography
          sx={{ fontSize: "1.6rem" }}
          color={"grayDark.color1"}
          mb={3}
        >
          {props.description}
        </Typography>
      )}
      <Box sx={{ mt: 2 }}>
        {!isDataEmpty &&
          Array.isArray(props.content) &&
          props.content.map((dataItem, dataItemIndex) => {
            const withSpecificAction = props.withSpecificAction
              ? props.withSpecificAction(
                  { ...dataItem, elementIndex: dataItemIndex },
                  showDoubleLayer
                )
              : null;

            const WithSpecificActionComponent =
              createWithSpecificActionComponent(dataItem, withSpecificAction);

            const isDropdownSpecificAction = () => {
              if (props.withSpecificAction) {
                if (
                  props.withSpecificAction(dataItem).condition &&
                  props.withSpecificAction(dataItem).actionType === "dropdown"
                )
                  return true;
              } else return null;
            };

            return (
              <BaseCard
                key={dataItem.id}
                variant={"secondary"}
                sx={{
                  mt: 3,
                  position: "relative",
                  pb:
                    dataItemIndex > props.showRemoveButtonFromIndex - 1
                      ? 5
                      : "2rem",
                  pt: "2rem",
                }}
                px="2rem"
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: ".5fr 1% 1fr",
                    alignItems: "center",
                    gap: "1rem",
                    pr: props.withSpecificAction?.(dataItem)?.condition
                      ? "5rem"
                      : "2rem",
                  }}
                  onClick={
                    isDropdownSpecificAction()
                      ? triggerVisibility.bind(null, dataItem)
                      : _void
                  }
                >
                  {props.tableHeader.map((headerItem, columnIndex) => {
                    return (
                      <Fragment key={headerItem.serverKey}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.4rem",
                            color: "black",
                          }}
                        >
                          {t(headerItem.name, { ns: "tableHeader" })}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "1.4rem" }}
                        >
                          :
                        </Typography>
                        {insertedProps?.hiddenColumns?.includes(
                          `${columnIndex}-${dataItem.id}`
                        ) ? null : (
                          <Box
                            sx={{
                              fontWeight: 400,
                              fontSize: "1.4rem",
                              color: "black",
                              ...(!!insertedProps?.tableCellParentSx?.[
                                `${columnIndex}-${dataItem.id}`
                              ] &&
                                insertedProps?.tableCellParentSx?.[
                                  `${columnIndex}-${dataItem.id}`
                                ]),
                            }}
                          >
                            {renderTableCell(
                              dataItem,
                              headerItem,
                              dataItemIndex,
                              columnIndex
                            )}
                          </Box>
                        )}
                      </Fragment>
                    );
                  })}
                </Box>

                {withSpecificAction &&
                  props.withSpecificAction?.(dataItem)?.condition && (
                    <Box
                      sx={{
                        position: "absolute",
                        padding: "1rem .5rem",
                        top: ".5rem",
                        insetInlineEnd: "2rem",
                      }}
                    >
                      {WithSpecificActionComponent}
                    </Box>
                  )}
                {dataItemIndex > props.showRemoveButtonFromIndex - 1 && (
                  <Box
                    sx={{
                      background: "#b6d1cc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#618984",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "3rem",
                      textAlign: "center",
                    }}
                    onClick={props.onRemove.bind(null, dataItem)}
                  >
                    حذف
                  </Box>
                )}

                {/* ERROR ALERT */}
                {props.helperMessageComponent &&
                  props.helperMessageComponent?.(dataItem)}

                {doubleLayerVisibility(dataItem) &&
                  props.withSpecificAction?.(dataItem)?.condition && (
                    <Box>
                      <hr style={{ marginBlock: "2rem" }} />
                      {renderedDoubleLayerComponent(dataItem)}
                    </Box>
                  )}
                {doubleLayerVisibility(dataItem) &&
                  !props.withSpecificAction && (
                    <Box>
                      <hr style={{ marginBlock: "2rem" }} />
                      {renderedDoubleLayerComponent(dataItem)}
                    </Box>
                  )}
              </BaseCard>
            );
          })}
        {isDataEmpty && (
          <EmptyTable
            component="div"
            sx={{
              gridColumn: "1 / -1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            emptyData={props.emptyData}
          />
        )}
      </Box>

      {/* ADD BUTTON */}
      {props.appendComponent}
    </Box>
  );
};

BaseTableDoubleCardResponsive.propTypes = {
  content: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  mainWrapperSx: PropTypes.object,
  tableHeader: PropTypes.array,
  showRemoveButtonFromIndex: PropTypes.number,
  onRemove: PropTypes.func,
  helperMessageComponent: PropTypes.func,
  appendComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
  emptyData: PropTypes.object,
};

export default BaseTableDoubleCardResponsive;
