import { useState } from "react";
import {
  useGetInspectionForLabQuery,
  useSendLabAssignmentDataMutation,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { useGetlabTechniciansQuery } from "modules/Laboratory/apis/subusers-apis/subusers-api";
import { useNavigate, useParams } from "react-router-dom";
import LabNewRequestsDetails from "./Lab/LabNewRequestsDetails";
import FieldNewRequestsDetails from "./Field/FieldNewRequestsDetails";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { TestTypes } from "constants/TestTypes";
import { NewRequestDetail } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { useTranslation } from "react-i18next";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import { AlertTypes } from "constants/AlertTypes";
import BaseModal from "core-ui/BaseModal/BaseModal";
import AssignLabTechnicianModal from "components/AllModalContent/AssignLabTechnicianModal/AssignLabTechnicianModal";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import AssignRequestToLabButton from "./AssignRequestToLabButton";
import AssignLabRequestModal from "components/AllModalContent/AssignLabRequestModal/AssignLabRequestModal";

const NewRequestsDetails = (props) => {
  const params = useParams();

  const { t } = useTranslation(["dashboard"]);

  const { user } = useSelector((store) => store.auth);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const formData = watch();

  const [showModal, setShowModal] = useState(false);
  const [showLabModal, setShowLabModal] = useState(false);

  const [step, setStep] = useState(1);

  const selectedLabTechnician = watch("selectedLabTechnician");

  const navigate = useNavigate();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { data: inspection, isSuccess: isInspectionSuccess } =
    useGetInspectionForLabQuery(params.id);

  const isLab = inspection?.testType === TestTypes.LAB_TEST;

  const { data: labTechnicians, isFetching } = useGetlabTechniciansQuery();

  const [sendLabAssignmentData, sendLabAssignmentDataResponse] =
    useSendLabAssignmentDataMutation();

  const assignRequestToLab = () => {
    sendLabAssignmentData({
      inspectionId: inspection.id,
      labTechnicianId: user?.user_id,
      recaptcha: formData.recaptcha,
    })
      .unwrap()
      .then((responseData) => {
        navigate(`/assigned-requests/${responseData.inspectionId}`, {
          state: { fromPageId: "assignLab" },
        });
      })
      .catch((err) => {
        console.error(err);
        showAlertHandler({
          show: true,
          message: err?.data.errorCode,
          type: AlertTypes.ERROR,
        });
      });
  };

  const onSubmitHandler = () => {
    sendLabAssignmentData({
      inspectionId: inspection?.id,
      labTechnicianId: selectedLabTechnician?.id,
      recaptcha: formData.recaptcha,
    })
      .unwrap()
      .then((responseData) => {
        navigate(`/assigned-requests/${responseData.inspectionId}`, {
          state: { fromPageId: "assignLab" },
        });
        setShowModal(false);
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message: err?.data.errorCode,
          type: AlertTypes.ERROR,
        });
        setShowModal(false);
      });
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const onLeavePageHandler = () => {
    reset({ selectedLabTechnician: null });
    setShowModal(false);
    setStep(1);
  };
  const onLeaveLabPageHandler = () => {
    setShowLabModal(false);
  };

  const nextStepHandler = (e) => {
    e.preventDefault();
    setStep(2);
  };

  return isInspectionSuccess ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات الجديدة - رقم الطلب ${inspection?.id} `}</title>
      </Helmet>
      <BaseModal
        show={showModal}
        py={"3rem"}
        px={"5rem"}
        onHide={onLeavePageHandler}
        title={`إسناد الطلب رقم ${inspection?.id}`}
        modalContentWrapperSx={{ height: "35rem" }}
        mainContainerSx={{
          width: "55rem",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <AssignLabTechnicianModal
            allowRecaptcha={true}
            onHide={onLeavePageHandler}
            control={control}
            errors={{ ...errors }}
            register={register}
            labTechniciansData={labTechnicians}
            step={step}
            selectedLabTechnician={selectedLabTechnician}
            confirmMessage="هل انت متأكد من رغبتك بإسناد الطلب لفني المختبر"
            nextStepHandler={nextStepHandler}
            isLoading={sendLabAssignmentDataResponse?.isLoading}
          />
        </form>
      </BaseModal>
      <BaseModal
        show={showLabModal}
        py={"3rem"}
        px={"5rem"}
        onHide={onLeaveLabPageHandler}
        title={`إسناد الطلب رقم ${inspection?.id}`}
        mainContainerSx={{
          width: "55rem",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(assignRequestToLab, onErrorHandler)}
        >
          <AssignLabRequestModal
            control={control}
            errors={errors}
            isLoading={sendLabAssignmentDataResponse?.isLoading}
            onHide={onLeaveLabPageHandler}
          />
        </form>
      </BaseModal>
      <DashboardIntro
        title={`${t("الطلب رقم", { ns: "dashboard" })}: ${inspection.id}`}
        variant="secondary"
        description={t("يمكنك إسناد أو تجاهل الطلب", {
          ns: "dashboard",
        })}
        descriptionSx={{ maxWidth: "70%" }}
        withAlertHandler={{
          ...(!isLab && {
            comp: (
              <BaseAlert
                my={5}
                width={`100%`}
                mb={5}
                show={true}
                labelSx={{ fontWeight: "bold" }}
                type={AlertTypes.WARNING}
                message={"LABORATORY_NEW_REQUESTS_WARNING_MSG"}
                icon={false}
              />
            ),
          }),
        }}
        action={
          <Stack direction="column" alignItems={"end"}>
            <AssignRequestToLabButton
              setShowLabModal={setShowLabModal}
              isFetching={isFetching}
              isLab={isLab}
              sendLabAssignmentDataResponse={sendLabAssignmentDataResponse}
              setShowModal={setShowModal}
            />
            <BaseAlert
              mb={2}
              show={alert.show}
              type={alert.type}
              message={alert.message}
              destroy={hideAlertHandler}
              autoClose={3}
            />
          </Stack>
        }
        breadcrumbData={NewRequestDetail({ id: inspection.id })}
      />
      {isLab ? (
        <LabNewRequestsDetails inspection={inspection} />
      ) : (
        <FieldNewRequestsDetails inspection={inspection} />
      )}
    </>
  ) : (
    <BaseLoader />
  );
};

export default NewRequestsDetails;
