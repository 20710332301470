import { Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import PropTypes from "prop-types";
import { publicAssetsPath } from "utils/Paths/Paths";

const Location = (props) => {
  const { location } = props;

  return (
    <Typography
      className={"request-data-details__value"}
      sx={{
        fontWeight: "bold !important",
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      {location ? (
        <>
          <a
            href={location}
            target="_blank"
            className="request-data-details__link"
          >
            الموقع
          </a>
          <img
            src={publicAssetsPath("icons/popout-svgrepo.svg")}
            width="auto"
            alt="popout-svg"
          />
        </>
      ) : (
        defaultEmptyValue
      )}
    </Typography>
  );
};

Location.propTypes = {
  location: PropTypes.string,
};

export default Location;
