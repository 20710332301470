import JsBarcode from "jsbarcode";

/**
 *
 * @param {string} string - The string that should be filtered from any special characters expect these - _
 * @returns {string} - it removes every special characters like [!@#$%^&*()+= ], but (- _) are not considered as special characters
 */
export const noSpecialCharsString = (string) =>
  string.replace(/[^0-9a-zA-Z-_]/g, "");

export const textToBase64Barcode = (code) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, code, { format: "CODE39" });
  return canvas.toDataURL("image/png");
};

export const removeLeadingZeros = (string) => {
  if (!string) return "";
  return string.replace(/^0+/, "") || "0";
};

export function containsArabicLetters(v) {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(v);
}
export function isValidString(text) {
  const allowedRegex = /^[a-zA-Z0-9-/]*$/;
  return allowedRegex.test(text);
}

export const customValidateLicenseNumber = (licenseNumber) => {
  if (containsArabicLetters(licenseNumber)) {
    return "يمكنك فقط استخدام الاحرف الانجليزية";
  } else if (licenseNumber?.includes(" ")) {
    return "غير مسموح باستخدام المسافات بين الاحرف الانجليزية";
  } else if (!isValidString(licenseNumber)) {
    return "يمكنك فقط استخدام الرموز التالية [- /]";
  }
};
