import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, Typography } from "@mui/material";
import { AssignedRequestsDetail } from "services/StaticLookup/Breadcrumb";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { renderTabs } from "utils/Tabs/Tabs";
import { requestAssignedRequestsDetailsTabs } from "services/StaticLookup/Tabs";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import { AlertTypes } from "constants/AlertTypes";
import {
  useCheckEditableForLaboratoryQuery,
  useGetInspectionForLabQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { Helmet } from "react-helmet";
import { DATA_ID } from "constants/Details";
import useAlert from "hooks/useAlert";
import { TestTypes } from "constants/TestTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import LabAssignedRequestDetails from "modules/Laboratory/components/DataDetails/AssignedRequests/Lab";
import FieldAssignedRequestDetails from "modules/Laboratory/components/DataDetails/AssignedRequests/Field";
import LabAssignedRequestTests from "./Lab";
import FieldAssignedRequestTests from "./Field";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { EditableStatusEnum } from "services/StaticLookup/EditableStatus";
import { REQUEST_TYPE } from "constants/RequestType";
import BaseMUITooltip from "core-ui/BaseTooltip/BaseMUITooltip";
import BaseModal from "core-ui/BaseModal/BaseModal";
import AssignLabTechnicianModal from "components/AllModalContent/AssignLabTechnicianModal/AssignLabTechnicianModal";
import { useForm } from "react-hook-form";
import { useGetlabTechniciansQuery } from "modules/Laboratory/apis/subusers-apis/subusers-api";
import { useReassignTechnicianMutation } from "modules/Admin/apis/reassign-apis/reassign-apis";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";

const AssignedRequestsDetails = () => {
  const params = useParams();

  const { t } = useTranslation();

  const { state } = useLocation();

  const navigate = useNavigate();

  const fromPageId = state?.fromPageId;

  const navigatedUserTimer = state?.navigatedUserTimer;

  const editError = state?.editError;

  const [tabsActiveIndex, setTabsActiveIndex] = useState(1);

  useEffect(() => {
    if (navigatedUserTimer) {
      clearTimeout(navigatedUserTimer);
    }
  }, [navigatedUserTimer]);

  const handleChange = (_, newValue) => {
    setTabsActiveIndex(newValue);
  };

  const {
    data: inspection,
    isFetching: isInspectionFetching,
    isSuccess: isInspectionSuccess,
    refetch,
  } = useGetInspectionForLabQuery(params.id);

  const isLab = inspection?.testType === TestTypes.LAB_TEST;

  const { data: checkEditable } = useCheckEditableForLaboratoryQuery(
    inspection?.id,
    {
      skip: !inspection?.id,
    }
  );

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  // FIELD
  const {
    alert: saveAttachmentAlert,
    showAlertHandler: showSaveAttachmentAlert,
    hideAlertHandler: hideSaveAttachmentAlert,
  } = useAlert();

  // FIELD
  const {
    alert: raiseResultsAlert,
    showAlertHandler: showRaiseResultsAlertHandler,
    hideAlertHandler: hideRaiseResultsAlertHandler,
  } = useAlert();

  // LAB
  const {
    alert: labDraftAlert,
    showAlertHandler: showLabDraftAlertHandler,
    hideAlertHandler: hideLabDraftAlertAlertHandler,
  } = useAlert();

  useEffect(() => {
    if (fromPageId === "assignLab") {
      refetch();
    }
    showAlertHandler({
      show: fromPageId === "assignLab",
      type: AlertTypes.SUCCESS,
    });
  }, []);

  const {
    alert: editStatusAlert,
    showAlertHandler: showEditStatusAlert,
    hideAlertHandler: hideEditStatusAlert,
  } = useAlert();

  const editRequestNavigator = () => {
    const editFormPage = isLab ? "lab-form" : "field-form";
    navigate(`/assigned-requests/${inspection?.id}/${editFormPage}`, {
      state: {
        isEditing: true,
      },
    });
  };

  const isPending = inspection?.lastEditStatus === EditableStatusEnum.PENDING;
  const isRejected = inspection?.lastEditStatus === EditableStatusEnum.REJECTED;

  const isReassignDisabled =
    inspection?.status === REQUEST_TYPE.SUCCEED ||
    inspection?.status === REQUEST_TYPE.TEST_STARTED ||
    inspection?.status === REQUEST_TYPE.SKIPPED ||
    inspection?.status === REQUEST_TYPE.FAILED;

  useEffect(() => {
    if (inspection) {
      function showEditAlert() {
        let message = "";
        let type = "";
        let show =
          inspection.lastEditStatus === EditableStatusEnum.APPROVED ||
          inspection.lastEditStatus === EditableStatusEnum.REJECTED ||
          inspection.lastEditStatus === EditableStatusEnum.PENDING ||
          !!editError;

        if (inspection.lastEditStatus === EditableStatusEnum.APPROVED) {
          message = SuccessCodes.APPROVED_EDIT_REQUEST;
          type = AlertTypes.SUCCESS;
        }
        if (inspection.lastEditStatus === EditableStatusEnum.REJECTED) {
          type = AlertTypes.WARNING;
        }
        if (inspection.lastEditStatus === EditableStatusEnum.PENDING) {
          message = SuccessCodes.SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM;
          type = AlertTypes.WARNING;
        }
        if (editError) {
          message = editError;
          type = AlertTypes.ERROR;
        }
        showEditStatusAlert({
          show,
          message,
          type,
        });
      }
      showEditAlert();
    }
  }, [inspection, editError]);

  //// reassign lab technician /////

  const [reassignTechnician] = useReassignTechnicianMutation();

  const {
    alert: reassignAlert,
    showAlertHandler: showReassignAlertHandler,
    hideAlertHandler: hideReassignAlertHandler,
  } = useAlert();

  const { data: labTechnicians } = useGetlabTechniciansQuery(
    {
      assigneeId: inspection?.labTechId,
    },
    { skip: !inspection?.labTechId }
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(1);

  const selectedLabTechnician = watch("selectedLabTechnician");

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const onLeavePageHandler = () => {
    reset({ selectedLabTechnician: null });
    setShowModal(false);
    setStep(1);
  };

  const nextStepHandler = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const onSubmitHandler = () => {
    reassignTechnician({
      assignmentId: inspection?.assignmentId,
      newAssigneeUserId: selectedLabTechnician?.id,
    })
      .unwrap()
      .then((responseData) => {
        onLeavePageHandler();
        refetch();
        showReassignAlertHandler({
          show: true,
          message: SuccessCodes.TECHNICIAN_REASSIGNED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        onLeavePageHandler();
        showReassignAlertHandler({
          show: true,
          message:
            err.data?.errorCode === ErrorCodes.REQ_CAN_NOT_BE_ASSIGNED
              ? ErrorCodes.REQ_CAN_NOT_BE_ASSIGNED
              : ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
      });
  };

  ////////////////////

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`الطلبات المسندة - رقم الطلب ${inspection?.id}`}</title>
        <link rel="canonical" href="/new-requests" />
      </Helmet>

      <BaseModal
        show={showModal}
        py={"3rem"}
        px={"5rem"}
        onHide={onLeavePageHandler}
        title={`إعادة إسناد الطلب رقم ${inspection?.id}`}
        mainContainerSx={{
          width: "55rem",
        }}
        containerSx={{
          height: step === 1 ? "39rem" : "10rem",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <AssignLabTechnicianModal
            allowRecaptcha={false}
            onHide={onLeavePageHandler}
            control={control}
            errors={{ ...errors }}
            register={register}
            labTechniciansData={labTechnicians}
            step={step}
            selectedLabTechnician={selectedLabTechnician}
            confirmMessage="هل انت متأكد من رغبتك بإعادة إسناد الطلب لفني المختبر"
            nextStepHandler={nextStepHandler}
          />
        </form>
      </BaseModal>

      <DashboardIntro
        title={t(`الطلب رقم ${inspection?.id}`, { ns: "dashboard" })}
        description={t(
          "يمكنك تحديث نتيجة كل اختبار على حدة والاطلاع على مواعيد فحص الاختبارات الحقلية",

          { ns: "dashboard" }
        )}
        variant={"secondary"}
        action={
          <DashboardIntroAction>
            <BaseAlert
              mb={4}
              sx={{ mb: 2 }}
              destroy={hideAlertHandler}
              autoClose={3}
              show={alert.show}
              message={SuccessCodes.ASSIGNED_LAB_SUCCESSFULLY}
              type={alert.type}
              labelSx={{ fontWeight: "bold" }}
            />
            <BaseAlert
              mb={4}
              sx={{ mb: 2 }}
              destroy={hideReassignAlertHandler}
              autoClose={3}
              show={reassignAlert.show}
              message={reassignAlert.message}
              type={reassignAlert.type}
              labelSx={{ fontWeight: "bold" }}
            />
            <BaseAlert
              sx={{ mb: 2 }}
              show={raiseResultsAlert.show}
              type={raiseResultsAlert.type}
              message={raiseResultsAlert.message}
              destroy={hideRaiseResultsAlertHandler}
              autoClose={5}
            />
            <BaseAlert
              sx={{ mb: 4 }}
              show={saveAttachmentAlert.show}
              type={saveAttachmentAlert.type}
              message={saveAttachmentAlert.message}
              destroy={hideSaveAttachmentAlert}
              autoClose={10}
            />
            <BaseAlert
              sx={{ mb: 4 }}
              show={labDraftAlert.show}
              type={labDraftAlert.type}
              message={labDraftAlert.message}
              destroy={hideLabDraftAlertAlertHandler}
              autoClose={10}
            />
            {inspection && (
              <BaseAlert
                mb={2}
                show={editStatusAlert.show}
                closeIcon={!isPending && !editError}
                autoClose={editError ? 5 : false}
                destroy={hideEditStatusAlert}
                message={editStatusAlert.message}
                type={editStatusAlert.type}
                labelSx={{ fontWeight: 500 }}
              >
                {isRejected && (
                  <Typography fontWeight={"inherit"}>
                    عذرًا، لم يتم قبول طلب تحديث النتائج، لمعرفة المزيد يمكنك
                    الإطلاع على{" "}
                    <Link
                      to={`/edit-requests/${inspection?.lastEditRequestId}`}
                    >
                      تفاصيل طلب التعديل
                    </Link>
                  </Typography>
                )}
              </BaseAlert>
            )}
            {checkEditable === EditableStatusEnum.CAN_EDIT && (
              <BaseButton
                variant="secondary"
                sx={{ minWidth: "12.2rem" }}
                onClick={editRequestNavigator}
              >
                تعديل النتائج
              </BaseButton>
            )}
            {inspection?.id && !isLab && (
              <BaseMUITooltip
                arrow
                title={
                  isReassignDisabled
                    ? "لا يمكن إعادة إسناد الطلب، يوجد نتائج مدخلة"
                    : ""
                }
              >
                <span>
                  <BaseButton
                    sx={{ minWidth: "12.2rem", marginLeft: "1rem" }}
                    onClick={() => setShowModal(true)}
                    disabled={isReassignDisabled}
                  >
                    إعادة إسناد الطلب
                  </BaseButton>
                </span>
              </BaseMUITooltip>
            )}
          </DashboardIntroAction>
        }
        breadcrumbData={AssignedRequestsDetail({ id: inspection?.id })}
      />

      <BaseCard
        mt={6}
        py={"2rem"}
        px={"3rem"}
        sx={{
          minHeight: "70vh",
          position: "relative",
        }}
      >
        <Tabs
          value={tabsActiveIndex}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
          className="tabs"
        >
          {renderTabs(requestAssignedRequestsDetailsTabs).map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{
                background: tabsActiveIndex === tab.value ? "#1F766C0D" : "",
                fontSize: "1.6rem",
              }}
            />
          ))}
        </Tabs>

        {tabsActiveIndex === 1 && (
          <>
            {isLab && isInspectionSuccess ? (
              <LabAssignedRequestDetails
                title={"تفاصيل الطلب"}
                inspection={inspection}
                dataId={DATA_ID.LAB_REQUEST_DETAILS}
              />
            ) : (
              !isLab &&
              isInspectionSuccess && (
                <FieldAssignedRequestDetails
                  inspection={inspection}
                  checkEditable={checkEditable}
                  title={"تفاصيل الطلب"}
                  dataId={DATA_ID.LAB_REQUEST_DETAILS}
                />
              )
            )}
          </>
        )}
        {tabsActiveIndex === 2 && (
          <>
            {isLab && isInspectionSuccess ? (
              <LabAssignedRequestTests
                inspection={inspection}
                isInspectionFetching={isInspectionFetching}
                isRejected={isRejected}
                showLabDraftAlertHandler={showLabDraftAlertHandler}
              />
            ) : (
              !isLab &&
              isInspectionSuccess && (
                <FieldAssignedRequestTests
                  inspection={inspection}
                  isInspectionFetching={isInspectionFetching}
                  showSaveAttachmentAlert={showSaveAttachmentAlert}
                  showRaiseResultsAlertHandler={showRaiseResultsAlertHandler}
                  isRejected={isRejected}
                />
              )
            )}
          </>
        )}
      </BaseCard>
    </>
  );
};

export default AssignedRequestsDetails;
