import { queryBuilder } from "utils/ApiHelpers";
import contractorApi from "..";
const transformContractData = (responseData) => {
  const transformData = responseData.map((d) => {
    return {
      id: d.id,
      name: d.nameAr,
    };
  });
  return transformData;
};

const contractorResourceApi = contractorApi.injectEndpoints({
  endpoints: (builder) => ({
    postNewInspection: builder.mutation({
      query: (body) => ({
        url: "/api/contractor/contractors/v2/newInspection",
        method: "POST",
        body: body,
      }),

      invalidatesTags: [
        "Wallet",
        "C-Request-Details",
        "Contractor-Inspection-Tests",
      ],
    }),

    newConsultantInfo: builder.mutation({
      query: (data) => ({
        url: `/api/contractor/licenses/updateConsultantInfo/${data.licenseId}`,
        method: "POST",
        body: data.body,
      }),

      invalidatesTags: ["C-Request-Details"],
    }),

    getFullContractorData: builder.query({
      query: () => ({
        url: "/api/contractor/contractors/retrieveLoggedInContractor",
      }),
      providesTags: ["Wallet"],
    }),

    getContractorRequests: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/contractor/contractors/requests?${q}`,
        };
      },
      providesTags: ["C-Request-Details"],
    }),
    getContractorlicensesRequest: builder.query({
      query: (data) => ({
        url: `/api/contractor/licenses/requests/${data.request_id}${
          data.request_search_query || ""
        }`,
      }),
      providesTags: ["C-Request-Details"],
    }),
    getContractorRequestDetailsLocation: builder.query({
      query: (request_id) => ({
        url: `/api/contractor/licenses/requests/${request_id}`,
      }),
      providesTags: ["C-Request-Details"],
    }),
    getUserInfo: builder.query({
      query: () => ({
        url: `/api/contractor/contractors/userInfo`,
      }),
    }),
    getRegions: builder.query({
      query: () => ({
        url: `/api/contractor/contractors/regions`,
      }),
      transformResponse: transformContractData,
    }),
    getCitiesByRegions: builder.query({
      query: (region_id) => ({
        url: `/api/contractor/contractors/cities/${region_id}`,
      }),
      transformResponse: transformContractData,
    }),
    updateAddress: builder.mutation({
      query: (body) => ({
        url: `/api/contractor/contractors/updateAddress`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Wallet"],
    }),
    cancelRequest: builder.mutation({
      query: (body) => ({
        url: `/api/contractor/contractors/inspection/cancel`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["C-Request-Details", "Wallet"],
    }),
    getOutdatedRequestFlag: builder.query({
      query: () => ({
        url: `/api/contractor/contractors/outdatedInspectionsCheck`,
      }),
    }),
    getLabTestAppointmentForContractor: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/appointments/v2/contractor/${inspectionId}`,
      }),
      providesTags: ["Reschedule-Appointment"],
    }),
    // NEW
    getInspectionAsphaltReport: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/inspectionReports/aspelt/report/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
    getInspectionSoilReport: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/inspectionReports/soil/report/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
    getInspectionConcreteProductReport: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/inspectionReports/concreteproducts/report/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
    getInspectionFieldReport: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/inspectionReports/soil/field/report/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
    getInspectionAsphaltFieldReport: builder.query({
      query: (inspectionId) => ({
        url: `/api/contractor/inspectionReports/aspelt/field/report/${inspectionId}`,
      }),
      providesTags: ["Request"],
    }),
  }),
});

export const {
  useLazyGetInspectionAsphaltReportQuery,
  useGetInspectionAsphaltReportQuery,
  usePostNewInspectionMutation,
  useGetFullContractorDataQuery,
  useLazyGetFullContractorDataQuery,
  useGetContractorRequestsQuery,
  useLazyGetContractorRequestsQuery,
  useNewConsultantInfoMutation,
  useGetContractorlicensesRequestQuery,
  useLazyGetContractorlicensesRequestQuery,
  useGetContractorRequestDetailsLocationQuery,
  useGetUserInfoQuery,
  useGetRegionsQuery,
  useLazyGetCitiesByRegionsQuery,
  useUpdateAddressMutation,
  useCancelRequestMutation,
  useGetOutdatedRequestFlagQuery,
  useGetLabTestAppointmentForContractorQuery,
  useGetInspectionSoilReportQuery,
  useGetInspectionConcreteProductReportQuery,
  useGetInspectionFieldReportQuery,
  useGetInspectionAsphaltFieldReportQuery,
} = contractorResourceApi;

export default contractorResourceApi;
