import { useState } from "react";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import {
  assignedRequestTestsFeildSubmitted,
  assignedRequestTestsFeildSubmittedVS,
} from "services/StaticLookup/TableHeader/TableHeader"; // ⚠️
import FieldExaminationTestsActions from "./FieldExaminationTestsActions/FieldExaminationTestsActions";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import FieldExaminationPointsConfirmation from "components/TableUtilitiesComponents/TableDoubleLayerComponents/FieldExaminationPointsConfirmation";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

const FieldExaminationTestsContent = (props) => {
  const inspectionLayer = props.inspection?.inspectionLayersList?.[0];

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const { stations } = useGetStationsWithTestData(props?.inspection);

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );
  const { hasVirtual, tableContent } = useGetVirtualStations(stations);

  const tableHeader = hasVirtual
    ? assignedRequestTestsFeildSubmittedVS({ user: "tech", isAsphalt })
    : assignedRequestTestsFeildSubmitted({ user: "tech", isAsphalt });

  const isFormFilled =
    props.questionnaireRecoord?.consultantPresent === true ||
    props.questionnaireRecoord?.consultantPresent === false
      ? true
      : false;

  const mainWrapperPadding = isFormFilled ? 2 : 5;

  const tableProps = {
    title: "الاختبارات",
    tableHeader: tableHeader,
    injectProps: {
      tableId: TABLE_ID.FIELD_EXAMINATION,
      extraStatus: props.inspection?.status,
      displayScore: true,
      mySpanColumns,
      setMySpanColumns,
      myHiddenColumns,
      setMyHiddenColumns,
      hiddenColumnKeyFromList: "labScore",
      submittedFromLabAt: props.inspection?.submittedAt,
    },
    content: tableContent || [],
    isLoading: props.isLoading,
    doubleLayer: () => ({
      visibility: isFormFilled,
      component: () => (
        <FieldExaminationPointsConfirmation stations={stations} />
      ),
    }),
  };

  return (
    <Box mt={5}>
      <SmartDoubleCardTable
        tableProps={tableProps}
        desktop={{
          injectProps: {
            ...tableProps.injectProps,
            longColumnGridDirection: "column",
          },
        }}
        mobile={{
          injectProps: {
            ...tableProps.injectProps,
            longColumnGridDirection: "row",
          },
        }}
      />
      <Box mt={12} mb={5} pl={mainWrapperPadding}>
        <FieldExaminationTestsActions
          isAppointmentReportDataFetching={
            props.isAppointmentReportDataFetching
          }
          isQuestionnaireRecoordFetching={props.isQuestionnaireRecoordFetching}
          appointmentId={props?.appointmentId}
          inspectionLayer={inspectionLayer}
          appointmentReportData={props?.appointmentReportData}
          questionnaireRecoord={props?.questionnaireRecoord}
          isFormFilled={isFormFilled}
          inspection={props?.inspection}
          t={props.t}
        />
      </Box>
    </Box>
  );
};

FieldExaminationTestsContent.propTypes = {
  inspection: PropTypes.object,
  questionnaireRecoord: PropTypes.object,
  isLoading: PropTypes.bool,
  isAppointmentReportDataFetching: PropTypes.bool,
  isQuestionnaireRecoordFetching: PropTypes.bool,
  appointmentReportData: PropTypes.object,
  t: PropTypes.func,
  appointmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FieldExaminationTestsContent;
