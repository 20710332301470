import { Box } from "@mui/material";
import PropTypes from "prop-types";

const CombinedField = (props) => {
  return (
    <Box sx={props.sx}>
      {props.field1 ? `${props.field1}/${props.field2}` : props.field2}
    </Box>
  );
};

CombinedField.propTypes = {
  sx: PropTypes.object,
  field1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  field2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CombinedField;
