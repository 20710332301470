export const monthsLookup = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const daysLookup = [
  "اﻷحد",
  "اﻷثنين",
  "الثلاثاء",
  "اﻷربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];

export const time = [
  { id: "1", label: "09:00 ص", value: "09" },
  { id: "2", label: "10:00 ص", value: "10" },
  { id: "3", label: "11:00 ص", value: "11" },
  { id: "4", label: "12:00 م", value: "12" },
  { id: "5", label: "01:00 م", value: "13" },
  { id: "6", label: "02:00 م", value: "14" },
  { id: "7", label: "03:00 م", value: "15" },
];

export const SystemSettingsDuration = Array.from({ length: 24 }, (_, i) => ({
  id: i + 1,
  value: i + 1,
  name: `${i + 1} ساعة`,
}));
