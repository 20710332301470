import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import { AdminAddUserManagementBreadcrumb } from "services/StaticLookup/Breadcrumb";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { Box } from "@mui/material";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { ValidEmailPattern } from "constants/Forms";
import useAlert from "hooks/useAlert";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { useNavigate } from "react-router-dom";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { validateIdNumberField } from "utils/Numbers/Numbers";
import { useAddNewLabTechnicianMutation } from "modules/Auth/apis/user-api";
import ConfirmAddNewLabTech from "components/AllModalContent/ConfirmAddNewLabTech/ConfirmAddNewLabTech";
import BaseModal from "core-ui/BaseModal/BaseModal";
import { useEffect, useState } from "react";
import { _scrollToTop } from "utils/DOM/DOM";
import { customValidateMobileNumber } from "utils/Forms/Forms";

let navigateUserTimer = null;

const AddUser = () => {
  const { t } = useTranslation(["auth", "common", "dashboard"]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange", shouldUnregister: true });

  const [addNewUser, addNewUserResponse] = useAddNewLabTechnicianMutation();

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const navigate = useNavigate();
  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const handleNavigation = () => {
    navigateUserTimer = setTimeout(() => {
      navigate(`/users-management`);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (navigateUserTimer) {
        clearTimeout(navigateUserTimer);
      }
    };
  }, []);

  const onSubmitHandler = (data, event) => {
    if (event) {
      event.preventDefault();
    }

    const newUserData = {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      nationalIdNumber: data.nationalId,
    };

    addNewUser(newUserData)
      .unwrap()
      .then((_) => {
        setShowConfirmModal(false);
        showAlertHandler({
          show: true,
          message: SuccessCodes.USER_CREATED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        handleNavigation();
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message:
            err.data?.message ||
            err.data?.errorCode ||
            ErrorCodes.UNKNOWN_ERROR,
          type: AlertTypes.ERROR,
        });
        setShowConfirmModal(false);
      })
      .finally(() => {
        _scrollToTop();
      });
  };

  const onErrorHandler = (errors, event) => {
    console.log("errors: ", errors);
  };

  const handleConfirmClick = () => {
    handleSubmit(() => setShowConfirmModal(true), onErrorHandler)();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين`}</title>
      </Helmet>

      <DashboardIntro
        title={"إضافة مستخدم جديد"}
        description={"قم بتعبئة بيانات المستخدم التالية"}
        breadcrumbData={AdminAddUserManagementBreadcrumb()}
        action={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={5}
            closeIcon={false}
          />
        }
      />

      <BaseModal
        show={showConfirmModal}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <ConfirmAddNewLabTech
          onConfirm={(event) => handleSubmit(onSubmitHandler)(event)}
          onHide={onLeavePageHandler}
        />
      </BaseModal>

      <BaseCard py={"5rem"} px={"6rem"} mt={6} minHeight={"75%"}>
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <BaseCardTitle marginBottom={5}>المعلومات العامة</BaseCardTitle>
          <WrapperContentGrid
            gap={10}
            sx={{ marginBottom: "4rem", flexDirection: "row", mt: "3rem" }}
          >
            <BaseInput
              htmlFor="name"
              label={t("name")}
              placeholder={t("الاسم")}
              maxLength={50}
              minLength={1}
              labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
              name="name" // This name attribute should match what you are using in the handler.
              register={{
                ...register("name", {
                  // Make sure this matches the name
                  required: {
                    value: true,
                    message: `${t("field")} ${t("name")} ${t("required")}`,
                  },
                  minLength: {
                    value: 6,
                    message: "الرجاء إدخال الاسم",
                  },
                }),
              }}
              errors={errors}
            />
            <BaseInput
              htmlFor="nationalId"
              label={t("nationalId")}
              name={"nationalId"}
              maxLength={10}
              register={{
                ...register("nationalId", {
                  required: {
                    value: true,
                    message: `${t("field")} ${t("idNumber")} ${t("required")}`,
                  },
                  maxLength: {
                    value: 10,
                    message: `فضلا تأكد من صحة المعلومات المدخلة`,
                  },
                  validate: validateIdNumberField,
                }),
              }}
              errors={errors}
            />
          </WrapperContentGrid>
          <hr />

          <BaseCardTitle marginBottom={5} mt={10}>
            معلومات التواصل
          </BaseCardTitle>

          <WrapperContentGrid
            gap={10}
            sx={{ marginBottom: "4rem", flexDirection: "row", mt: "3rem" }}
          >
            <BaseInput
              htmlFor="mobile"
              label={"رقم الجوال"}
              placeholder="05 xxx xxxx"
              labelStyles={{
                fontWeight: "bold",
                color: "grayDark.color",
              }}
              name="mobile"
              register={{
                ...register("mobile", {
                  required: {
                    value: true,
                    message: "حقل رقم الجوال مطلوب",
                  },
                  validate: customValidateMobileNumber,
                }),
              }}
              errors={errors}
            />
            <BaseInput
              htmlFor="email"
              label={t("email")}
              placeholder={t("البريد الإلكتروني")}
              maxLength={30}
              labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
              name="email"
              register={{
                ...register("email", {
                  required: {
                    value: true,
                    message: `${t("field")} ${t("email")} ${t("required")}`,
                  },
                  pattern: {
                    value: ValidEmailPattern,
                    message: `${t("Invalid email address", { ns: "auth" })}`,
                  },
                }),
              }}
              errors={errors}
            />
          </WrapperContentGrid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              flexWrap: "wrap",
              gap: 4,
            }}
          >
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              variant={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("تراجع")}
            </BaseButton>
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              type="button"
              variant={addNewUserResponse.isLoading ? "disabled" : ""}
              disabled={addNewUserResponse.isLoading}
              onClick={handleConfirmClick}
            >
              {t("إضافة")}
            </BaseButton>
          </Box>
        </form>
      </BaseCard>
    </>
  );
};

export default AddUser;
