import { Box, Typography } from "@mui/material";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "./CompanyDetails.module.scss";
import PointsCard from "../PointsCard/PointsCard";
import PropTypes from "prop-types";

const CompanyDetails = (props) => {
  return !props.data ? (
    <BaseLoader />
  ) : (
    <Box className={classes["company-data"]} mt={6} ml={4}>
      <Box className={classes["company-data__wrapper"]}>
        <Typography className={classes["company-data__title"]}>
          بيانات الشركة
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={classes["company-data__item"]}>
            <Typography className={classes["company-data__label"]}>
              اسم الشركة
            </Typography>
            <Typography className={classes["company-data__value"]}>
              {props.data?.name || "لايوجد"}
            </Typography>
          </Box>
          <Box className={classes["company-data__item"]}>
            <Typography className={classes["company-data__label"]}>
              الرقم الموحد للمنشأة
            </Typography>
            <Typography className={classes["company-data__value"]}>
              {props.data?.crNumber || "لايوجد"}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />

      <Box className={classes["company-data__wrapper"]}>
        <Typography className={classes["company-data__title"]}>
          رصيد النقاط الحالي
        </Typography>
        <Box className={classes["company-data__item"]}>
          <PointsCard
            points={props.data?.wallet}
            isFetching={props.isFetching}
          />
        </Box>
      </Box>
    </Box>
  );
};

CompanyDetails.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.number,
  isFetching: PropTypes.bool,
};

export default CompanyDetails;
