import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const BaseDataItem = (props) => {
  return (
    <Box className={"request-data-details__item"}>
      <Typography
        className={"request-data-details__label"}
        component={props.labelComponentAs || "p"}
      >
        {props.label}
      </Typography>
      <Typography
        className={
          !props.valueClassName
            ? `request-data-details__value`
            : props.valueClassName
        }
        component={props.valueComponentAs || "p"}
        sx={{ ...props.valueSx, marginTop: 1 }}
      >
        {props.value}
      </Typography>
    </Box>
  );
};

export default BaseDataItem;

BaseDataItem.defaultProps = {
  labelComponentAs: "p",
  valueComponentAs: "p",
};

BaseDataItem.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  valueClassName: PropTypes.string,
  labelComponentAs: PropTypes.string,
  valueComponentAs: PropTypes.string,
  valueSx: PropTypes.object,
};
