import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import PropTypes from "prop-types";

const ResponsiveTechnicianCorrection = ({
  renderTechnicianConfirmations,
  editedStation,
  renderScores,
}) => {
  return (
    <BaseCard sx={{}} variant="alternative" px={"2rem"} py={"1rem"} mt={2}>
      <Box
        sx={{
          gridColumn: `1 / -1`,
          gridRow: 1,
        }}
      >
        {renderTechnicianConfirmations()}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ".5fr 1% 1fr",
          gap: "1rem",
          mt: 2,
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "medium" }}>
          الكثافة الجافة العظمي
        </Typography>
        <Typography sx={{ fontSize: "1.4rem" }}>:</Typography>
        <Typography sx={{ fontSize: "1.4rem" }}>
          {editedStation?.updatedMaxProctor}
        </Typography>

        <Typography sx={{ fontSize: "1.4rem", fontWeight: "medium" }}>
          الرطوبة المثلي
        </Typography>
        <Typography sx={{ fontSize: "1.4rem" }}>:</Typography>
        <Typography sx={{ fontSize: "1.4rem" }}>
          {editedStation?.updatedOptimumProctor}
        </Typography>

        <Typography sx={{ fontSize: "1.4rem", fontWeight: "medium" }}>
          النتيجة
        </Typography>
        <Typography sx={{ fontSize: "1.4rem" }}>:</Typography>
        {editedStation?.testStationMappingEdits?.map((edit, idx) => (
          <Typography
            key={new Date().getTime().toString().substring(0, 10)}
            sx={{ fontSize: "1.4rem" }}
          >
            {renderScores(edit.updatedScores)}
          </Typography>
        ))}
      </Box>
    </BaseCard>
  );
};

ResponsiveTechnicianCorrection.propTypes = {
  renderTechnicianConfirmations: PropTypes.func,
  editedStation: PropTypes.object,
  renderScores: PropTypes.func,
};

export default ResponsiveTechnicianCorrection;
