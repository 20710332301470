import { Box, Typography } from "@mui/material";
import BarcodesModal from "components/AllModalContent/BarcodesModal/BarcodesModal";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseViewModalDataItem from "core-ui/BaseViewModalDataItem/BaseViewModalDataItem";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useReceiveSamplesByLabMutation } from "modules/Laboratory/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";
import { useState } from "react";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";

const SamplesDelivery = (props) => {
  const samples = props.samplesData?.samples.filter(
    (item) => item.status === "DELIVERED_TO_LAB"
  );
  const [showBardcodes, setShowBardcodes] = useState(false);

  const samplesDeliveredToLab = samples?.map((item) => {
    return item.formattedCode;
  });

  const DELIVERED_TO_LAB = !!props.samplesData?.deliveredToLabAt;

  const [receiveSamplesByLab, receiveSamplesByLabResponse] =
    useReceiveSamplesByLabMutation();

  const receiveSamplesHandler = () => {
    receiveSamplesByLab({
      inspectionId: props.inspection?.id,
      formattedCodes: samples.map((sample) => sample.formattedCode),
    });
  };
  const onLeaveCardHandler = () => setShowBardcodes(false);

  return (
    <>
      <BaseModal
        show={showBardcodes}
        py={"4rem"}
        px={"4rem"}
        onHide={onLeaveCardHandler}
        closeIcon
        mainContainerSx={{
          width: {
            lg: "80rem",
          },
        }}
      >
        <BarcodesModal
          onHide={onLeaveCardHandler}
          samples={props.samplesData?.samples}
          variant="RECEIVED_BY_LAB"
        />
      </BaseModal>

      <Typography
        mb={2}
        sx={{
          fontWeight: "500",
          fontSize: "1.6rem",
          color: "grayDark.color",
        }}
      >
        استلام العينة
      </Typography>

      {!props.receivedByLabAt && DELIVERED_TO_LAB && (
        <Typography mb={3} sx={{ fontSize: "1.6rem", color: "grayDark.color" }}>
          سيقوم مركز التوزيع بتسليم العينات التالية
          <Typography
            component={"span"}
            style={{
              fontSize: "inherit",
              fontWeight: "bold",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            {samplesDeliveredToLab.join(", ")}
          </Typography>
          قريبًا.
        </Typography>
      )}

      {!props.receivedByLabAt ? (
        <BaseButton
          sx={{ mb: 4 }}
          onClick={receiveSamplesHandler}
          disabled={receiveSamplesByLabResponse.isLoading || !DELIVERED_TO_LAB}
          variant={
            receiveSamplesByLabResponse.isLoading || !DELIVERED_TO_LAB
              ? "disabled"
              : ""
          }
        >
          تأكيد استلام العينة
        </BaseButton>
      ) : (
        <>
          <Typography
            mb={3}
            sx={{ fontSize: "1.6rem", color: "grayDark.color" }}
          >
            تم استلام العينة
          </Typography>
          <Box className={"request-data-details"} mt={4} mb={4}>
            <WrapperContentGrid gap={4}>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  تاريخ الاستلام
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {formatDateYYYYMMDD(props.receivedByLabAt)}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  وقت الاستلام
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {displayFormattedTimeHHMM(props.receivedByLabAt)}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  رموز العينات (barcode)
                </Typography>

                <BaseViewModalDataItem
                  value={"barcodes"}
                  onClick={() => setShowBardcodes(true)}
                />
              </Box>
            </WrapperContentGrid>
          </Box>
        </>
      )}
    </>
  );
};

SamplesDelivery.propTypes = {
  samplesData: PropTypes.object,
  inspection: PropTypes.object,
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  receivedByLabAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

export default SamplesDelivery;
