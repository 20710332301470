import { useEffect, useState } from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { AdminUserManagementDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import AdminEditUserForm from "./AdminEditUserForm";
import { _void } from "utils/Objects/Objects";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ConfirmationModal from "components/AllModalContent/ConfirmationModal/ConfirmationModal";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { UsersRolesEnum } from "constants/UsersTypes";
import { shouldRenderUserManagementControls } from "modules/Admin/pages/UserManagement/AdminUserDetails/helpers";
import { useSelector } from "react-redux";

const AdminUserDetails = () => {
  const params = useParams();
  const { userType, userRole } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { data: user, isFetching: isFetchingUser } = useGetUserQuery(
    params?.id,
    {
      skip: !params?.id,
    }
  );

  const [updateUser, updateUserResponse] = useUpdateUserMutation();

  const isSavingData = updateUserResponse.isLoading;

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({ mode: "onChange" });

  const formData = watch();

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const onSubmitHandler = () => {
    const idealData = {
      id: user?.id,
      name: formData.name,
      mobile: formData.mobileNumber,
      role: formData.role?.role,
      type: formData.role?.type,
      ...(formData?.role?.role === UsersRolesEnum.TECHNICIAN && {
        entityId: formData.entityName?.id,
      }),
    };

    updateUser(idealData)
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.USER_UPDATED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        setShowConfirmModal(false);
        navigate(`/users-management/${user.id}`, {
          state: { fromPageId: "editUser" },
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message:
            err.data?.errorCode === ErrorCodes.TECHNICIAN_HAS_ACTIVE_ASSIGNMENTS
              ? ErrorCodes.TECHNICIAN_CANT_UPDATE
              : ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
        setShowConfirmModal(false);
      });
  };

  useEffect(() => {
    if (!shouldRenderUserManagementControls(userType, userRole, user?.role)) {
      navigate(`/users-management/${user.id}`);
    }
  }, [userType, userRole, user, shouldRenderUserManagementControls]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين - ${user?.name}`}</title>
      </Helmet>

      <DashboardIntro
        title={user?.name}
        isLoading={isFetchingUser}
        description={"يمكنك تعديل معلومات المستخدم"}
        action={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={10}
          />
        }
        breadcrumbData={
          !user ? _void : AdminUserManagementDetailsBreadcrumb(user)
        }
      />

      <BaseModal
        show={showConfirmModal}
        title="تأكيد تحديث بيانات المستخدم"
        onHide={onLeavePageHandler}
        closeIcon
        px={"4rem"}
        containerSx={{
          minHeight: "25rem",
        }}
      >
        <ConfirmationModal
          onConfirm={onSubmitHandler}
          onHide={onLeavePageHandler}
          confirmMessage="هل أنت متأكد من تحديث بيانات المستخدم؟"
        />
      </BaseModal>

      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        <AdminEditUserForm
          user={user}
          navigate={navigate}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields}
          isSavingData={isSavingData}
          setShowConfirmModal={setShowConfirmModal}
          formData={formData}
          watch={watch}
          reset={reset}
        />
      </BaseCard>
    </>
  );
};

export default AdminUserDetails;
