import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTheme } from "@emotion/react";
import { rejectionReasons } from "services/StaticLookup/RejectionReasons";
import FieldTechnicianConfirmation from "./FieldTechnicianConfirmation";
import PropTypes from "prop-types";
import ResponsiveTechnicianCorrection from "./ResponsiveTechnicianCorrection";
import TechnicianCorrection from "./TechnicianCorrection";

const FieldExaminationEditRequestConfirmation = ({
  props,
  scoresList,
  element,
  editedStations,
  displayTechnicianConfirmation,
}) => {
  const { breakpoints } = useTheme();
  const lg = useMediaQuery(breakpoints.down("lg"));
  const { tableHeader } = props;

  const gridColumnSpanForConfirmation = `1 / span ${tableHeader.length - 1}`;

  const editedStation = scoresList?.find(
    (scoreItem) => scoreItem?.station === element.id
  );
  const matching = editedStations?.find((station) => station.id === element.id);

  const reason = rejectionReasons?.find(
    (reason) => reason.id === matching?.editedStation?.updatedSkipReason
  );

  const renderTechnicianConfirmations = () => (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ fontSize: "1.6rem", fontWeight: 500 }}>
        النتيجة البديلة:
      </Typography>
      {matching?.editedStation?.updatedSkipReason && reason && (
        <Typography sx={{ fontSize: "1.6rem", fontWeight: 500, ml: 1 }}>
          {reason.name}
        </Typography>
      )}
    </Box>
  );

  const renderScores = (scores) =>
    scores?.map((score, idx) => {
      return (
        <React.Fragment key={crypto.randomUUID()}>
          <Typography component="span" fontSize="inherit">
            {`${score?.score}`}
          </Typography>
          {idx < scores.length - 1 && ", "}
        </React.Fragment>
      );
    });

  return (
    <>
      {displayTechnicianConfirmation && (
        <FieldTechnicianConfirmation
          props={props}
          scoresList={scoresList}
          element={element}
          editedStations={editedStations}
        />
      )}
      {lg ? (
        <ResponsiveTechnicianCorrection
          editedStation={editedStation}
          renderScores={renderScores}
          renderTechnicianConfirmations={renderTechnicianConfirmations}
        />
      ) : (
        <TechnicianCorrection
          tableHeader={tableHeader}
          gridColumnSpanForConfirmation={gridColumnSpanForConfirmation}
          editedStation={editedStation}
          renderScores={renderScores}
          renderTechnicianConfirmations={renderTechnicianConfirmations}
        />
      )}
    </>
  );
};

FieldExaminationEditRequestConfirmation.propTypes = {
  props: PropTypes.object,
  element: PropTypes.object,
  tableHeader: PropTypes.array,
  scoresList: PropTypes.array,
  editedStations: PropTypes.array,
  displayTechnicianConfirmation: PropTypes.bool,
};

export default FieldExaminationEditRequestConfirmation;
