import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { drillingLicences } from "services/StaticLookup/TableHeader/TableHeader";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useLazyGetContractorLicensesQuery } from "modules/Contractor/apis/licenses-apis/licenses-api";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { publicAssetsPath } from "utils/Paths/Paths";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY } from "constants/Table";
import { drillingLicenses } from "services/StaticLookup/Breadcrumb";
import { useSelector } from "react-redux";
import { sysopsContractorRoleChecker } from "utils/Auth/Auth";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import Search from "components/Search/Search";
import { licenseYearsData } from "services/StaticLookup/LicensesYears";
import { isContentReady } from "utils/Array/Arrays";
import AddCheckupRequestButton from "./AddCheckupRequestButton";

const DrillingLicensesIndex = () => {
  const { t } = useTranslation(["dashboard"]);

  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseYear, setLicenseYear] = useState("");

  const { userType, userRole } = useSelector((store) => store.auth);

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getContractorLicenses, getContractorLicensesResponse] =
    useLazyGetContractorLicensesQuery({ page, pageSize });

  const getLicenseNumber = (licenseNumber) => {
    setLicenseNumber(licenseNumber);
  };

  const getLicenseYear = (licenseYear) => {
    setLicenseYear(licenseYear.name || "");
  };

  useEffect(() => {
    let timer = setTimeout(
      getContractorLicenses.bind(null, {
        page,
        size: pageSize,
        sort: "id,desc",
        ...(licenseNumber && {
          licenseNumber: encodeURIComponent(licenseNumber),
        }),
        licenseYear,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, licenseNumber, licenseYear]);

  const isNoSearchData =
    (licenseNumber || licenseYear) &&
    !isContentReady(getContractorLicensesResponse.data?.content);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"رخص الحفر"}</title>
        <link rel="canonical" href="/drilling-licenses" />
      </Helmet>
      <DashboardIntro
        title={`${t("drilling licences", { ns: "dashboard" })}`}
        description={t("drilling licenses page description", {
          ns: "dashboard",
        })}
        action={
          sysopsContractorRoleChecker(userType, userRole) ? (
            <AddCheckupRequestButton />
          ) : null
        }
        breadcrumbData={drillingLicenses({})}
      />
      <Search
        getSearchValue={getLicenseNumber}
        getDropdownValue={getLicenseYear}
        input={{ type: "text", placeholder: "يمكنك البحث برقم الرخصة" }}
        dropdown={{
          label: "سنة الإصدار",
          placeholder: "حدد سنة الإصدار",
          data: licenseYearsData(),
        }}
      />

      <BaseCard mt={6} py={"10px"} px={"10px"}>
        <BaseTableIndex
          tableHeader={drillingLicences}
          content={getContractorLicensesResponse?.data?.content || []}
          isLoading={getContractorLicensesResponse.isFetching}
          emptyData={{
            title: isNoSearchData
              ? t("No License Search")
              : t("No new license"),
            desc: isNoSearchData
              ? t("No Search Desc")
              : t("No new license desc"),
            img: publicAssetsPath("images/documents.svg"),
          }}
          withDetails={{
            paramsKey: "id",
            to: (id) => `/drilling-licenses/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "رخصة",
            onChangePaginationDataHandler,
            totalCount: getContractorLicensesResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default DrillingLicensesIndex;
