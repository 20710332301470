import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { Controller } from "react-hook-form";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useTranslation } from "react-i18next";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { UsersRolesEnum } from "constants/UsersTypes";
import {
  useGetUserRolesQuery,
  useGetUsersOfficeQuery,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import { customValidateMobileNumber } from "utils/Forms/Forms";

function AdminEditUserForm(props) {
  const { t } = useTranslation(["dashboard"]);

  const {
    register,
    handleSubmit,
    formData,
    control,
    errors,
    dirtyFields,
    isSavingData,
    setShowConfirmModal,
    watch,
    reset,
  } = props;

  const { data: usersOffice } = useGetUsersOfficeQuery();
  const { data: userRoles, isLoading: isUserRolesLoading } =
    useGetUserRolesQuery({ shouldEdit: true });

  const handleCancelClick = () => {
    props?.navigate(`/users-management/${props?.user.id}`);
  };

  // prettier-ignore
  const getValueOf = (key) => dirtyFields[key] ? formData[key] : props?.user?.[key];
  const selectedRole = watch("role");

  const onSubmitHandler = (d, event) => {
    event.preventDefault();
    setShowConfirmModal(true);
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  let offices = [];

  if (usersOffice) {
    offices = usersOffice.map((office) => ({
      id: office?.id,
      name: office?.name,
    }));
  }

  useEffect(() => {
    if (
      props?.user?.role === UsersRolesEnum.TECHNICIAN &&
      usersOffice?.length > 0
    ) {
      reset({
        role: userRoles?.find(
          (item) =>
            item.role === getValueOf("role") &&
            item.type === getValueOf("entitytype")
        ),
        entityName: offices?.find(
          (item) => item.name === getValueOf("entityName")
        ),
      });
    } else if (userRoles?.length > 0) {
      reset({
        role: userRoles?.find(
          (item) =>
            item.role === getValueOf("role") &&
            item.type === getValueOf("entitytype")
        ),
      });
    }
  }, [usersOffice, userRoles]);

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
    >
      <Box mb={4} className={"request-data-details"}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            المعلومات العامة
          </Typography>
          <WrapperContentGrid sx={{ marginTop: 4 }} columnsWidth={27} gap={4}>
            <Box className={"request-data-details__item"}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: `${t("Field")} ${t("required")}`,
                  },
                  minLength: {
                    value: 6,
                    message: "يجب أن يكون الاسم ٦ احرف على الأقل",
                  },
                  pattern: {
                    value: /.*\S.*/,
                    message: "الحقل مطلوب",
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <BaseInput
                      htmlFor="name"
                      label={"الاسم"}
                      placeholder={t("name")}
                      value={getValueOf("name")}
                      onChange={onChange}
                      maxLength={30}
                      labelStyles={{
                        fontWeight: "bold",
                        color: "grayDark.color",
                      }}
                      name="name"
                      register={{
                        ...register("name", {
                          required: {
                            value: true,
                            message: `${t("Field")} ${t("required")}`,
                          },
                          minLength: {
                            value: 6,
                            message: "يجب أن يكون الاسم ٦ احرف على الأقل",
                          },
                          pattern: {
                            value: /.*\S.*/,
                            message: "الحقل مطلوب",
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  );
                }}
              />
            </Box>
            <Controller
              control={control}
              name="role"
              rules={{
                required: {
                  value: true,
                  message: `${t("Field")} ${t("required")}`,
                },
              }}
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <BaseDropdown
                    htmlFor="role"
                    onChange={onChange}
                    control={control}
                    name="role"
                    required
                    errors={errors}
                    isLoading={isUserRolesLoading}
                    data={userRoles}
                    label={"الوظيفة"}
                    labelStyles={{
                      fontWeight: "bold",
                      color: "grayDark.color",
                    }}
                    placeholder="حدد الوظيفة"
                    selectMenuStyles={{
                      height: "12rem !important",
                      border: "1px solid gray",
                      color: "black",
                    }}
                  />
                );
              }}
            />
            {selectedRole && selectedRole.name === "فني الرقابة" && (
              <Box className={"request-data-details__item"}>
                <Controller
                  control={control}
                  name="entityName"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("Field")} ${t("required")}`,
                    },
                  }}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <BaseDropdown
                        htmlFor="entityName"
                        control={control}
                        onChange={onChange}
                        name="entityName"
                        required
                        errors={errors}
                        label={t("entityId")}
                        labelStyles={{
                          color: "grayDark.color",
                          fontSize: "1.4rem",
                          fontWeight: 500,
                        }}
                        placeholder={t("select office")}
                        data={offices}
                      />
                    );
                  }}
                />
              </Box>
            )}
          </WrapperContentGrid>
        </Box>
      </Box>
      <hr />
      <Box mt={4} className={"request-data-details"}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            معلومات التواصل
          </Typography>
          <WrapperContentGrid sx={{ marginTop: 4 }} columnsWidth={27} gap={4}>
            <Box className={"request-data-details__item"}>
              <BaseInput
                disabled={true}
                value={props?.user?.email}
                htmlFor="email"
                name="email"
                label={"البريد الإلكتروني"}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <BaseInput
                htmlFor="mobileNumber"
                label={"رقم الجوال"}
                placeholder="05 xxx xxxx"
                value={getValueOf("mobileNumber")}
                labelStyles={{
                  fontWeight: "bold",
                  color: "grayDark.color",
                }}
                name="mobileNumber"
                register={{
                  ...register("mobileNumber", {
                    required: {
                      value: true,
                      message: "حقل رقم الجوال مطلوب",
                    },
                    validate: customValidateMobileNumber,
                  }),
                }}
                errors={errors}
              />
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          gap: 2,
          marginTop: "2rem",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
          }}
          disabled={isSavingData}
          variant={"secondary"}
          onClick={handleCancelClick}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          type={"submit"}
          isLoading={isSavingData}
        >
          {t("حفظ")}
        </BaseButton>
      </Box>
    </form>
  );
}

AdminEditUserForm.propTypes = {
  register: PropTypes.func,
  handleSubmit: PropTypes.func,
  watch: PropTypes.func,
  reset: PropTypes.func,
  formData: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
  dirtyFields: PropTypes.object,
  isSavingData: PropTypes.bool,
  setShowConfirmModal: PropTypes.func,
};

export default AdminEditUserForm;
