import React from "react";
import BaseInput from "core-ui/BaseInput/BaseInput";
import PropTypes from "prop-types";

const StationPointInput = (props) => {
  const {
    errors,
    register,
    id,
    t,
    placeholder,
    name,
    stationsHasErrors,
    setStationsHasErrors,
    inputStyles,
  } = props;
  return (
    <BaseInput
      defaultErrorSx
      inputContainerStyles={{ marginBottom: 0 }}
      step={"any"}
      placeholder={placeholder}
      inputStyles={{ width: "10rem", marginTop: 0, ...inputStyles }}
      htmlFor={`station-${id}-${name}`}
      name={`station-${id}-${name}`}
      register={{
        ...register(`station-${id}-${name}`, {
          onChange: () => {
            if (
              stationsHasErrors?.length > 0 &&
              stationsHasErrors.includes(id)
            ) {
              setStationsHasErrors((prevStations) => {
                const updatedStations = [...prevStations];

                updatedStations.splice(prevStations.indexOf(id), 1);
                return updatedStations;
              });
            }
          },
          required: {
            value: true,
            message: `${t("field")} ${t("required")}`,
          },
          pattern: {
            value: /^[0-9]{1,2}(\+)\d{3}(\.\d+)*$/,
            message: `يرجى إدخال نقطة البداية والنهاية بالصيغة التالية: المسافة بالمتر + المسافة بالكيلومتر، مثال: 010+0`,
          },
        }),
      }}
      errors={errors}
    />
  );
};

StationPointInput.propTypes = {
  errors: PropTypes.object,
  inputStyles: PropTypes.object,
  register: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  stationsHasErrors: PropTypes.array,
  setStationsHasErrors: PropTypes.func,
};

export default StationPointInput;
