export default {
  SUCCESS_RESET_PASSWORD: "SUCCESS_RESET_PASSWORD",
  SUCCESS_REGISTER: "SUCCESS_REGISTER",
  SUCCESS_FORGOT_PASSWORD: "SUCCESS_FORGOT_PASSWORD",
  SUCCESS_PAYMENT_LAYER: "SUCCESS_PAYMENT_LAYER",
  SUCCESS_SUBMIT_RESULTS: "SUCCESS_SUBMIT_RESULTS",
  SUCCESS_COLLECT_TECH_DATA: "SUCCESS_COLLECT_TECH_DATA",
  SUCCESS_EXAMINATION_RECORD_FORM: "SUCCESS_EXAMINATION_RECORD_FORM",
  APPOINTMENT_BOOKING_SUCCESS: "APPOINTMENT_BOOKING_SUCCESS",
  SUCCESS_UPLOAD_ATTACHMENT: "SUCCESS_UPLOAD_ATTACHMENT",
  ADDRESS_ADDED_SUCCESSFULLY: "ADDRESS_ADDED_SUCCESSFULLY",
  SUCCESS_PAYMENT_TRANSACTION: "SUCCESS_PAYMENT_TRANSACTION",
  SUCCESS_CON_SAVE_ATTACHMENT: "SUCCESS_CON_SAVE_ATTACHMENT",
  SUCCESS_LAB_RAISE_SCORES_AND_PROCTOR: "SUCCESS_LAB_RAISE_SCORES_AND_PROCTOR",
  ASSIGNED_LAB_SUCCESSFULLY: "ASSIGNED_LAB_SUCCESSFULLY",
  TECHNICIAN_REASSIGNED_SUCCESSFULLY: "TECHNICIAN_REASSIGNED_SUCCESSFULLY",
  TECHNICIAN_ASSIGNED_SUCCESSFULLY: "TECHNICIAN_ASSIGNED_SUCCESSFULLY",
  USER_CREATED_SUCCESSFULLY: "USER_CREATED_SUCCESSFULLY",
  USER_ACTIVATED_SUCCESSFULLY: "USER_ACTIVATED_SUCCESSFULLY",
  USER_DECTIVATED_SUCCESSFULLY: "USER_DECTIVATED_SUCCESSFULLY",
  USER_REINVITED_SUCCESSFULLY: "USER_REINVITED_SUCCESSFULLY",
  LAB_DEASSIGNED_SUCCESSFULLY: "LAB_DEASSIGNED_SUCCESSFULLY",
  PROFILE_UPDATED_SUCCESSFULLY: "PROFILE_UPDATED_SUCCESSFULLY",
  SUCCESS_MODIFY_THE_REQUEST: "SUCCESS_MODIFY_THE_REQUEST",
  SUCCESS_SUBMIT_LAB_TEST_REQUEST_FORM: "SUCCESS_SUBMIT_LAB_TEST_REQUEST_FORM",
  SUCCESS_APPROVE_EDIT_REQUEST: "SUCCESS_APPROVE_EDIT_REQUEST",
  SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM:
    "SUCCESS_SUBMIT_EDIT_TEST_REQUEST_FORM",
  REJECTED_EDIT_TEST_REQUEST: "REJECTED_EDIT_TEST_REQUEST",
  SUCCESS_SUBMIT_TEST_REQUEST_FORM: "SUCCESS_SUBMIT_TEST_REQUEST_FORM",
  SUCCESS_APPOINTMENT_RESCHEDULE: "SUCCESS_APPOINTMENT_RESCHEDULE",
  SUCCESS_ONLY_DRAFT_REQUEST_FORM: "SUCCESS_ONLY_DRAFT_REQUEST_FORM",
  APPROVED_EDIT_REQUEST: "APPROVED_EDIT_REQUEST",
  SUCCESS_SUBMIT_ISSUE: "SUCCESS_SUBMIT_ISSUE",
  CONSULTANT_REQUEST_REJECTED: "CONSULTANT_REQUEST_REJECTED",
  CONSULTANT_REQUEST_APPROVED: "CONSULTANT_REQUEST_APPROVED",
  SUCCESSFUL_SENDING_REQUEST: "SUCCESSFUL_SENDING_REQUEST",
  SUCCESS_UPDATE_REQUEST: "SUCCESS_UPDATE_REQUEST",
  SUCCESSFUL_REQUEST: "SUCCESSFUL_REQUEST",
  SUCCESS_APPROVE_ISSUANCE_INSPECTION_CERTIFICATE:
    "SUCCESS_APPROVE_ISSUANCE_INSPECTION_CERTIFICATE",
  SUCCESS_REJECT_ISSUANCE_INSPECTION_CERTIFICATE:
    "SUCCESS_REJECT_ISSUANCE_INSPECTION_CERTIFICATE",
  USER_UPDATED_SUCCESSFULLY: "USER_UPDATED_SUCCESSFULLY",
  CONFIGURATIONS_UPDATED_SUCCESSFULLY: "CONFIGURATIONS_UPDATED_SUCCESSFULLY",
};
