import { Box, Typography } from "@mui/material";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import { TABLE_ID } from "constants/Table";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import React, { useState } from "react";
import { formatDateYYYYMMDD, renderDateTimeInAMPM } from "utils/Date/Date";
import { publicAssetsPath } from "utils/Paths/Paths";
import { useTranslation } from "react-i18next";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import { defaultEmptyValue } from "constants/Empty";
import {
  consultantExaminationDetailsTests,
  consultantExaminationDetailsTestsVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import LicenseLink from "components/LicenseLink/LicenseLink";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

function ExaminationDataField({
  request,
  dataId,
  stations,
  labTestAppointment,
  isFetching,
  ...props
}) {
  const [showStation, setShowStation] = useState(false);

  const inspectionLayer = request?.inspectionLayersList?.[0];

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const createdAtDateObject = new Date(request?.createdAt);

  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const requestCreatedAtTime = renderDateTimeInAMPM(
    `${createdAtDateObject.getHours()}:${createdAtDateObject.getMinutes()}:00`
  );
  const onLeavePageHandler = () => setShowStation(false);

  const { t } = useTranslation(["dashboard"]);

  const { hasVirtual, tableContent } = useGetVirtualStations(stations);

  const tableHeader = hasVirtual
    ? consultantExaminationDetailsTestsVS
    : consultantExaminationDetailsTests({
        show: request?.status !== "SKIPPED",
        isAsphalt,
      });

  const isTechnicianConfirmed = tableContent?.some(
    (station) =>
      station.maxProctorConfirmation === true ||
      station.maxProctorConfirmation === false
  );

  const isRequestDone =
    request?.status === "SUCCEED" || request?.status === "FAILED";

  const tableProps = {
    title: "قائمة الاختبارات لنقاط الـ Station التالية",
    tableHeader: tableHeader,
    content: tableContent || [],
    isLoading: isFetching,
    emptyData: {
      title: t("No new license"),
      desc: t("No new license desc"),
      img: publicAssetsPath("images/documents.svg"),
    },
    injectProps: {
      tableId: TABLE_ID.SUB_TEST_STATUS,
      extraStatus: request?.status,
      mySpanColumns,
      isRequestDone,
      setMySpanColumns,
      myHiddenColumns,
      setMyHiddenColumns,
      shouldDisplayTheResult: isTechnicianConfirmed,
      submittedFromLabAt: request?.submittedAt,
    },
  };
  return (
    <Box>
      <BaseModal
        show={showStation}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>

      <Box className={"request-data-details"} mt={4}>
        <Box className={"request-data-details__wrapper"} mb={5}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.id}
              </Typography>
            </Box>
            <LicenseLink license={request?.licenses?.[0]} />
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الفحص
              </Typography>
              <BaseStatus
                status={request?.status}
                dataId={dataId}
                testType={request?.testType}
                appointment={request?.appointment}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                تاريخ الإنشاء
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatDateYYYYMMDD(request?.createdAt)}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                وقت الإنشاء
              </Typography>
              <Typography className={"request-data-details__value"}>
                {requestCreatedAtTime}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                {"اختبار مواد الأعمال المدنية"}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{ direction: "rtl" }}
              >
                {request?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                مسار الفحص
              </Typography>
              <BaseTypeTag type={request?.testType} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {request?.contractorEstimatedTime || "لايوجد"} أيام
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نقاط الـ Stations
              </Typography>
              <Typography
                className={"request-data-details__value"}
                onClick={() => setShowStation(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  cursor: "grab",
                }}
              >
                نقاط الـ Stations{" "}
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                  alt="popout-stations"
                />
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موعد الفحص
              </Typography>
              <Typography
                component={"div"}
                className={"request-data-details__value"}
              >
                <DisplayRequestAppointmentDate
                  appointmentDate={labTestAppointment?.scheduledDate}
                  scheduledAppointment={labTestAppointment?.scheduledTime}
                />
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box mt={5}>
          <SmartDoubleCardTable
            tableProps={tableProps}
            desktop={{
              injectProps: {
                ...tableProps.injectProps,
                longColumnGridDirection: "column",
              },
            }}
            mobile={{
              injectProps: {
                ...tableProps.injectProps,
                longColumnGridDirection: "row",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

ExaminationDataField.propTypes = {
  request: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stations: PropTypes.array,
  labTestAppointment: PropTypes.object,
  isFetching: PropTypes.bool,
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ExaminationDataField;
