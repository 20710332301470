import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { useTranslation } from "react-i18next";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { adminReportRequests } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import { Helmet } from "react-helmet";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import BaseSearch from "core-ui/BaseSearch/BaseSearch";
import { ReportRequestsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useLazyGetReportRequestsQuery } from "modules/Admin/apis/inspection-apis/lab-apis";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";

const ReportRequests = () => {
  const { t } = useTranslation(["dashboard"]);
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [searchTerm, setSearchTerm] = useState("");
  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getReportRequests, getReportRequestsResponse] =
    useLazyGetReportRequestsQuery();

  useEffect(() => {
    let timer = setTimeout(
      getReportRequests.bind(null, {
        page,
        size: pageSize,
        sort: "id,desc",
        search: searchTerm,
      }),
      500
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, searchTerm, getReportRequests]);

  const isNoSearchData =
    searchTerm && !isContentReady(getReportRequestsResponse.data?.content);
  const emptyTitle = isNoSearchData ? t("No Results") : t("no reports");
  const emptyDesc = isNoSearchData
    ? t("No Search Desc")
    : t("No Report Requests Desc");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Report Requests")}</title>
        <link rel="canonical" href="/home" />
      </Helmet>

      <DashboardIntro
        title={t("Report Requests")}
        breadcrumbData={ReportRequestsBreadcrumb(t)}
        description={t(
          "تتيح لك هذة الصفحة عرض ملخص الطلبات المسندة الخاصة بالمختبرات خلال فترة شهر"
        )}
        action={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={10}
          />
        }
      />

      <BaseSearch
        placeholder="يمكنك البحث باسم المختبر او برقم التقرير"
        name="ReportRequestsSearch"
        getSearchTerm={setSearchTerm}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={adminReportRequests}
          content={getReportRequestsResponse.data?.content || []}
          isLoading={getReportRequestsResponse.isFetching}
          injectProps={{
            tableId: TABLE_ID.ADMIN_REPORT_REQUESTS,
            showAlertHandler: showAlertHandler,
          }}
          totalCount={20}
          emptyData={{
            title: emptyTitle,
            desc: emptyDesc,
            img: publicAssetsPath("images/documents2.svg"),
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "تقرير",
            onChangePaginationDataHandler,
            totalCount: getReportRequestsResponse?.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default ReportRequests;
