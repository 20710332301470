import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { displayInvoiceDetails } from "services/StaticLookup/TableHeader/TableHeader";
import {
  useGetInvoiceDetailsQuery,
  useLazyGetInvoiceQuery,
} from "modules/Contractor/apis/payment-apis/payment-apis";
import { useLocation, useParams } from "react-router-dom";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import DashboardIntroAction from "components/Dashboard/DashboardIntro/DashboardIntroAction/DashboardIntroAction";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { invoicesDetailsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import useAlert from "hooks/useAlert";
import { useTranslation } from "react-i18next";
import { formattedNumber } from "utils/Numbers/Numbers";
import classes from "./InvoicesStatus.module.scss";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { VAT_PERCENT } from "constants/Vat";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import ExportInvoiceButton from "./ExportInvoiceButton";

function InvoicesDetails() {
  const { t } = useTranslation(["wallet"]);

  const params = useParams();

  const { data: invoiceDetails } = useGetInvoiceDetailsQuery(params?.id);

  const [reEvaluate, setReEvaluate] = useState(false);

  const location = useLocation();

  const { state } = location;

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const [getIssueInvoice, issueInvoice] = useLazyGetInvoiceQuery();

  useEffect(() => {
    getIssueInvoice(params?.id);
  }, [reEvaluate]);

  // To avoid any errors that might come from the backend, we've set a timer to ensure synchronization with the billing system's response.
  // This is because the billing system might take a few seconds after submitting the payment.

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setReEvaluate(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [reEvaluate]);

  useEffect(() => {
    const navigatedFromWalletPageWithSuccessfulTransaction =
      state?.status === "SUCCEED";

    if (navigatedFromWalletPageWithSuccessfulTransaction && invoiceDetails) {
      showAlertHandler({
        show: true,
        type: AlertTypes.SUCCESS,
        message: SuccessCodes.SUCCESS_PAYMENT_TRANSACTION,
        interpolation: { points: invoiceDetails?.cost },
      });
    }
  }, [state, invoiceDetails]);

  const formattedInvoiceDetails = [
    {
      id: "101-100-101",
      points: invoiceDetails?.points,
      cost: invoiceDetails?.cost,
    },
  ];

  if (!invoiceDetails) return <BaseLoader />;

  const textSX = {
    fontSize: "1.6rem",
  };

  const flexClasses = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const alignedText = {
    width: "50%",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  };

  return (
    <>
      <DashboardIntro
        title={t(`بيانات العملية رقم ${params?.id}`, { ns: "wallet" })}
        variant="secondary"
        action={
          <DashboardIntroAction>
            <Box>
              <BaseAlert
                show={alert.show}
                type={alert.type}
                message={alert.message}
                interpolation={alert.interpolation}
                destroy={hideAlertHandler}
                autoClose={10}
                closeIcon={false}
              />
            </Box>
            <ExportInvoiceButton
              issueInvoice={issueInvoice}
              reEvaluate={reEvaluate}
              showAlertHandler={showAlertHandler}
            />
          </DashboardIntroAction>
        }
        breadcrumbData={invoicesDetailsBreadcrumb({ id: params?.id })}
      />

      <BaseCard
        px={"3rem"}
        py={"3rem"}
        mt={5}
        sx={{
          minHeight: "70vh",
          paddingBottom: { sm: "10rem", xs: "17rem" },
        }}
      >
        <Box mt={6} ml={4} mr={4}>
          <BaseDataWrapper mt={1} title={"بيانات المورد"}>
            <BaseDataItem
              label={"الاسم"}
              value={invoiceDetails?.supplierName}
            />
            <BaseDataItem
              label={"الرقم الضريبي"}
              value={invoiceDetails?.supplierTaxNumber}
            />
          </BaseDataWrapper>

          <BaseDataWrapper mt={1} title={"تفاصيل الفاتورة"}>
            <BaseDataItem
              label={"اسم العميل"}
              value={invoiceDetails?.contractorName}
              valueSx={{ color: "grayDark.color5", fontSize: "1.6rem" }}
            />
            <BaseDataItem
              label={"رقم الفاتورة"}
              value={invoiceDetails?.id}
              valueSx={{ color: "grayDark.color5", fontSize: "1.6rem" }}
            />
            <BaseDataItem
              label={"نوع العملية"}
              value={" شحن المحفظة"}
              valueClassName={classes["invoice-status__text"]}
            />
            <BaseDataItem
              label={"التاريخ"}
              valueSx={{ color: "grayDark.color5", fontSize: "1.6rem" }}
              value={`${formatDateYYYYMMDD(
                invoiceDetails?.paymentTime,
                "dddd, YYYY/MM/DD"
              )} - ${displayFormattedTimeHHMM(invoiceDetails?.paymentTime)}`}
            />
          </BaseDataWrapper>
        </Box>
        <Box mt={6} ml={4} mr={4}>
          <BaseTableIndex
            tableHeader={displayInvoiceDetails}
            title={"ملخص الطلب"}
            content={formattedInvoiceDetails || []}
          />
          <Box sx={{ ...flexClasses, mb: 2, ml: 2 }}>
            <Box sx={{ ...flexClasses }}>
              <Typography sx={textSX}>{t("ضريبة القيمة المضافة")}</Typography>
              <Typography
                sx={{ ...textSX, ml: 1 }}
              >{`(${VAT_PERCENT}%) `}</Typography>
            </Box>

            <Box sx={{ ...flexClasses, ...alignedText }}>
              <Typography sx={{ ...textSX, fontSize: "2.0rem", mr: 1 }}>
                {formattedNumber(invoiceDetails?.totalVat)}
              </Typography>
              <Typography sx={{ ...textSX }}>ريال </Typography>
            </Box>
          </Box>
          <Box sx={{ ...flexClasses, mb: 2 }}>
            <Typography
              sx={{ fontSize: "1.6rem", fontWeight: 500 }}
              color={"grayDark.color1"}
              ml={2}
            >
              {"التكلفة الكلية"}
            </Typography>
            <Box sx={{ ...flexClasses, ...alignedText }}>
              <Typography
                sx={{
                  ...textSX,
                  fontSize: "2.0rem",
                  ml: 1,
                  color: "primary.colorSoft10",
                  fontWeight: 500,
                }}
              >
                {formattedNumber(invoiceDetails?.totalCost)}
              </Typography>
              <Typography
                sx={{
                  ...textSX,
                  fontSize: "2.0rem",
                  ml: 1,
                  color: "primary.colorSoft10",
                  fontWeight: 500,
                }}
              >
                ريال{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </BaseCard>
    </>
  );
}

export default InvoicesDetails;
