import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import classes from "./DashboardDrillingLicensesData.module.scss";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import PropTypes from "prop-types";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import CombinedField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/CombinedField/CombinedField";

const DrillingLicensesData = (props) => {
  return !props.data ? (
    <BaseLoader />
  ) : (
    <Box className={classes["drilling-data"]} mt={6} ml={4}>
      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          {props.title || "الرخصة"}
        </Typography>
        <WrapperContentGrid gap={4}>
          <BaseDataItem
            valueComponentAs={"div"}
            labelComponentAs={"div"}
            label={
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  color: "grayDark.color",
                  fontWeight: 500,
                }}
              >
                {"رقم الرخصة"}
              </Typography>
            }
            value={
              <CombinedField
                sx={{ direction: "rtl", textAlign: "end" }}
                field1={props.data?.licenseYear}
                field2={props.data?.licenseNumber}
              />
            }
          />

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              مقدم الخدمة
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.serviceProviderName}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              شركة المقاولات
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorCompany}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              النوع
            </Typography>
            <BaseTypeTag type={props.data.typeRefId} />
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              تاريخ الانتهاء
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.expiryDate}
            </Typography>
          </Box>

          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              نوع الحفر{" "}
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.drillingType || "لايوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              نوع الخدمة{" "}
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.serviceName || "لايوجد"}
            </Typography>
          </Box>
          {props.data?.projectName ? (
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                اسم المشروع{" "}
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.projectName || "لا يوجد"}
              </Typography>
            </Box>
          ) : null}
          {props.data?.streetWidth ? (
            <Box className={classes["drilling-data__item"]}>
              <Typography className={classes["drilling-data__label"]}>
                عرض الشارع{" "}
              </Typography>
              <Typography className={classes["drilling-data__value"]}>
                {props.data.streetWidth || "لا يوجد"}
              </Typography>
            </Box>
          ) : null}
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              حالة الشهادة
            </Typography>
            <BaseStatus
              status={props.data.certificateIssued}
              dataId={props.dataId}
            />
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />

      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          معلومات شركة المقاولات
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              اسم شركة المقاولات
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorCompany || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              البريد الالكتروني
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.contractorEmail || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الجوال
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {formatPhoneNumber(props.data.contractorMobile) || "لا يوجد"}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          بيانات الحفر
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الطول
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.licenceLength || "لا يوجد"} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              العرض
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.boundaryWidth} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              العمق
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.boundaryDepth} م
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الحجم
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.volumNo}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>

      <hr />

      <Box className={classes["drilling-data__wrapper"]}>
        <Typography className={classes["drilling-data__title"]}>
          معلومات الاستشاري
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              الاسم
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.consultantName || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              رقم الجوال
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {formatPhoneNumber(props.data.consultantMobile) || "لا يوجد"}
            </Typography>
          </Box>
          <Box className={classes["drilling-data__item"]}>
            <Typography className={classes["drilling-data__label"]}>
              البريد الالكتروني
            </Typography>
            <Typography className={classes["drilling-data__value"]}>
              {props.data.consultantEmail || "لا يوجد"}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
      <hr />
    </Box>
  );
};

DrillingLicensesData.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  columns: PropTypes.number,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DrillingLicensesData;
