import React from "react";
import PreviewPDF from "core-ui/BasePDF/PreviewPDF/PreviewPDF";
import { formatDateYYYYMMDD } from "utils/Date/Date";
import TransactionContent from "components/PrintTransaction/TransactionContent/TransactionContent";
import { TransactionTypes } from "constants/TransactionTypes";
import { TestTypes } from "constants/TestTypes";
import PropTypes from "prop-types";
import OneUseLabContent from "./OneUseLabContent";
import OneUseContent from "./OneUseContent";
import OneUseRefundContent from "./OneUseRefundContent";

const PrintTransaction = (props, { chunk }) => {
  const { data } = props;

  const testTypeLabel = {
    FIELD: "حقلي",
    LAB: "معملي",
  };

  const processType =
    data?.status === TransactionTypes.REFUND ? "إسترداد نقاط" : "خصم نقاط";

  const headerProps = {
    title: "ملخص العملية",
    subtitles: [
      {
        title: "التفاصيل",
        data: {
          "اسم شركة المقاولات": `${data?.contractorName}`,
          "رقم العملية": `${data?.id}`,
          "نوع العملية": processType,
          التاريخ: `${formatDateYYYYMMDD(data?.transactionTime)}`,
          "رقم الطلب": `${data?.inspectionLayer}`,
          "نوع الفحص": `${testTypeLabel[data?.testtype]}`,
          "اختبار مواد الأعمال المدنية": `${data?.inspectionLayerName}`,
        },
      },
    ],
    tableTitle: "ملخص الطلب",
    TableTitle2: "الوصف",
    tableTitle1Margin: 30,
    tableTitle2Margin: 360,
    ...props,
  };

  const toBeChunkedData =
    data?.deductionDetailsDTO && data?.deductionDetailsDTO.length !== 0
      ? data?.deductionDetailsDTO
      : [{}];

  const isLab = data?.testtype === TestTypes.LAB_TEST;
  const isDeducted = data?.status === TransactionTypes.DEDUCTED;

  let withAdditionData =
    isLab && isDeducted ? data?.combinedSampleCollectionCost : 0;

  return (
    <PreviewPDF {...headerProps} toBeChunkedData={toBeChunkedData}>
      {(chunk, pageIndex, chunks) => {
        const isLastPage = pageIndex === chunks.length - 1;
        return (
          <>
            {!isDeducted && <OneUseRefundContent data={data}/>}
            {isDeducted && <TransactionContent transactionData={chunk} />}
            {isLastPage && isDeducted && isLab && (
              <OneUseLabContent data={data} />
            )}
            {isLastPage && isDeducted && (
              <OneUseContent withAddition={withAdditionData} data={data} />
            )}
          </>
        );
      }}
    </PreviewPDF>
  );
};

PrintTransaction.propTypes = {
  data: PropTypes.object,
};

export default PrintTransaction;
