import {
  adminNavData,
  centerNavData,
  consultantNavData,
  contractorNavData,
  laboratoryNavData,
  laboratoryTechnicianNavData,
  technicianNavData,
} from "services/StaticLookup/NavigationData";
import {
  laboratoryTechnicianRoleChecker,
  opadminRoleChecker,
  opsuppadminRoleChecker,
  qaqcadminRoleChecker,
  qaqcsuppadminRoleChecker,
  sysadminRoleChecker,
  sysopsCenterRoleChecker,
  sysopsConsultantRoleChecker,
  sysopsContractorRoleChecker,
  sysopsLaboratoryRoleChecker,
  technicianRoleChecker,
} from "utils/Auth/Auth";

export function getNavData(userRole, userType) {
  let navData = [];

  switch (userRole) {
    // CONTRACTOR
    case sysopsContractorRoleChecker(userType, userRole):
      navData = contractorNavData;
      break;
    // CONSULTANT
    case sysopsConsultantRoleChecker(userType, userRole):
      navData = consultantNavData;
      break;
    // LABORATORY
    case sysopsLaboratoryRoleChecker(userType, userRole):
      navData = laboratoryNavData;
      break;
    // LABORATORY TECHNICIAN
    case laboratoryTechnicianRoleChecker(userType, userRole):
      navData = laboratoryTechnicianNavData;
      break;
    // ADMIN
    case sysadminRoleChecker(userType, userRole):
    case opadminRoleChecker(userType, userRole):
    case opsuppadminRoleChecker(userType, userRole):
    case qaqcadminRoleChecker(userType, userRole):
    case qaqcsuppadminRoleChecker(userType, userRole):
      navData = adminNavData;
      break;
    // TECHNICIAN
    case technicianRoleChecker(userType, userRole):
      navData = technicianNavData;
      break;
    // CENTER
    case sysopsCenterRoleChecker(userType, userRole):
      navData = centerNavData;
      break;
  }
  return navData;
}
