import { Box } from "@mui/material";
import PropTypes from "prop-types";

const DashboardIntroAction = (props) => {
  return (
    <Box
      sx={{
        ...props.sx,
        width: props.fluid || props.sm ? "100%" : "min(45rem, 100%)",
        textAlign: "end",
      }}
    >
      {props.children}
    </Box>
  );
};

DashboardIntroAction.propTypes = {
  sx: PropTypes.object,
  fluid: PropTypes.bool,
  sm: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default DashboardIntroAction;
