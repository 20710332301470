import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import { defaultEmptyValue } from "constants/Empty";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { displayFormattedTimeHHMM, formatDateYYYYMMDD } from "utils/Date/Date";
import {
  useGetAppointmentDetailsForLabQuery,
  useGetSamplesForLabQuery,
} from "modules/Laboratory/apis/inspection-apis/inspection-api";
import { formattedNumber } from "utils/Numbers/Numbers";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import PropTypes from "prop-types";

const LabAssignedRequestDetails = (props) => {
  const { inspection } = props;

  const inspectionLayer = inspection.inspectionLayersList[0];

  const { data: samplesData } = useGetSamplesForLabQuery(inspection.id);

  const { data: appointmentData } = useGetAppointmentDetailsForLabQuery(
    inspection.id
  );

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  return (
    <Box className={"request-data-details"} mt={4}>
      <Box className={"request-data-details__wrapper"}>
        <Typography className={"request-data-details__title"}>
          تفاصيل الطلب
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم الطلب
            </Typography>
            <Typography className={"request-data-details__value"}>
              {inspection?.id || ""}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              تاريخ القبول
            </Typography>
            <Typography className={"request-data-details__value"}>
              {formatDateYYYYMMDD(inspection?.labAssignedAt) ||
                defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              وقت القبول
            </Typography>
            <Typography className={"request-data-details__value"}>
              {displayFormattedTimeHHMM(inspection?.labAssignedAt) ||
                defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              اسم الاختبار
            </Typography>
            <Typography className={"request-data-details__value"}>
              {inspectionLayer?.name}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              تكلفة الاختبار
            </Typography>
            <Typography className={"request-data-details__value"}>
              {inspection?.labPrice
                ? `${formattedNumber(inspection?.labPrice)} ريال`
                : defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              نوع الفحص
            </Typography>
            <BaseTypeTag type={inspection?.testType} />
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              حالة الطلب
            </Typography>
            <BaseStatus status={inspection?.status} dataId={props.dataId} />
          </Box>
          {isABC && (
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                درجة التربة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspectionLayer?.soilGrade || defaultEmptyValue}
              </Typography>
            </Box>
          )}
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              عدد العينات
            </Typography>
            <Typography className={"request-data-details__value"}>
              {samplesData?.numberOfSamples}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              حالة العينة
            </Typography>
            <BaseStatus
              status={samplesData?.samplestatus}
              extraStatus={samplesData?.samplestatus}
              dataId={props.dataId}
            />
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              موعد الفحص
            </Typography>
            <BaseStatus
              status={inspection?.testType}
              appointment={appointmentData?.scheduledTime}
              dataId={props.dataId}
            />
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              المدة المتوقعة
            </Typography>
            <Typography className={"request-data-details__value"}>
              {inspection?.labEstimatedTime} أيام
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              الفني
            </Typography>
            <Typography className={"request-data-details__value"}>
              {inspection?.labTechName}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
    </Box>
  );
};

LabAssignedRequestDetails.propTypes = {
  inspection: PropTypes.object,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabAssignedRequestDetails;
