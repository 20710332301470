import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import { extractCurrentLabScores } from "utils/Formatter/Formatter";
import { transformStationToFrom } from "services/StaticLookup/TableHeader/TableHeader";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { calculateEditedStations } from "utils/CalculateEditedStations/CalculateEditedStations";
import { setDoubleLayer } from "utils/SetDoubleLayer/SetDoubleLayer";
import HiddenColumn from "components/TableUtilitiesComponents/TableDynamicFieldComponents/HiddenColumn/HiddenColumn";
import LongColumn from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongColumn/LongColumn";
import FieldNotApplicableScoreFallback from "components/FieldNotApplicableScoreFallback/FieldNotApplicableScoreFallback";
import { isContentReady } from "utils/Array/Arrays";
import PropTypes from "prop-types";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

function TestsTableField({ isLoading, stations, data }) {
  const [mySpanColumns, setMySpanColumns] = useState([]);
  const [myHiddenColumns, setMyHiddenColumns] = useState([]);

  const isAsphalt = data?.inspectionName?.includes("Asphalt");

  const labEditRequestFieldTests = useMemo(
    () => [
      { serverKey: "layerName", name: "test_name" },
      {
        serverKey: "from",
        name: "start point",
        format: transformStationToFrom,
      },
      {
        serverKey: "to",
        name: "end point",
        format: transformStationToFrom,
      },
      {
        serverKey: "distance",
        name: "station length",
      },
      {
        serverKey: "layerNumber",
        name: "layer number",
        show: !isAsphalt,
      },
      {
        serverKey: "currentMaxProctor",
        name: isAsphalt ? "max proctor gmm" : "max proctor",
        hasComponent: {
          comp: (
            <LongColumn
              fallback={
                <FieldNotApplicableScoreFallback skipReasonKey="currentSkipReason" />
              }
            />
          ),
        },
      },
      {
        serverKey: "currentOptimumProctor",
        name: "optimum proctor",
        hasComponent: {
          comp: <HiddenColumn />,
        },
      },
      {
        serverKey: "editedStation",
        name: "result percentage",
        format: extractCurrentLabScores,
        hasComponent: {
          comp: (
            <HiddenColumn
              custom={(element) => {
                if (!isContentReady(element?.testStationMappingEdits)) {
                  return null;
                }
                if (
                  element?.testStationMappingEdits?.some(
                    (testStation) =>
                      !isContentReady(testStation.currentScores) ||
                      testStation?.currentScores === null
                  )
                ) {
                  return null;
                }
                return element?.testStationMappingEdits
                  .map(getTestMappingStationsLabScore)
                  .filter((s) => s !== null && s !== undefined)
                  .join(", ");
              }}
            />
          ),
        },
      },
    ],
    [isAsphalt]
  );

  function getTestMappingStationsLabScore(testStation) {
    return testStation?.currentScores?.map((item) => item?.labScore);
  }

  const editedStations = useMemo(
    () => calculateEditedStations(stations, data),
    [stations, data]
  );

  if (!stations) {
    return <BaseLoader />;
  }

  const tableProps = {
    tableHeader: labEditRequestFieldTests,
    content: editedStations || [],
    injectProps: {
      tableId: TABLE_ID.ADMIN_EDITED_REQUESTS_TESTS_FIELD,
      submittedFromLabAt: true,
      mySpanColumns,
      setMySpanColumns,
      myHiddenColumns,
      setMyHiddenColumns,
      customServerKey: (element) => {
        if (
          element.tableHeaderItem.serverKey === "editedStation" &&
          !isContentReady(element?.editedStation?.testStationMappingEdits)
        ) {
          return null;
        }
        if (
          element.tableHeaderItem.serverKey === "editedStation" &&
          element?.editedStation?.testStationMappingEdits?.some(
            (testStation) =>
              !isContentReady(testStation.currentScores) ||
              testStation.currentScores === null
          )
        ) {
          return null;
        }
        return true;
      },
    },
    isLoading: isLoading || !stations,
    doubleLayer: (element) => setDoubleLayer(element, editedStations, data),
  };
  return (
    <Box>
      <SmartDoubleCardTable
        tableProps={tableProps}
        desktop={{
          injectProps: {
            ...tableProps.injectProps,
            longColumnGridDirection: "column",
          },
        }}
        mobile={{
          injectProps: {
            ...tableProps.injectProps,
            longColumnGridDirection: "row",
          },
        }}
      />
    </Box>
  );
}

TestsTableField.propTypes = {
  isLoading: PropTypes.bool,
  stations: PropTypes.array,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default TestsTableField;
