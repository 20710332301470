import { Box, Typography } from "@mui/material";
import MultipleConsultantDataItem from "components/MultipleConsultantDataItem/MultipleConsultantDataItem";
import Notes from "components/Notes/Notes";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import { defaultEmptyValue } from "constants/Empty";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import {
  useGetAppointmentDetailsForTechnicianQuery,
  useGetSamplesForTechnicianQuery,
} from "modules/Technician/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import { publicAssetsPath } from "utils/Paths/Paths";

const LabTechnicianRequestDetails = (props) => {
  const inspectionLayer = props.inspection.inspectionLayersList?.[0];

  const { data: appointmentData } = useGetAppointmentDetailsForTechnicianQuery(
    props.inspection.id
  );

  const { data: samplesData } = useGetSamplesForTechnicianQuery(
    props.inspection.id
  );

  const singleLicense = props.inspection.licenses?.[0];
  const isSingleLicense = props.inspection?.licenses?.length === 1;

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  return (
    <Box className={"request-data-details"} mt={4}>
      <Box className={"request-data-details__wrapper"}>
        <Typography className={"request-data-details__title"}>
          تفاصيل الطلب
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم الطلب
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection.id || defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              اختبار مواد الأعمال المدنية{" "}
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection.name || defaultEmptyValue}
            </Typography>
          </Box>

          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              موعد الجمع
            </Typography>
            <Typography
              component={"div"}
              className={"request-data-details__value"}
            >
              <DisplayRequestAppointmentDate
                appointmentDate={appointmentData?.scheduledDate}
                scheduledAppointment={appointmentData?.scheduledTime}
              />
            </Typography>
          </Box>
          {isABC && (
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                درجة التربة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspectionLayer?.soilGrade || defaultEmptyValue}
              </Typography>
            </Box>
          )}
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              عدد العينات
            </Typography>
            <Typography className={"request-data-details__value"}>
              {samplesData?.numberOfSamples
                ? samplesData.numberOfSamples * 3
                : 0}
            </Typography>
          </Box>

          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              الحالة
            </Typography>
            <BaseStatus
              status={props.inspection.status}
              testType={props.inspection?.testType}
              dataId={props.dataId}
            />
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              المقاول
            </Typography>
            <Typography className={"request-data-details__value"}>
              {singleLicense.contractorName || defaultEmptyValue}
            </Typography>
          </Box>
          {props.inspection?.contractorMobileNumber && (
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم جوال المقاول
              </Typography>
              <Typography className={"request-data-details__value"}>
                {props.inspection.contractorMobileNumber || defaultEmptyValue}
              </Typography>
            </Box>
          )}
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              {`موقع أخذ العينة`}
            </Typography>
            {inspectionLayer?.latitude ? (
              <LocationField
                location={`https://maps.google.com/?q=${inspectionLayer?.latitude},${inspectionLayer?.longitude}`}
              />
            ) : (
              <Typography className={"request-data-details__value"}>
                {defaultEmptyValue}
              </Typography>
            )}
          </Box>
        </WrapperContentGrid>
      </Box>
      {isAsphalt && inspectionLayer?.aspeltMixDesignDetailDto?.notes && (
        <>
          <hr />
          <Box my={2}>
            <Box mb={2}>
              <Notes notes={inspectionLayer?.aspeltMixDesignDetailDto?.notes} />
            </Box>
          </Box>
        </>
      )}
      <hr />
      <Box className={"request-data-details__wrapper"}>
        <Typography className={"request-data-details__title"}>
          معلومات الاستشاري
        </Typography>
        {isSingleLicense ? (
          <WrapperContentGrid gap={4}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                الاسم
              </Typography>
              <Typography className={"request-data-details__value"}>
                {singleLicense.consultantName || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الجوال
              </Typography>
              <Typography className={"request-data-details__value"}>
                {formatPhoneNumber(singleLicense.consultantMobile) ||
                  defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                البريد الإلكتروني
              </Typography>
              <Typography className={"request-data-details__value"}>
                {singleLicense.consultantEmail || defaultEmptyValue}
              </Typography>
            </Box>
          </WrapperContentGrid>
        ) : (
          <MultipleConsultantDataItem
            requestId={props.inspection?.id}
            data={props.inspection?.licenses}
          />
        )}
      </Box>
      <hr />
      <Box className={"request-data-details__wrapper"}>
        <Typography className={"request-data-details__title"}>
          معلومات المختبر
        </Typography>
        <WrapperContentGrid gap={4}>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              الاسم
            </Typography>
            <Typography className={"request-data-details__value"}>
              {props.inspection.labName || defaultEmptyValue}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              موقع المختبر
            </Typography>
            <Typography
              className={"request-data-details__value"}
              sx={{
                fontWeight: "bold !important",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {props.inspection.labLocation ? (
                <>
                  <a
                    href={props.inspection.labLocation}
                    target="_blank"
                    className="request-data-details__link"
                    rel="noreferrer"
                  >
                    الموقع
                  </a>
                  <img
                    src={publicAssetsPath("icons/popout-svgrepo.svg")}
                    width="auto"
                    alt="location"
                  />
                </>
              ) : (
                defaultEmptyValue
              )}
            </Typography>
          </Box>
          <Box className={"request-data-details__item"}>
            <Typography className={"request-data-details__label"}>
              رقم الجوال
            </Typography>
            <Typography className={"request-data-details__value"}>
              {formatPhoneNumber(props.inspection.labRepresentativeMobile) ||
                defaultEmptyValue}
            </Typography>
          </Box>
        </WrapperContentGrid>
      </Box>
    </Box>
  );
};

LabTechnicianRequestDetails.propTypes = {
  inspection: PropTypes.object,
  columns: PropTypes.number,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabTechnicianRequestDetails;
