import adminApi from "..";

const configurationApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfigurationList: builder.query({
      query: () => {
        return {
          url: `/api/admin/configuration/list`,
        };
      },
      providesTags: ["CONFIGURATION"],
    }),
    editConfigurationList: builder.mutation({
      query: (body) => ({
        url: `/api/admin/configuration/edit`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CONFIGURATION"],
    }),
  }),
});

export const {
  useGetConfigurationListQuery,
  useEditConfigurationListMutation,
} = configurationApi;

export default configurationApi;
