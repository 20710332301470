import { createSlice } from "@reduxjs/toolkit";
import { UsersRolesEnum } from "constants/UsersTypes";
import { getUserToken } from "services/Storage";

const user = getUserToken();

const initialState = {
  contractorRegistrationData: {
    registerDataMain: {},
    validateData: {},
  },
  consultantRegistrationData: {
    registerDataForm: {},
  },
  isLoading: true,
  error: null,
  isAuth: !!user?.token,
  token: user?.token || null,
  user: user?.data || null,
  userType: user?.data.user_type.toLowerCase() || "", // EXAMPLE NOT AUTH
  userRole: user?.data.user_role || "", // EXAMPLE NOT AUTH
};
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    saveContractorRegistrationData: (state, action) => {
      state.contractorRegistrationData = {
        registerDataMain: action.payload.registerDataMain,
        validateData: action.payload.validateData,
      };
    },
    saveConsultantRegistrationData: (state, action) => {
      state.consultantRegistrationData = {
        registerDataForm: action.payload.registerDataForm,
      };
    },

    clearConsultantRegistrationData: (state) => {
      state.consultantRegistrationData = {
        registerDataForm: {},
      };
    },
    login: (state, action) => {
      state.isAuth = true;
      state.token = action.payload.user.token;
      state.user = action.payload.user.data;
      state.userType = action.payload.user.data.user_type.toLowerCase();
      state.userRole = action.payload.user.data.user_role;
    },
    logout: (state, action) => {
      state.isAuth = false;
      state.token = null;
      state.user = null;
      state.userType = "";
      state.userRole = "";
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
