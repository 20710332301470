import { useRoutes } from "react-router-dom";
import authRoutes from "modules/Auth/routes";
import technicianRoutes from "modules/Technician/routes";
import adminRoutes from "modules/Admin/routes";
import labTechnicianRoutes from "modules/LaboratoryTechnician/routes";
import DistributionCenterRoutes from "modules/DistributionCenter/routes";
import publicRoutes from "modules/Public/routes";
import { useSelector } from "react-redux";
import { UsersRolesEnum, UsersTypesEnum } from "constants/UsersTypes";
import useCatchUserType from "hooks/useCatchUserType";
import { Box } from "@mui/material";
import useBreakpoint from "hooks/useBreakpoint";
import { sysopsRoleRoutes } from "utils/Routes/Routes";
import consultantRoutes from "modules/Consultant/routes";
import { addSharedRoutesMiddleware } from "utils/Paths/Paths";
import {
  notFoundRoute,
  sharedRoutesBetweenPublicAndAuth,
} from "services/Routes";

const BaseLayout = () => {
  const { isAuth, userType, userRole } = useSelector((store) => store.auth);

  const matches = useBreakpoint();

  const { userRoleFromURL } = useCatchUserType();

  const allRoutes = {
    [UsersRolesEnum.SYSOPS]: [sysopsRoleRoutes(userType, userRole)],

    [UsersRolesEnum.LAB_TECHNICIAN]: labTechnicianRoutes,

    [UsersRolesEnum.CONSULTANT]: consultantRoutes,

    [UsersTypesEnum.CENTER]: DistributionCenterRoutes,

    [UsersRolesEnum.TECHNICIAN]: technicianRoutes,

    [UsersRolesEnum.SYSADMIN]: adminRoutes,
    [UsersRolesEnum.OP_ADMIN]: adminRoutes,
    [UsersRolesEnum.OP_SUPP_ADMIN]: adminRoutes,
    [UsersRolesEnum.QA_QC_ADMIN]: adminRoutes,
    [UsersRolesEnum.QA_QC_SUPP_ADMIN]: adminRoutes,
  };

  let renderedRoutes = null;

  if (allRoutes[userRole]) {
    renderedRoutes = allRoutes[userRole];
  } else if (allRoutes[userType]) {
    renderedRoutes = allRoutes[userType];
  } else {
    renderedRoutes = allRoutes[userRoleFromURL];
  }

  addSharedRoutesMiddleware(renderedRoutes, sharedRoutesBetweenPublicAndAuth);

  const routes = useRoutes([
    ...(isAuth ? renderedRoutes ?? [] : authRoutes),
    ...(!isAuth ? [] : authRoutes),
    ...publicRoutes,
    notFoundRoute,
  ]);

  return <Box sx={{ paddingTop: matches ? "5rem" : "0" }}>{routes}</Box>;
};

export default BaseLayout;
