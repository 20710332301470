import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { availableTo } from "utils/Auth/Auth";

const RoleBasedComponent = (props) => {
  const { userRole } = useSelector((store) => store.auth);

  return availableTo(userRole, props.allowedRoles, props.children);
};

RoleBasedComponent.defaultProps = {
  allowedRoles: [],
};

RoleBasedComponent.propTypes = {
  allowedRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default RoleBasedComponent;
