import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { useTranslation } from "react-i18next";
import { AdminAddUserManagementBreadcrumb } from "services/StaticLookup/Breadcrumb";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { Box } from "@mui/material";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { ValidEmailPattern } from "constants/Forms";
import {
  useGetUserRolesQuery,
  useGetUsersOfficeQuery,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import useAlert from "hooks/useAlert";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { AlertTypes } from "constants/AlertTypes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { useNavigate } from "react-router-dom";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useAddNewUserMutation } from "modules/Auth/apis/user-api";
import { _scrollToTop } from "utils/DOM/DOM";
import { customValidateMobileNumber } from "utils/Forms/Forms";

const AdminAddUser = () => {
  const { t } = useTranslation(["dashboard"]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", shouldUnregister: true });

  const [addNewUser, addNewUserResponse] = useAddNewUserMutation();
  const { data: usersOffice } = useGetUsersOfficeQuery();
  const { data: userRoles, isLoading: isUserRolesLoading } =
    useGetUserRolesQuery({ shouldEdit: true });

  let offices = [];
  if (usersOffice) {
    offices = usersOffice.map((office) => ({
      id: office?.id,
      name: office?.name,
    }));
  }

  const selectedJob = watch("job");
  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const navigate = useNavigate();

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    const newUserData = {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      role: data.job.role,
      type: data.job.type,
    };

    if (data.entityId?.id) {
      newUserData.entityId = data.entityId.id;
    }

    if (data.job.type === "CENTER") {
      newUserData.entityId = "1";
    }

    addNewUser(newUserData)
      .unwrap()
      .then((_) => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.USER_CREATED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message:
            err.data?.message ||
            err.data?.errorCode ||
            ErrorCodes.UNKNOWN_ERROR,
          type: AlertTypes.ERROR,
        });
      })
      .finally(() => {
        reset();
        _scrollToTop();
      });
  };
  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين`}</title>
      </Helmet>

      <DashboardIntro
        title={"إضافة مستخدم جديد"}
        description={"قم بتعبئة بيانات المستخدم التالية"}
        breadcrumbData={AdminAddUserManagementBreadcrumb()}
        action={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={5}
            closeIcon={false}
          />
        }
      />

      <BaseCard py={"5rem"} px={"6rem"} mt={6} minHeight={"75%"}>
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <BaseCardTitle marginBottom={5}>المعلومات العامة</BaseCardTitle>
          <WrapperContentGrid
            gap={10}
            sx={{ marginBottom: "4rem", flexDirection: "row", mt: "3rem" }}
          >
            <BaseInput
              htmlFor="name"
              label={t("name")}
              placeholder={t("الاسم")}
              maxLength={30}
              minLength={6}
              labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
              name="name"
              register={{
                ...register("name", {
                  required: {
                    value: true,
                    message: `${t("field")} ${t("name")} ${t("required")}`,
                  },
                  minLength: {
                    value: 6,
                    message: "يجب أن يكون الاسم ٦ احرف على الأقل",
                  },
                }),
              }}
              errors={errors}
            />
            <BaseDropdown
              control={control}
              name="job"
              required
              errors={errors}
              label={"الوظيفة"}
              labelStyles={{
                color: "grayDark.color",
                fontSize: "1.4rem",
                fontWeight: 500,
              }}
              isLoading={isUserRolesLoading}
              placeholder="حدد الوظيفة"
              data={userRoles}
            />

            {selectedJob && selectedJob.name === "فني الرقابة" && (
              <BaseDropdown
                control={control}
                name="entityId"
                required
                errors={errors}
                label={t("entityId")}
                labelStyles={{
                  color: "grayDark.color",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                }}
                placeholder={t("select office")}
                data={offices}
              />
            )}
          </WrapperContentGrid>
          <hr />

          <BaseCardTitle marginBottom={5} mt={10}>
            معلومات التواصل
          </BaseCardTitle>

          <WrapperContentGrid
            gap={10}
            sx={{ marginBottom: "4rem", flexDirection: "row", mt: "3rem" }}
          >
            <BaseInput
              htmlFor="email"
              label={t("email")}
              placeholder={t("البريد الإلكتروني")}
              maxLength={30}
              labelStyles={{ fontWeight: "bold", color: "grayDark.color" }}
              name="email"
              register={{
                ...register("email", {
                  required: {
                    value: true,
                    message: `${t("field")} ${t("email")} ${t("required")}`,
                  },
                  pattern: {
                    value: ValidEmailPattern,
                    message: `${t("Invalid email address", { ns: "auth" })}`,
                  },
                }),
              }}
              errors={errors}
            />
            <BaseInput
              htmlFor="mobile"
              label={"رقم الجوال"}
              placeholder="05 xxx xxxx"
              labelStyles={{
                fontWeight: "bold",
                color: "grayDark.color",
              }}
              name="mobile"
              register={{
                ...register("mobile", {
                  required: {
                    value: true,
                    message: "حقل رقم الجوال مطلوب",
                  },
                  validate: customValidateMobileNumber,
                }),
              }}
              errors={errors}
            />
          </WrapperContentGrid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              flexWrap: "wrap",
              gap: 4,
            }}
          >
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              variant={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("تراجع")}
            </BaseButton>
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              type="submit"
              variant={addNewUserResponse.isLoading ? "disabled" : ""}
              disabled={addNewUserResponse.isLoading}
            >
              {t("إضافة")}
            </BaseButton>
          </Box>
        </form>
      </BaseCard>
    </>
  );
};

export default AdminAddUser;
