import React from "react";
import BaseInput from "core-ui/BaseInput/BaseInput";
import PropTypes from "prop-types";

const LayerNumberInput = (props) => {
  const { errors, register, id, t, inputStyles } = props;

  return (
    <BaseInput
      defaultErrorSx
      inputContainerStyles={{ marginBottom: 0 }}
      step="any"
      placeholder="1"
      inputStyles={{ width: "10rem", marginTop: 0, ...inputStyles }}
      htmlFor={`station-${id}-layerNumber`}
      name={`station-${id}-layerNumber`}
      maxLength={9}
      register={{
        ...register(`station-${id}-layerNumber`, {
          required: {
            value: true,
            message: `${t("field")} ${t("required")}`,
          },
          pattern: {
            value: /^[0-9]+$/,
            message: `يجب أن يكون الرقم صحيح`,
          },
        }),
      }}
      errors={errors}
    />
  );
};

LayerNumberInput.propTypes = {
  errors: PropTypes.object,
  inputStyles: PropTypes.object,
  register: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func,
};

export default LayerNumberInput;
