import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import DisplayResultTest from "components/DisplayResultTest/DisplayResultTest";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import DisplayMinMaxSingleScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayMinMaxSingleScores/DisplayMinMaxSingleScores";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SieveTable from "modules/Laboratory/pages/Dashboard/AssignedRequests/AssignedRequestsDetails/Lab/SieveTable/SieveTable";
import DisplayScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayScore/DisplayScore";
import { enLabelSx } from "constants/Typography";
import PropTypes from "prop-types";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

const formatter = (cell) => {
  return (
    <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>{cell}</Typography>
  );
};

function LabExaminationRequestTests({
  inspectionTestsData,
  asphalet,
  subTests,
  isFetching,
  ...props
}) {
  const adminAsphaletSieveMixDesignGradationLimits = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: formatter,
    },
    { name: " " },
    { name: "  ", hasComponent: { comp: <DisplayMinMaxSingleScores /> } },
    {
      serverKey: "score",
      name: "   ",
      fallback: "لا يوجد",
    },
  ];

  const adminSieveMixDesignGradationLimits = [
    {
      serverKey: "testName",
      name: "Sieve Size",
      format: formatter,
    },
    {
      serverKey: "status",
      name: "          ",
      show: !asphalet,
      hasComponent: { comp: <BaseStatus tableId={TABLE_ID.SUB_TEST_STATUS} /> },
    },
    {
      name: "            ",
      hasComponent: { comp: <DisplayMinMaxSingleScores /> },
    },
    {
      serverKey: "score",
      name: "               ",
      fallback: "غير محدد",
    },
    { name: "                  " },
  ];

  const adminLabExaminationRequest = useMemo(() => [
    { serverKey: "testName", name: "Test" },
    {
      serverKey: "status",
      hasComponent: { comp: <BaseStatus /> },
      name: "Test Status",
    },
    {
      name: "limits",
      hasComponent: { comp: <DisplayMinMaxSingleScores /> },
    },
    {
      serverKey: "score",
      name: "Result",
      hasComponent: { comp: <DisplayScore /> },
    },
    {
      serverKey: "result",
      name: "resultـstatus",
      show: !asphalet,
      hasComponent: {
        comp: <DisplayResultTest />,
      },
      fallback: "بنتظار المعالجة",
    },
  ]);

  const tableProps = {
    tableHeader: adminLabExaminationRequest,
    content: inspectionTestsData || [],
    isLoading: isFetching,
    injectProps: {
      mainData: props?.testsData || [],
      layersData: props?.layersData,
      tableId: TABLE_ID.SUB_TEST_STATUS,
    },
    doubleLayer: () => {
      return {
        visibility: true,
        component: (element) => {
          return (
            <SieveTable
              tableHeader={
                asphalet
                  ? adminAsphaletSieveMixDesignGradationLimits
                  : adminSieveMixDesignGradationLimits
              }
              content={subTests?.inspectionSubTests || []}
              isLoading={isFetching}
            />
          );
        },
      };
    },
    withSpecificAction: (element) => {
      return {
        condition: element?.containSubtests,
        actionType: "dropdown",
        component: (
          <KeyboardArrowDownIcon
            color="primary"
            fontSize="large"
            sx={{
              cursor: "pointer",
              transition: "all .5s ease",
              transform: element?.isOpen ? "rotate(180deg)" : "",
            }}
          />
        ),
      };
    },
  };

  return (
    <Box>
      <SmartDoubleCardTable tableProps={tableProps} />
    </Box>
  );
}

LabExaminationRequestTests.propTypes = {
  inspectionTestsData: PropTypes.array,
  asphalet: PropTypes.bool,
  subTests: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isFetching: PropTypes.bool,
  testsData: PropTypes.array,
  layersData: PropTypes.array,
};

export default LabExaminationRequestTests;
