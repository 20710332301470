import {
  opadminRoleChecker,
  opsuppadminRoleChecker,
  qaqcadminRoleChecker,
  qaqcsuppadminRoleChecker,
  sysadminRoleChecker,
} from "utils/Auth/Auth";
import { UsersRolesEnum } from "constants/UsersTypes";
import { UsersRoles } from "services/StaticLookup/UsersJobs";

const userDetailsAdmin = (userRole) => userRole === UsersRolesEnum.SYSADMIN;
const userDetailsOpAdmin = (userRole) => userRole === UsersRolesEnum.OP_ADMIN;
const userDetailsOpSuppAdmin = (userRole) =>
  userRole === UsersRolesEnum.OP_SUPP_ADMIN;
const userDetailsQaQcAdmin = (userRole) =>
  userRole === UsersRolesEnum.QA_QC_ADMIN;
const userDetailsQaQcSuppAdmin = (userRole) =>
  userRole === UsersRolesEnum.QA_QC_SUPP_ADMIN;

export const shouldRenderUserManagementControls = (
  userType,
  userRole,
  userDetailsRole
) => {
  switch (userRole) {
    // ADMIN
    case sysadminRoleChecker(userType, userRole):
      return true;
    // OP ADMIN
    case opadminRoleChecker(userType, userRole):
      if (shouldManage(userDetailsAdmin)(userDetailsRole)) return false;
      if (shouldManage(userDetailsOpAdmin)(userDetailsRole)) return false;
      if (shouldManage(userDetailsQaQcAdmin)(userDetailsRole)) return false;
      if (shouldManage(userDetailsQaQcSuppAdmin)(userDetailsRole)) return false;
      return true;
    // OP SUPP ADMIN
    case opsuppadminRoleChecker(userType, userRole):
      return false;
    // QA QC ADMIN
    case qaqcadminRoleChecker(userType, userRole):
      if (shouldManage(userDetailsAdmin)(userDetailsRole)) return false;
      if (shouldManage(userDetailsOpAdmin)(userDetailsRole)) return false;
      if (shouldManage(userDetailsOpSuppAdmin)(userDetailsRole)) return false;
      if (shouldManage(userDetailsQaQcAdmin)(userDetailsRole)) return false;
      return true;
    // QA QC SUPP ADMIN
    case qaqcsuppadminRoleChecker(userType, userRole):
      return false;
  }

  return false;
};

const shouldManage = (userRoleChecker) => (userRole) => {
  if (userRoleChecker(userRole)) {
    return true;
  }
  return false;
};

export const getRoleLabel = (data) => {
  const currentRole = UsersRoles.find(
    (item) => item?.role === data?.role && item?.type === data?.entitytype
  );
  return currentRole?.name;
}
