import { Box, Typography } from "@mui/material";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import EmptyTable from "core-ui/BaseTable/EmptyTable/EmptyTable";
import PropTypes from "prop-types";
import React from "react";

const BaseTableResponsive = (props) => {
  const isDataEmpty = props.content.length === 0;
  return (
    <Box
      sx={{
        ...props.mainWrapperSx,
      }}
    >
      {props.title && <BaseCardTitle mb={1}>{props.title}</BaseCardTitle>}

      {props.description && (
        <Typography
          sx={{ fontSize: "1.6rem" }}
          color={"grayDark.color1"}
          mb={3}
        >
          {props.description}
        </Typography>
      )}
      <Box sx={{ mt: 2 }}>
        {!isDataEmpty &&
          Array.isArray(props.content) &&
          props.content.map((dataItem, dataItemIndex) => {
            return (
              <BaseCard
                key={dataItem.id}
                variant="secondary"
                sx={{
                  mt: 3,
                  position: "relative",
                  pb:
                    dataItemIndex > props.showRemoveButtonFromIndex - 1
                      ? 5
                      : "2rem",
                  pt: "2rem",
                }}
                px="2rem"
              >
                {props.tableHeader.map((headerItem) => {
                  return (
                    <Box
                      key={headerItem.serverKey}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: ".5fr 1% 1fr",
                        alignItems: "center",
                        gap: "1rem",
                        mb: 2,
                      }}
                    >
                      {headerItem.header && headerItem.header() && (
                        <>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "1.4rem",
                              color: "black",
                            }}
                          >
                            {headerItem.header(
                              headerItem,
                              dataItem,
                              dataItemIndex
                            )}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "1.4rem" }}
                          >
                            :
                          </Typography>
                        </>
                      )}
                      <Box
                        sx={{
                          fontWeight: 400,
                          fontSize: "1.4rem",
                          color: "black",
                        }}
                      >
                        {headerItem.value({
                          headerItem,
                          dataItem,
                          index: dataItemIndex,
                        })}
                      </Box>
                    </Box>
                  );
                })}
                {dataItemIndex > props.showRemoveButtonFromIndex - 1 && (
                  <Box
                    sx={{
                      background: "#b6d1cc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#618984",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "3rem",
                      textAlign: "center",
                    }}
                    onClick={props.onRemove.bind(null, dataItem)}
                  >
                    حذف
                  </Box>
                )}
                {/* ERROR ALERT */}
                {props.helperMessageComponent &&
                  props.helperMessageComponent?.(dataItem)}
              </BaseCard>
            );
          })}
        {isDataEmpty && (
          <EmptyTable
            component="div"
            sx={{
              gridColumn: "1 / -1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            emptyData={props.emptyData}
          />
        )}
      </Box>
      {/* ADD BUTTON */}
      {props.appendComponent}
    </Box>
  );
};

BaseTableResponsive.propTypes = {
  content: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  mainWrapperSx: PropTypes.object,
  tableHeader: PropTypes.array,
  showRemoveButtonFromIndex: PropTypes.number,
  onRemove: PropTypes.func,
  helperMessageComponent: PropTypes.func,
  appendComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
  emptyData: PropTypes.object,
};

export default BaseTableResponsive;
