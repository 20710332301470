import { Box, Typography, useMediaQuery } from "@mui/material";
import { TABLE_ID } from "constants/Table";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { useTranslation } from "react-i18next";
import { publicAssetsPath } from "utils/Paths/Paths";
import LocationField from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LocationField/LocationField";
import { formatDateYYYYMMDD, renderDateTimeInAMPM } from "utils/Date/Date";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import LongLength from "components/TableUtilitiesComponents/TableDynamicFieldComponents/LongLength/LongLength";
import DisplayResultTest from "components/DisplayResultTest/DisplayResultTest";
import { useMemo } from "react";
import BaseFileDataItem from "core-ui/BaseFileDataItem/BaseFileDataItem";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { AlertTypes } from "constants/AlertTypes";
import { defaultEmptyValue } from "constants/Empty";
import MixDesignDetails from "modules/Laboratory/components/MixDesignDetails/MixDesignDetails";
import Notes from "components/Notes/Notes";
import DisplayMinMaxSingleScores from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayMinMaxSingleScores/DisplayMinMaxSingleScores";
import { enLabelSx } from "constants/Typography";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import { KeyboardArrowDown } from "@mui/icons-material";
import DoubleLayerData from "./DoubleLayerData";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import DisplayScore from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayScore/DisplayScore";
import MultipleLicensesDataItem from "components/MultipleLicensesDataItem/MultipleLicensesDataItem";
import LicenseLink from "components/LicenseLink/LicenseLink";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import PropTypes from "prop-types";
import { formatPhoneNumber } from "utils/Numbers/Numbers";
import BaseTableDoubleCardResponsive from "core-ui/BaseTable/BaseTableDoubleCardResponsive/BaseTableDoubleCardResponsive";
import { useTheme } from "@emotion/react";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

const ExaminationDataLab = ({
  request,
  inspectionTestsData,
  isFetching,
  subTests,
  labtestappointment,
  canReschedule,
  dataId,
  asphalet,
  inspectionLayer,
  AspeltBaseCourseAttachment,
  columns,
  ...props
}) => {
  const { t } = useTranslation(["dashboard"]);

  const { breakpoints } = useTheme();

  const lg = useMediaQuery(breakpoints.down("lg"));

  const createdAtDateObject = new Date(request?.createdAt);

  const requestCreatedAtTime = renderDateTimeInAMPM(
    `${createdAtDateObject.getHours()}:${createdAtDateObject.getMinutes()}:00`
  );

  const inspectionTests = inspectionTestsData?.map(
    (inspection) => inspection?.inspectionTests
  );

  const formatter = (cell) => {
    return (
      <Typography sx={{ ...enLabelSx, fontSize: "1.6rem" }}>{cell}</Typography>
    );
  };

  const contractorExaminationDetailsTests = useMemo(
    () => [
      {
        serverKey: "testName",
        name: "test name",
        format: formatter,
      },
      {
        serverKey: "testStandard",
        hasComponent: { customProps: "testStandard", comp: <LongLength /> },
        fallback: "لا يوجد",
        name: "testـstandards",
      },
      {
        ...(asphalet && {
          serverKey: "minPassScore",
          name: "limits",
          hasComponent: { comp: <DisplayMinMaxSingleScores /> },
        }),
      },
      {
        serverKey: "status",
        name: "status",
        hasComponent: { comp: <BaseStatus /> },
      },
      {
        serverKey: "score",
        name: "score percentage",
        hasComponent: { comp: <DisplayScore /> },
      },
      {
        serverKey: ["result", "status"],
        name: "Result",
        show: !asphalet,
        hasComponent: {
          comp: <DisplayResultTest />,
        },
      },
    ],
    [
      asphalet,
      inspectionTestsData,
      request?.name,
      request?.requiresAspeltReport,
    ]
  );

  const isRequestDone =
    request?.status === "SUCCEED" || request?.status === "FAILED";

  const isSingleLicense = request?.licenses?.length === 1;

  const isABC = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ABC
  );

  const tableProps = {
    title: "الاختبارات",
    description:
      "تم تحديد الاختبارات التالية بناء على نوع اختبار مواد الأعمال المدنية و نوع مسار الفحص",
    content: inspectionTests?.[0] || [],
    tableHeader: contractorExaminationDetailsTests,
    isLoading: isFetching,
    emptyData: {
      title: t("No new license"),
      desc: t("No new license desc"),
      img: publicAssetsPath("images/documents.svg"),
    },
    injectProps: {
      tableId: TABLE_ID.SUB_TEST_STATUS,
      isRequestDone,
      extraStatus: request?.status,
    },
    doubleLayer: () => {
      return {
        visibility: true,
        component: () => (
          <DoubleLayerData
            subTests={subTests?.inspectionSubTests}
            request={request}
            asphalet={asphalet}
          />
        ),
      };
    },
    withSpecificAction: (element, triggeredElements) => {
      return {
        condition: element?.containSubtests,
        actionType: "dropdown",
        component: (
          <KeyboardArrowDown
            sx={{
              fontSize: 38,
              cursor: "pointer",
              color: "#347A7C",
              transition: "all .5s ease",
              transform: triggeredElements?.[element.id]
                ? "rotate(180deg)"
                : "",
              marginRight: "10px",
            }}
          />
        ),
      };
    },
  };

  return (
    <Box>
      {isFetching ? (
        <BaseLoader />
      ) : (
        <Box>
          <Box className={"request-data-details"} mt={4}>
            <BaseAlert
              show={canReschedule}
              type={AlertTypes.WARNING}
              labelSx={{ fontWeight: "bold", color: "black" }}
              message={"REQUEST_IS_OUTDATED"}
              icon={false}
            />

            <Box className={"request-data-details__wrapper"} mb={5}>
              <Typography className={"request-data-details__title"}>
                تفاصيل الطلب
              </Typography>
              <WrapperContentGrid gap={4}>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    رقم الطلب
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {request?.id}
                  </Typography>
                </Box>
                {isSingleLicense ? (
                  <LicenseLink license={request?.licenses?.[0]} />
                ) : (
                  <MultipleLicensesDataItem
                    requestId={request?.id}
                    data={request?.licenses}
                  />
                )}
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    حالة الفحص
                  </Typography>
                  <BaseStatus
                    status={request?.status}
                    dataId={dataId}
                    testType={request?.testType}
                    appointment={request?.appointment}
                  />
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    تاريخ الإنشاء
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {formatDateYYYYMMDD(request?.createdAt)}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    وقت الإنشاء
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {requestCreatedAtTime}
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    {"اختبار مواد الأعمال المدنية"}
                  </Typography>
                  <Typography
                    className={"request-data-details__value"}
                    sx={{ direction: "rtl" }}
                  >
                    {request?.name || defaultEmptyValue}
                  </Typography>
                </Box>
                {isABC && (
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      درجة التربة
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {inspectionLayer?.soilGrade || defaultEmptyValue}
                    </Typography>
                  </Box>
                )}
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    {"موعد جمع العينة"}
                  </Typography>
                  <Typography
                    component={"div"}
                    className={"request-data-details__value"}
                  >
                    <DisplayRequestAppointmentDate
                      appointmentDate={labtestappointment?.scheduledDate}
                      scheduledAppointment={labtestappointment?.scheduledTime}
                    />
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    مسار الفحص
                  </Typography>
                  <BaseTypeTag type={request?.testType} />
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    المدة المتوقعة
                  </Typography>
                  <Typography className={"request-data-details__value"}>
                    {request?.contractorEstimatedTime || "لايوجد"} أيام
                  </Typography>
                </Box>
                <Box className={"request-data-details__item"}>
                  <Typography className={"request-data-details__label"}>
                    {`موقع أخذ العينة`}
                  </Typography>
                  {request?.inspectionLayersList[0]?.latitude ? (
                    <LocationField
                      location={`https://maps.google.com/?q=${request?.inspectionLayersList[0]?.latitude},${request?.inspectionLayersList[0]?.longitude}`}
                    />
                  ) : (
                    <Typography className={"request-data-details__value"}>
                      {defaultEmptyValue}
                    </Typography>
                  )}
                </Box>

                {asphalet && (
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      ملف تصميم خلطة الاسفلت (Asphalt Mix Design)
                    </Typography>
                    <BaseFileDataItem
                      label=" Asphalt.pdf"
                      attachment={AspeltBaseCourseAttachment}
                    />
                  </Box>
                )}
              </WrapperContentGrid>
            </Box>
            <hr />
          </Box>
          {request?.techName && request?.techMobile && request?.techEmail && (
            <>
              <Box className={"request-data-details__wrapper"}>
                <Typography className={"request-data-details__title"}>
                  بيانات فني الرقابة
                </Typography>
                <WrapperContentGrid gap={4}>
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      الاسم
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {request?.techName || defaultEmptyValue}
                    </Typography>
                  </Box>
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      البريد الإلكتروني
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {request?.techEmail || defaultEmptyValue}
                    </Typography>
                  </Box>
                  <Box className={"request-data-details__item"}>
                    <Typography className={"request-data-details__label"}>
                      رقم الجوال
                    </Typography>
                    <Typography className={"request-data-details__value"}>
                      {formatPhoneNumber(request?.techMobile) ||
                        defaultEmptyValue}
                    </Typography>
                  </Box>
                </WrapperContentGrid>
              </Box>
              <hr />
            </>
          )}
          {inspectionLayer?.requiresAspeltReport && asphalet && (
            <Box>
              <Box mt={5}>
                <Box mt={4} mb={3}>
                  <Notes
                    notes={inspectionLayer.aspeltMixDesignDetailDto?.notes}
                  />
                </Box>
                <Box mt={4} mb={7}>
                  <MixDesignDetails
                    aspeltMixDesignDetailDto={
                      inspectionLayer.aspeltMixDesignDetailDto
                    }
                  />
                </Box>
                <hr />
              </Box>
            </Box>
          )}
          {request?.cancellationReason && (
            <>
              <BaseAlert
                mb={5}
                mt={5}
                show={true}
                labelSx={{ fontWeight: "bold", color: "black" }}
                type={AlertTypes.WARNING}
                icon={false}
              >
                <Typography
                  sx={{
                    fontSize: "inherit !important",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  سبب إلغاء الطلب:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "normal",
                  }}
                >
                  {request?.cancellationReason}
                </Typography>
              </BaseAlert>
              <hr />
            </>
          )}
          <Box mt={5}>
            <SmartDoubleCardTable tableProps={tableProps} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ExaminationDataLab.propTypes = {
  request: PropTypes.object,
  inspectionTestsData: PropTypes.array,
  isFetching: PropTypes.bool,
  subTests: PropTypes.object,
  labtestappointment: PropTypes.object,
  canReschedule: PropTypes.bool,
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  asphalet: PropTypes.bool,
  inspectionLayer: PropTypes.object,
  AspeltBaseCourseAttachment: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ExaminationDataLab;
