import { Typography } from "@mui/material";
import { defaultEmptyValue } from "constants/Empty";
import PropTypes from "prop-types";
import { publicAssetsPath } from "utils/Paths/Paths";

const LocationField = (props) => {
  const { location, testType } = props;

  return (
    <Typography
      className={"request-data-details__value"}
      sx={{
        fontWeight: "bold !important",
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      {location && testType === "LAB" ? (
        defaultEmptyValue
      ) : (
        <>
          {location ? (
            <>
              <a
                href={location}
                target="_blank"
                className="request-data-details__link"
              >
                الموقع
              </a>
              <img
                src={publicAssetsPath("icons/popout-svgrepo.svg")}
                width="auto"
                alt="popout-svg"
              />
            </>
          ) : (
            defaultEmptyValue
          )}
        </>
      )}
    </Typography>
  );
};

LocationField.propTypes = {
  location: PropTypes.string,
  testType: PropTypes.string,
};

export default LocationField;
