import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

const ConfirmationModal = (props) => {
  const { palette } = useTheme();

  const { t } = useTranslation(["dashboard"]);

  const onConfirmHandler = (event) => {
    event.preventDefault();
    props.onConfirm(event);
  };

  return (
    <>
      <Box sx={{ mb: 8 }}>
        <Box>
          <Typography
            sx={{
              mt: 2,
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "grayDark.color",
              mb: 1.5,
            }}
          >
            {props.confirmMessage}
          </Typography>
        </Box>
      </Box>
      <Box
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
            color: `${palette.text.tags.blueSoft}`,
          }}
          variant={"secondary"}
          onClick={props.onHide}
          disabled={props.isLoading}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          isLoading={props.isLoading}
          onClick={onConfirmHandler}
        >
          {t("تأكيد")}
        </BaseButton>
      </Box>
    </>
  );
};

ConfirmationModal.propTypes = {
  confirmMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ConfirmationModal;
