import { FormHelperText, IconButton, InputLabel } from "@mui/material";
import { useState } from "react";
import classes from "./BaseInput.module.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import useCatchUserType from "hooks/useCatchUserType";
import { useTranslation } from "react-i18next";
import BaseTooltip from "core-ui/BaseTooltip/BaseTooltip";
import { stopOnWheel } from "utils/DOM/DOM";
import { publicAssetsPath } from "utils/Paths/Paths";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import PropTypes from "prop-types";

const BaseInput = (props) => {
  const { t } = useTranslation(["auth"]);

  const { userRoleFromURL } = useCatchUserType();

  const [showPassword, setShowPassword] = useState(false);

  const isPassword = props.type === "password";

  const inputHasError = () => {
    if (props.customError) {
      return props.customError;
    } else if (props.errors) {
      return props.errors[props.name];
    } else {
      return "";
    }
  };

  const inputDisabled = props.disabled;

  const changePasswordVisibilityHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  const inputType = () => {
    if (isPassword) {
      if (showPassword) {
        return "text";
      } else {
        return "password";
      }
    } else return props.type;
  };

  const dynamicInputWidth = props?.sx?.width
    ? `min(100%, ${props.sx.width})`
    : "100%";
  return (
    <Box sx={{ width: dynamicInputWidth, ...props.inputContainerStyles }}>
      {props.label && (
        <InputLabel
          sx={{
            ...props.labelStyles,
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: 500,
            marginBottom: "1rem",
          }}
          htmlFor={props.htmlFor}
        >
          {props.label}
        </InputLabel>
      )}
      <Box sx={{ position: "relative", width: dynamicInputWidth, ...props.sx }}>
        <input
          onInput={(e) => {
            if (
              props.name === "result" &&
              e.target.value.length > e.target.maxLength
            )
              e.target.value = e.target.value.slice(0, e.target.maxLength);
          }}
          onChange={
            props.onChange ? (e) => props.onChange(e.target.value) : () => {}
          }
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          type={inputType()}
          min={props.min}
          max={props.max}
          onWheel={stopOnWheel}
          maxLength={props.maxLength}
          value={props.value}
          onKeyDown={props.onKeyDown}
          defaultValue={props.defaultValue}
          {...props.register}
          autoComplete={props.autoComplete}
          step={props.step}
          id={props.htmlFor}
          pattern={props.pattern}
          onFocus={props.onFocus}
          style={{ ...props.inputStyles }}
          className={`
              ${classes["base-input"]} 
              ${
                classes[
                  `base-input--${inputHasError() ? "invalid" : undefined}`
                ]
              }
              ${
                classes[`base-input--${inputDisabled ? "disabled" : undefined}`]
              }
            `}
          placeholder={props.placeholder || props.label}
        />
        {isPassword && (
          <IconButton
            aria-label="toggle password visibility"
            onClick={changePasswordVisibilityHandler}
            edge="end"
            className={`${classes["base-input__icon"]} ${
              classes[`base-input__icon--${isPassword ? "password" : ""}`]
            }`}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        )}
        {!props.isLoading && props.icon && !props.tooltip && (
          <IconButton className={classes["base-input__icon"]}>
            <img
              src={publicAssetsPath(`icons/${props.icon}.svg`)}
              width="auto"
              alt={props.icon}
            />
          </IconButton>
        )}
        {props.isLoading && (
          <BaseLoader
            sx={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              transform: "translateY(-50%)",
            }}
            size="sm"
          />
        )}
        {props.tooltip && !props.icon && !props.isLoading && (
          <BaseTooltip
            className={classes["base-input__icon"]}
            type={props.tooltip.icon}
            text={props.tooltip.text}
          />
        )}
      </Box>

      {props.helperText && (
        <Box>
          <FormHelperText
            variant="filled"
            sx={{ marginInline: 0, fontSize: "1.2rem" }}
          >
            {props.helperText}
          </FormHelperText>
        </Box>
      )}
      {inputHasError() && (
        <Box>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {inputHasError().message}
          </FormHelperText>
        </Box>
      )}
      {props.resetPassword && (
        <Link
          style={{
            fontSize: "1.4rem",
            fontWeight: 500,
          }}
          to={`/auth/${userRoleFromURL}/reset-password`}
          className="reset-password-link"
        >
          {t("forget password")}
        </Link>
      )}
    </Box>
  );
};

BaseInput.propTypes = {
  type: PropTypes.string,
  customError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errors: PropTypes.object,
  sx: PropTypes.object,
  inputContainerStyles: PropTypes.object,
  inputStyles: PropTypes.object,
  labelStyles: PropTypes.object,
  tooltip: PropTypes.object,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  register: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  resetPassword: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  pattern: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
};

export default BaseInput;
