import ReassignTechnicianModal from "components/AllModalContent/ReassignTechnicianModal/ReassignTechnicianModal";
import ViewDetails from "components/TableUtilitiesComponents/TableActionsComponents/ViewDetails/ViewDetails";
import { AlertTypes } from "constants/AlertTypes";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseModal from "core-ui/BaseModal/BaseModal";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import {
  useAssignNewTechnicianMutation,
  useGetTechniciansQuery,
  useGetUnAssignedTechniciansQuery,
  useLazyGetIsTechnicianAvailableQuery,
} from "modules/Admin/apis/reassign-apis/reassign-apis";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import { UnAssignedTechniciansRequestsIndex } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";

const UnAssignedTechnicians = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [showModal, setShowModal] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [isAvailable, setIsAvailable] = useState("");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const {
    page: unAssignedPage,
    pageSize: unAssignedPageSize,
    onChangePaginationDataHandler: unAssignedOnChangePaginationDataHandler,
  } = useBaseTablePagination();

  const {
    data: unAssignedTechniciansData,
    isFetching: isUnAssignedTechniciansFetching,
  } = useGetUnAssignedTechniciansQuery(
    {
      page: unAssignedPage,
      size: unAssignedPageSize,
      sortId: "id,desc",
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: technicnaData } = useGetTechniciansQuery();

  const [assignNewTechnician, assignNewTechnicianResponse] =
    useAssignNewTechnicianMutation();

  const [getIsTechnicianAvailable, getIsTechnicianAvailableResponse] =
    useLazyGetIsTechnicianAvailableQuery();

  const emptyTitle = t("No assigned requests");
  const emptyDesc = t("No assigned requests desc");

  useEffect(() => {
    setIsAvailable(getIsTechnicianAvailableResponse?.data?.isAvailable);
  }, [getIsTechnicianAvailableResponse]);

  const onDisplayModal = (el) => {
    setShowModal(true);
    setRequestId(el.inspectionId);
  };

  const onLeavePageHandler = () => {
    reset({ technicianName: null });
    setIsAvailable("");
    setShowModal(false);
  };

  const onSubmitHandler = (data, event) => {
    event.preventDefault();
    assignNewTechnician({
      inspectionId: requestId,
      userId: data?.technicianName?.id,
    })
      .unwrap()
      .then((responseData) => {
        onLeavePageHandler();
        props.showAlertHandler({
          show: true,
          message: SuccessCodes.TECHNICIAN_ASSIGNED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        return responseData;
      })
      .catch((err) => {
        onLeavePageHandler();
        props.showAlertHandler({
          show: true,
          message:
            err.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
      });
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const checkTechnicianAvailability = (data) => {
    getIsTechnicianAvailable({
      inspectionLayerId: requestId,
      technicianId: data?.id,
    });
  };

  return (
    <>
      <BaseModal
        show={showModal}
        py={"3rem"}
        px={"5rem"}
        onHide={onLeavePageHandler}
        title={`إسناد الطلب رقم ${requestId}`}
        mainContainerSx={{
          width: "55rem",
        }}
        containerSx={{
          height: "39rem",
        }}
      >
        <form
          aria-label="form"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <ReassignTechnicianModal
            isAvailable={isAvailable}
            checkTechnicianAvailability={checkTechnicianAvailability}
            techniciansData={technicnaData}
            isLoading={
              getIsTechnicianAvailableResponse.isFetching ||
              assignNewTechnicianResponse.isLoading
            }
            onHide={onLeavePageHandler}
            control={control}
            errors={{ ...errors }}
            register={register}
          />
        </form>
      </BaseModal>

      {unAssignedTechniciansData?.content?.length > 0 && (
        <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
          <BaseTableIndex
            title="بانتظار الاسناد"
            tableHeader={UnAssignedTechniciansRequestsIndex}
            content={unAssignedTechniciansData?.content || []}
            isLoading={isUnAssignedTechniciansFetching}
            dataItemKey={"inspectionId"}
            injectProps={{
              tableId: TABLE_ID.ADMIN_TECHNICIAN_REQUESTS,
            }}
            totalCount={20}
            emptyData={{
              title: emptyTitle,
              desc: emptyDesc,
              img: publicAssetsPath("images/documents2.svg"),
            }}
            withAction={{
              comp: <ViewDetails label={"إسناد الطلب"} />,
              action: onDisplayModal,
            }}
            pagination={{
              displayBy: TABLE_DISPLAY_BY,
              label: "طلب",
              onChangePaginationDataHandler:
                unAssignedOnChangePaginationDataHandler,
              totalCount: unAssignedTechniciansData?.totalElements,
            }}
          />
        </BaseCard>
      )}
    </>
  );
};

UnAssignedTechnicians.propTypes = {
  showAlertHandler: PropTypes.func,
};

export default UnAssignedTechnicians;
