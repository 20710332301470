import inspectionApi from "../../../Inspection/apis";
import { queryBuilder } from "../../../../utils/ApiHelpers";

const inspectionContractorApi = inspectionApi.injectEndpoints({
  endpoints: (builder) => ({
    getLayersTemplates: builder.query({
      query: (query) => ({
        url: `/api/inspection/inspections/layersTemplates/${query}`,
      }),
    }),
    getLayersData: builder.query({
      query: (data) => {
        const query = queryBuilder(data);

        return { url: `/api/inspection/inspections/layersTemplates?${query}` };
      },
    }),
    getCheckCertificateEligible: builder.query({
      query: (data) => {
        const query = queryBuilder(data);

        return {
          url: `/api/inspection/inspectionCertificateRequest/check?${query}`,
        };
      },
    }),
    getMaterialsData: builder.query({
      query: (data) => {
        return { url: `/api/inspection/inspectionTemplates` };
      },
    }),
    getDevicesData: builder.query({
      query: (data) => {
        const query = queryBuilder(data);

        return {
          url: `/api/inspection/inspectionTemplates/fieldDevices?${query}`,
        };
      },
    }),
    getTestsTemplates: builder.mutation({
      query: ({ body, layerId }) => ({
        url: `/api/inspection/inspections/testsTemplates/${layerId}`,
        method: "POST",
        body,
      }),
    }),
    //new getInspectionTestsDataForContractor
    getInspectionTestsDataForContractor: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/inspection/inspectionTests/v2/contractor/${inspectionLayerId}`,
      }),
      providesTags: [
        "Request",
        "C-Request-Details",
        "Contractor-Inspection-Tests",
      ],
    }),
    getPackages: builder.query({
      query: () => ({
        url: `/api/inspection/inspections/packages`,
      }),
    }),
    saveAttachment: builder.mutation({
      query: ({ body, inspectionLayerId }) => ({
        url: `/api/inspection/inspectionLayers/saveAspeltBaseAttachment?inspectionLayerId=${inspectionLayerId}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Request"],
    }),
    getAsphaltGradationLimits: builder.query({
      query: (layerTemplateId) => ({
        url: `/api/inspection/inspections/aspeltDesignGradationLimits/${layerTemplateId}`,
      }),
    }),
    // NEW
    getAsphaltReport: builder.query({
      query: (inspectionLayerId) => ({
        url: `/api/contractor/inspectionReports/aspeltReport/${inspectionLayerId}`,
      }),
      providesTags: ["Request", "Samples"],
    }),
    //old
    getOldInspectionLayer: builder.query({
      query: (request_id) => {
        return {
          url: `/api/inspection/inspectionLayers/${request_id}`,
        };
      },
      providesTags: ["C-Request-Details"],
    }),
    // new
    getContractorInspection: builder.query({
      query: (inspectionLayerId) => {
        return {
          url: `/api/inspection/inspections/v2/contractor/${inspectionLayerId}`,
        };
      },
      providesTags: ["C-Request-Details"],
    }),
    //new
    getSubTestsForContractor: builder.query({
      query: (inspectionTestId) => ({
        url: `/api/inspection/inspectionSubTests/v2/contractor/${inspectionTestId}`,
      }),
      providesTags: ["C-Request-Details"],
    }),
    inspectionCertificates: builder.mutation({
      query: (body) => ({
        url: "/api/inspection/inspectionCertificates/view",
        method: "POST",
        body: body,
      }),
      providesTags: ["C-Request-Details", "Request"],
    }),
    checkEditableForContractor: builder.query({
      query: (inspectionId) => ({
        url: `/api/inspection/inspections/v2/contractor/checkIfeCanEditable/${inspectionId}`,
      }),
      invalidatesTags: ["Edit-Stations", "Editable"],
    }),
    editStations: builder.mutation({
      query: ({ body, InspectionId }) => ({
        url: `/api/contractor/contractors/v2/FIELD/${InspectionId}/editStations`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Edit-Stations", "Editable"],
    }),
    submitCertificateRequest: builder.mutation({
      query: (body) => ({
        url: `/api/inspection/inspectionCertificateRequest/newInspectionCertificateRequest`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["C-Request-Details"],
    }),
  }),
});

export const {
  useGetSubTestsForContractorQuery,
  useGetOldInspectionLayerQuery,
  useLazyGetCheckCertificateEligibleQuery,
  useGetAsphaltReportQuery,
  useGetInspectionTestsDataForContractorQuery,
  useGetTestsTemplatesMutation,
  useGetPackagesQuery,
  useLazyGetLayersDataQuery,
  useLazyGetMaterialsDataQuery,
  useLazyGetDevicesDataQuery,
  useSaveAttachmentMutation,
  useLazyGetAsphaltGradationLimitsQuery,
  useGetContractorInspectionQuery,
  useInspectionCertificatesMutation,
  useCheckEditableForContractorQuery,
  useGetDevicesDataQuery,
  useGetMaterialsDataQuery,
  useEditStationsMutation,
  useSubmitCertificateRequestMutation,
} = inspectionContractorApi;

export default inspectionContractorApi;
