import React from "react";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { Controller } from "react-hook-form";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useTranslation } from "react-i18next";
import BaseButton from "core-ui/BaseButton/BaseButton";
import PropTypes from "prop-types";
import { SystemSettingsDuration } from "services/StaticLookup/Time";

function SystemSettingsEditForm(props) {
  const { t } = useTranslation(["dashboard"]);

  const {
    register,
    handleSubmit,
    formData,
    control,
    errors,
    isLoading,
    setShowConfirmModal,
    reset,
  } = props;

  const handleCancelClick = () => {
    props?.navigate(`/system-settings`);
  };

  const onSubmitHandler = (d, event) => {
    event.preventDefault();
    setShowConfirmModal(true);
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
    >
      <Box mb={4} className={"request-data-details"}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            فني الرقابة
          </Typography>
          <WrapperContentGrid sx={{ marginTop: 4 }} columnsWidth={27} gap={4}>
            <Box className={"request-data-details__item"}>
              <Controller
                control={control}
                name="duration"
                rules={{
                  required: {
                    value: true,
                    message: `${t("Field")} ${t("required")}`,
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <BaseDropdown
                      htmlFor="duration"
                      onChange={onChange}
                      control={control}
                      name="duration"
                      required
                      errors={errors}
                      data={SystemSettingsDuration}
                      label={"مدة الرحلة"}
                      labelStyles={{
                        fontWeight: "bold",
                        color: "grayDark.color",
                      }}
                      placeholder="مدة الرحلة"
                    />
                  );
                }}
              />
            </Box>
            <BaseInput
              htmlFor="technicianMaxRequests"
              label={"الحد الأقصى من الطلبات"}
              placeholder={t("الحد الأقصى من الطلبات")}
              type="number"
              labelStyles={{
                fontWeight: "bold",
                color: "grayDark.color",
              }}
              name="technicianMaxRequests"
              register={{
                ...register("technicianMaxRequests", {
                  required: {
                    value: true,
                    message: `${t("Field")} ${t("required")}`,
                  },
                  min: {
                    value: 1,
                    message: ` يجب علي الحد الأقصى من الطلبات ان لا يكون اقل من 1`,
                  },
                  max: {
                    value: 1000,
                    message: ` يجب علي الحد الأقصى من الطلبات ان لا يتجاوز الرقم 1000`,
                  },
                }),
              }}
              errors={errors}
            />
          </WrapperContentGrid>
        </Box>
      </Box>
      <hr />
      <Box mt={4} className={"request-data-details"}>
        <Box className={"request-data-details__wrapper"}>
          <Typography className={"request-data-details__title"}>
            المختبرات
          </Typography>
          <WrapperContentGrid sx={{ marginTop: 4 }} columnsWidth={27} gap={4}>
            <Box className={"request-data-details__item"}>
              <BaseInput
                htmlFor="laboratoryMaxRequests"
                type="number"
                label={"الحد الاعلى للطلبات المقبولة يوميًا"}
                placeholder="الحد الاعلى للطلبات المقبولة يوميًا"
                labelStyles={{
                  fontWeight: "bold",
                  color: "grayDark.color",
                }}
                name="laboratoryMaxRequests"
                register={{
                  ...register("laboratoryMaxRequests", {
                    required: {
                      value: true,
                      message: "حقل الحد الاعلى للطلبات اليومية مطلوب",
                    },
                    min: {
                      value: 1,
                      message: ` يجب علي الحد الاعلى للطلبات اليومية ان لا يكون اقل من 1`,
                    },
                    max: {
                      value: 1000,
                      message: ` يجب علي الحد الاعلى للطلبات اليومية ان لا يتجاوز الرقم 1000`,
                    },
                  }),
                }}
                errors={errors}
              />
            </Box>
          </WrapperContentGrid>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          gap: 2,
          marginTop: "10rem",
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
            mr: 1,
          }}
          isLoading={isLoading}
          variant={"secondary"}
          onClick={handleCancelClick}
        >
          {t("إلغاء")}
        </BaseButton>
        <BaseButton
          sx={{ minWidth: "12.2rem" }}
          type={"submit"}
          isLoading={isLoading}
        >
          {t("حفظ")}
        </BaseButton>
      </Box>
    </form>
  );
}

SystemSettingsEditForm.propTypes = {
  register: PropTypes.func,
  handleSubmit: PropTypes.func,
  watch: PropTypes.func,
  navigate: PropTypes.func,
  reset: PropTypes.func,
  formData: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
  dirtyFields: PropTypes.object,
  isLoading: PropTypes.bool,
  setShowConfirmModal: PropTypes.func,
};

export default SystemSettingsEditForm;
