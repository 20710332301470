import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { stationInputsActions } from "store/staions-inputs/station-inputs";
import { useTranslation } from "react-i18next";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import {
  RowNumber,
  LayerNumberInput,
  StationPointInput,
  LayerLength,
  LayerTest,
} from "./components";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import messages from "services/AlertMessages/messages";
import PropTypes from "prop-types";

function StationsTable(props) {
  const {
    register,
    errors,
    setValue,
    formData,
    unregister,
    clearErrors,
    setError,
    raiseNewRequestData,
    control,
    layersData,
    sm,
  } = props;

  const { t } = useTranslation(["dashboard"]);
  const dispatch = useDispatch();
  const [lengthErr, setlengthErr] = useState([]);
  const [stations, setStations] = useState(props.stations || []);

  const isAsphaltMaterial =
    raiseNewRequestData?.material?.serviceName?.includes("Asphalt");

  const [stationsHasErrors, setStationsHasErrors] = useState([]);

  const defaultstationNumber = useSelector(
    (state) => state.stationInputs.stations
  );

  useEffect(() => {
    setStations(defaultstationNumber);
  }, [defaultstationNumber]);

  const stationsHeaders = useMemo(
    () => [
      {
        serverKey: "id",
        name: "",
        hasComponent: {
          comp: <RowNumber />,
        },
      },
      {
        serverKey: "startPoint",
        name: "نقطة البداية",
        hasComponent: {
          extraProps: {
            placeholder: "0+015",
            name: "from",
            stationsHasErrors,
            setStationsHasErrors,
          },
          comp: <StationPointInput />,
        },
      },
      {
        serverKey: "endPoint",
        name: "نقطة النهاية",
        hasComponent: {
          extraProps: {
            placeholder: "1+300",
            name: "to",
            stationsHasErrors,
            setStationsHasErrors,
          },
          comp: <StationPointInput />,
        },
      },
      !isAsphaltMaterial && {
        serverKey: "layerNumber",
        name: "رقم الطبقة",
        hasComponent: {
          comp: <LayerNumberInput />,
        },
      },
      {
        serverKey: "layerTest",
        name: "اختبار مواد الأعمال المدنية",
        hasComponent: {
          extraProps: {
            errors,
            raiseNewRequestData,
            control,
            editableLayersTemplates: layersData,
          },
          comp: <LayerTest />,
        },
      },
      {
        serverKey: "length",
        name: "طول المقطع",
        hasComponent: {
          extraProps: { lengthErr, setlengthErr, clearErrors, setError },
          comp: <LayerLength />,
        },
      },
    ],
    [isAsphaltMaterial, layersData, raiseNewRequestData]
  );

  //
  useEffect(() => {
    if (Array.isArray(props.stations) && props.stations.length > 0) {
      const initialStations = props.stations?.map((station) => ({
        ...station,
        removable: false,
      }));
      setStations(initialStations);
    }
  }, []);

  const handleAddRow = () => {
    setStations((prevStations) => {
      const newId =
        prevStations.length > 0
          ? Math.max(...prevStations.map((s) => s.id)) + 1
          : 1;
      const newStation = {
        id: newId,
        startPoint: "",
        endPoint: "",
        layerNumber: "",
        layerTest: "",
        length: "",
      };
      const updatedStations = [...prevStations, newStation];
      dispatch(stationInputsActions.setStations(updatedStations));
      return updatedStations;
    });
  };

  const handleRemoveRow = ({ id }) => {
    if (id === 0) return;
    setStations((prevStations) => {
      const updatedStations = [...prevStations];
      const itemToRemoveIndex = updatedStations.findIndex(
        (el) => el?.id === id
      );

      if (stationsHasErrors?.length > 0 && stationsHasErrors.includes(id)) {
        setStationsHasErrors((prevStations) => {
          const updatedEroorStations = [...prevStations];

          updatedEroorStations.splice(prevStations.indexOf(id), 1);
          return updatedEroorStations;
        });
      }

      unregister(`station-${id}-from`);
      unregister(`station-${id}-to`);
      unregister(`station-${id}-layerNumber`);
      unregister(`station-${id}-layerTest`);
      unregister(`station-${id}-length`);

      updatedStations.splice(itemToRemoveIndex, 1);
      dispatch(stationInputsActions.setStations(updatedStations));
      return updatedStations;
    });
  };

  const tableColumnsGrid = isAsphaltMaterial
    ? "0.5fr 1.5fr 1.5fr 0fr 3fr 1.5fr 0.5fr"
    : "0.5fr 1.5fr 1.5fr 1.5fr 3fr 1.5fr 0.5fr";

  return (
    <Box mt={5} sx={{ display: "flex", flexDirection: "column" }}>
      <BaseTableDoubleCard
        unset={true}
        columnsWidth={sm ? "" : tableColumnsGrid}
        title={"نقاط الـ Station"}
        description={
          "يرجى كتابة نقطة البداية و النهاية لكل Station ( مثال نقطة البداية 100+1 و نقطة النهاية 900+1 ) مع إضافة رقم الطبقة واختبار مواد الأعمال المدنية."
        }
        injectProps={{
          errors,
          register,
          formData,
          setValue,
          t,
        }}
        tableMainWrapperSx={{ mt: 2 }}
        tableHeader={stationsHeaders}
        content={stations || []}
        doubleLayer={(element) => {
          return {
            visibility: false,
            specificRowId: [...stationsHasErrors, ...lengthErr],
            // NNED TO REFACTOR THIS
            component: (
              <Box>
                {lengthErr?.includes(element?.id) && (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    {
                      messages[
                        ErrorCodes.STATIONS_STARTPOINT_IS_BIGGER_THAN_ENDPOINT
                      ]
                    }
                  </Typography>
                )}
                {stationsHasErrors?.includes(element?.id) && (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    {messages[ErrorCodes.STATIONS_STARTPOINT_IS_OVERLAPPING]}
                  </Typography>
                )}
              </Box>
            ),
          };
        }}
        withSpecificAction={(element, triggeredElements) => {
          return {
            ignoreState: true,
            condition: element?.elementIndex !== 0,
            component: (
              <CloseIcon
                fontSize="large"
                sx={{
                  color: "primary.colorSoft5",
                  fontWeight: "bold",
                  cursor: "grab",
                  mt: 3,
                  mr: 3,
                }}
              />
            ),
            action: handleRemoveRow,
          };
        }}
        withActionRow={
          stations.length < 5 && {
            actionComponent: (
              <Box
                sx={{
                  display: "flex",
                  flexAlign: "center",
                  justifyContent: "center",
                }}
              >
                <AddIcon
                  fontSize="large"
                  sx={{
                    fontWeight: 500,
                    color: "primary.colorSoft13",
                  }}
                />
                <Typography
                  onClick={handleAddRow}
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: 500,
                    color: "primary.colorSoft13",
                    cursor: "grab",
                  }}
                >
                  إضافة نقطة Station جديدة
                </Typography>
              </Box>
            ),
          }
        }
      />
    </Box>
  );
}

StationsTable.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  formData: PropTypes.object,
  unregister: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  raiseNewRequestData: PropTypes.object,
  control: PropTypes.object,
  layersData: PropTypes.array,
  stations: PropTypes.array,
  sm: PropTypes.bool,
};

export default StationsTable;
