import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import classes from "./BaseTimelineResponsive.module.scss";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import PropTypes from "prop-types";
import BaseCard from "core-ui/BaseCard/BaseCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const BaseTimelineResponsive = ({
  activeStageIndex = 0,
  completedStagesIndexes = [],
  stages = [],
}) => {
  const checkupTimelineRef = useRef();

  const [showSteps, setShowSteps] = useState(false);

  useEffect(() => {
    if (showSteps) {
      const setLabelPositionByLength = () => {
        const labelList = Array.from(
          checkupTimelineRef.current.querySelectorAll("p")
        );
        labelList.forEach((label) => {
          const currentLabelLength = label.textContent.length;
          if (currentLabelLength <= 10) {
            label.classList.add(classes["checkup-timeline__label--sm"]);
          }
        });
      };
      setLabelPositionByLength();
    }
  }, [showSteps]);

  const triggerVisibility = () => {
    setShowSteps((prevState) => !prevState);
  };

  const progressRef = useRef();
  const progress = ((activeStageIndex + 1) / stages.length) * 100;

  const timelineContentDynamic = stages.map((content, idx) => {
    const isActiveStage = activeStageIndex === idx;

    return (
      <Fragment key={content.name}>
        <div className={`${classes["checkup-timeline__circle"]}`}>
          <span>{idx + 1}</span>
          <span className={`${classes["checkup-timeline__icon-done"]}`}>
            <DoneIcon />
          </span>
        </div>
        <Typography
          className={`${classes["checkup-timeline__label"]}
         ${isActiveStage ? classes["checkup-timeline__label--active"] : ""}
        `}
        >
          {content.name}
        </Typography>
      </Fragment>
    );
  });

  return (
    <BaseCard
      px={"2.5rem"}
      sx={{
        padding: "2rem",
      }}
    >
      <Box sx={{ direction: "rtl" }}>
        <Box onClick={() => triggerVisibility()}>
          <Typography className={classes["progress-label"]}>
            {showSteps ? (
              <KeyboardArrowUpIcon
                sx={{ marginLeft: "1rem" }}
                fontSize="medium"
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ marginLeft: "1rem" }}
                fontSize="medium"
              />
            )}
            الخطوة {activeStageIndex + 1} من {stages.length}
          </Typography>
          <Box className={classes["uploader-progress__bar"]}>
            <Typography
              component="span"
              width={`${progress}%`}
              className={classes["uploader-progress__bar--process"]}
              ref={progressRef}
            ></Typography>
          </Box>
        </Box>

        {showSteps && (
          <Box
            mt={"2rem"}
            display={"flex"}
            flexDirection={"column"}
            gap={"1rem"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            className={`${classes["checkup-timeline"]}`}
            ref={checkupTimelineRef}
          >
            {timelineContentDynamic.map((content, idx) => {
              const isActiveStage = activeStageIndex === idx;
              const areDoneStages = completedStagesIndexes.includes(idx);
              return (
                <Box
                  key={crypto.randomUUID()}
                  className={`
                ${classes["checkup-timeline__wrapper"]}
                ${
                  isActiveStage
                    ? classes["checkup-timeline__wrapper--active"]
                    : ""
                }
                ${
                  areDoneStages
                    ? classes["checkup-timeline__wrapper--done"]
                    : ""
                }
                `}
                >
                  {content}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </BaseCard>
  );
};

BaseTimelineResponsive.propTypes = {
  activeStageIndex: PropTypes.number,
  completedStagesIndexes: PropTypes.array,
  stages: PropTypes.array,
};

export default BaseTimelineResponsive;
