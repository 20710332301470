import FieldExaminationEditRequestConfirmation from "components/TableUtilitiesComponents/TableDynamicFieldComponents/FieldExaminationEditRequestConfirmation/FieldExaminationEditRequestConfirmation";

export const setDoubleLayer = (
  element,
  editedStations,
  data,
  displayTechnicianConfirmation
) => {
  return {
    visibility: true,
    component: () => (
      <FieldExaminationEditRequestConfirmation
        scoresList={data?.stationEdits}
        editedStations={editedStations}
        displayTechnicianConfirmation={displayTechnicianConfirmation}
        element={element}
      />
    ),
  };
};
