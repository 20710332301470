import { useState } from "react";
import { Box } from "@mui/material";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import FieldTestLabDoubleLayer from "components/TableUtilitiesComponents/TableDoubleLayerComponents/FieldTestLabDoubleLayer";
import { assignedRequestTestsFeild } from "services/StaticLookup/TableHeader/TableHeader";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";
import SmartDoubleCardTable from "components/SmartDoubleCardTable/SmartDoubleCardTable";

const RaiseResultsFieldTest = (props) => {
  const {
    isSubmitted,
    _tableContent,
    enableDrafting,
    isDraftedNotSubmitted,
    isRequestDetailsFetching,
    inspectionLayerDTO,
    injectProps: { allowRaiseResult, setValue },
  } = props;

  const [stationInputs, setStationInputs] = useState({});

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayerDTO?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const tableProps = {
    title: "يرجى إدخال نتائج الإختبارات لنقاط الـ station التالية",
    description: " ",
    tableHeader: assignedRequestTestsFeild({ isAsphalt }),
    isLoading: isRequestDetailsFetching,
    content: _tableContent.content,
    injectProps: {
      ...props.injectProps,
      allowRaiseResult,
      isDraftedNotSubmitted,
      isSubmitted,
      enableDrafting,
    },
    doubleLayer: () => ({
      visibility: true,
      component: (element) => (
        <FieldTestLabDoubleLayer
          setValue={setValue}
          ignoreStackingContext
          isSubmitted={isSubmitted}
          stationInputs={stationInputs}
          enableDrafting={enableDrafting}
          setStationInputs={setStationInputs}
          isDraftedNotSubmitted={isDraftedNotSubmitted}
        />
      ),
    }),
  };

  return (
    <Box sx={{ mt: 5 }}>
      <SmartDoubleCardTable tableProps={tableProps} />
    </Box>
  );
};

RaiseResultsFieldTest.propTypes = {
  isSubmitted: PropTypes.bool,
  _tableContent: PropTypes.object,
  injectProps: PropTypes.object,
  enableDrafting: PropTypes.bool,
  isDraftedNotSubmitted: PropTypes.bool,
  isRequestDetailsFetching: PropTypes.bool,
};

export default RaiseResultsFieldTest;
