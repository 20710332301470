import { useEffect, useState } from "react";
import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { SystemSettingsEditBreadcrumb } from "services/StaticLookup/Breadcrumb";
import SystemSettingsEditForm from "./SystemSettingsEditForm";
import { _void } from "utils/Objects/Objects";
import { useForm } from "react-hook-form";
import { AlertTypes } from "constants/AlertTypes";
import SuccessCodes from "services/StaticLookup/ServerCodes/SuccessCodes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import BaseModal from "core-ui/BaseModal/BaseModal";
import ConfirmationModal from "components/AllModalContent/ConfirmationModal/ConfirmationModal";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import useAlert from "hooks/useAlert";
import { useTranslation } from "react-i18next";
import {
  useEditConfigurationListMutation,
  useGetConfigurationListQuery,
} from "modules/Admin/apis/configuration-apis/configuration-apis";
import { SystemConfigurationCodes } from "constants/Configuration";
import { SystemSettingsDuration } from "services/StaticLookup/Time";
import { _scrollToTop } from "utils/DOM/DOM";
import { removeLeadingZeros } from "utils/Strings/Strings";

let navigationTimer = null;

const SystemSettingsEdit = () => {
  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { alert, showAlertHandler, hideAlertHandler } = useAlert();

  const { data: configurationData } = useGetConfigurationListQuery(undefined, {
    refetchOnFocus: true,
  });

  const [updateSystemConfigurations, updateSystemConfigurationsResponse] =
    useEditConfigurationListMutation();

  const isSavingData = updateSystemConfigurationsResponse.isLoading;

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
  });

  const formData = watch();

  const onLeavePageHandler = () => {
    setShowConfirmModal(false);
  };

  const onSubmitHandler = () => {
    const idealData = configurationData?.map((config) => {
      if (
        config.configCode === SystemConfigurationCodes.MAXIMUM_REQUESTS_PER_TRIP
      ) {
        return {
          ...config,
          newValue: removeLeadingZeros(formData.technicianMaxRequests),
        };
      } else if (config.configCode === SystemConfigurationCodes.TRIP_DURATION) {
        return {
          ...config,
          newValue: formData.duration.value,
        };
      } else if (
        config.configCode ===
        SystemConfigurationCodes.MAXIMUM_REQUESTS_PER_LABORATORY
      ) {
        return {
          ...config,
          newValue: removeLeadingZeros(formData.laboratoryMaxRequests),
        };
      } else return { ...config, newValue: "" };
    });

    updateSystemConfigurations({ configurations: idealData })
      .unwrap()
      .then(() => {
        showAlertHandler({
          show: true,
          message: SuccessCodes.CONFIGURATIONS_UPDATED_SUCCESSFULLY,
          type: AlertTypes.SUCCESS,
        });
        setShowConfirmModal(false);
        navigationTimer = setTimeout(() => {
          navigate(`/system-settings`);
        }, 3000);
      })
      .catch((err) => {
        showAlertHandler({
          show: true,
          message:
            err.data?.errorCode === ErrorCodes.TECHNICIAN_HAS_ACTIVE_ASSIGNMENTS
              ? ErrorCodes.TECHNICIAN_CANT_UPDATE
              : ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
          type: AlertTypes.ERROR,
        });
        setShowConfirmModal(false);
      })
      .finally(() => {
        _scrollToTop();
      });
  };

  useEffect(() => {
    if (configurationData) {
      const technicianTripDuration = configurationData.find(
        (d) => d?.configCode === SystemConfigurationCodes.TRIP_DURATION
      );
      const technicianMaxRequests = configurationData.find(
        (d) =>
          d?.configCode === SystemConfigurationCodes.MAXIMUM_REQUESTS_PER_TRIP
      );
      const laboratoryMaxRequests = configurationData.find(
        (d) =>
          d?.configCode ===
          SystemConfigurationCodes.MAXIMUM_REQUESTS_PER_LABORATORY
      );

      reset({
        technicianMaxRequests: technicianMaxRequests?.newValue,
        laboratoryMaxRequests: laboratoryMaxRequests?.newValue,
        duration:
          SystemSettingsDuration.find(
            (d) => d.value.toString() == technicianTripDuration?.newValue
          ) || null,
      });
    }
  }, [configurationData, reset]);

  useEffect(() => {
    return () => clearTimeout(navigationTimer);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`تعديل ${t("system settings")}`}</title>
      </Helmet>

      <DashboardIntro
        title={t("system settings")}
        description={"يمكنك تعديل إعدادات النظام"}
        action={
          <BaseAlert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            destroy={hideAlertHandler}
            autoClose={10}
          />
        }
        breadcrumbData={SystemSettingsEditBreadcrumb()}
      />

      <BaseModal
        show={showConfirmModal}
        title="تأكيد تحديث إعدادات النظام"
        onHide={onLeavePageHandler}
        closeIcon
        px={"4rem"}
        containerSx={{
          minHeight: "25rem",
        }}
      >
        <ConfirmationModal
          onConfirm={onSubmitHandler}
          onHide={onLeavePageHandler}
          confirmMessage="هل أنت متأكد من تحديث إعدادات النظام؟"
        />
      </BaseModal>

      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        <SystemSettingsEditForm
          navigate={navigate}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields}
          isSavingData={isSavingData}
          setShowConfirmModal={setShowConfirmModal}
          formData={formData}
          isLoading={updateSystemConfigurationsResponse.isLoading}
          watch={watch}
          reset={reset}
        />
      </BaseCard>
    </>
  );
};

export default SystemSettingsEdit;
