import { Box, Typography } from "@mui/material";
import BaseStatus from "core-ui/BaseStatus/BaseStatus";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { defaultEmptyValue } from "constants/Empty";
import BaseTypeTag from "core-ui/BaseTypeTag/BaseTypeTag";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import { publicAssetsPath } from "utils/Paths/Paths";
import DisplayRequestAppointmentDate from "components/TableUtilitiesComponents/TableDynamicFieldComponents/DisplayRequestAppointmentDate/DisplayRequestAppointmentDate";
import BaseModal from "core-ui/BaseModal/BaseModal";
import StationsDataModal from "components/AllModalContent/StationsDataModal/StationsDataModal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { BaseTableCard } from "core-ui/BaseTable/Utilities";
import {
  adminFieldRequestDetails,
  adminFieldRequestDetailsVS,
} from "services/StaticLookup/TableHeader/TableHeader";
import useGetStationsWithTestData from "hooks/useGetStationsWithTestData";
import useGetVirtualStations from "hooks/useGetVirtualStations";
import PropTypes from "prop-types";
import {
  inspectionLayerFlagChecker,
  InspectionLayerFlags,
} from "constants/Inspection";

function LabRequestDetailsField({ ...props }) {
  const params = useParams();

  const inspection = props?.inspection;

  const { stations } = useGetStationsWithTestData({ id: params.id });

  const [showStation, setShowStation] = useState(false);

  const onLeavePageHandler = () => setShowStation(false);

  const { tableContent, hasVirtual } = useGetVirtualStations(stations);

  const inspectionLayer = props.inspection?.inspectionLayersList[0];

  const isAsphalt = inspectionLayerFlagChecker(
    inspectionLayer?.flags,
    InspectionLayerFlags.IS_ASPHALT
  );

  const tableHeader = hasVirtual
    ? adminFieldRequestDetailsVS({ isAsphalt })
    : adminFieldRequestDetails({ isAsphalt });

  return inspection ? (
    <Box>
      <BaseModal
        show={showStation && stations.length !== 0}
        py={"6rem"}
        px={"4rem"}
        onHide={onLeavePageHandler}
      >
        <StationsDataModal onHide={onLeavePageHandler} stations={stations} />
      </BaseModal>

      <Box className={"request-data-details"} mt={4} ml={4} mr={4}>
        <Box className={"request-data-details__wrapper"} mb={6}>
          <Typography className={"request-data-details__title"}>
            تفاصيل الطلب
          </Typography>
          <WrapperContentGrid gap={4} sx={{ marginTop: 6 }}>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اسم المختبر
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labName || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                رقم الطلب
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.id || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                اختبار مواد الأعمال المدنية{" "}
              </Typography>
              <Typography
                className={"request-data-details__value"}
                sx={{ direction: "rtl" }}
              >
                {inspection?.name || defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                حالة الطلب
              </Typography>
              <BaseStatus
                status={inspection?.status}
                dataId={props.dataId}
                testType={inspection.testType}
                appointment={inspection.appointment}
              />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نوع الفحص
              </Typography>
              <BaseTypeTag type={inspection?.testType} />
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                موعد الفحص
              </Typography>
              <Typography className={"request-data-details__value"}>
                <DisplayRequestAppointmentDate
                  appointmentDate={props.appointmentData?.scheduledDate}
                  scheduledAppointment={props.appointmentData?.scheduledTime}
                />
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                المدة المتوقعة
              </Typography>
              <Typography className={"request-data-details__value"}>
                {inspection?.labEstimatedTime
                  ? `${inspection?.labEstimatedTime} أيام`
                  : defaultEmptyValue}
              </Typography>
            </Box>
            <Box className={"request-data-details__item"}>
              <Typography className={"request-data-details__label"}>
                نقاط الـ Stations
              </Typography>
              <Typography
                className={"request-data-details__value"}
                onClick={() => setShowStation(true)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  cursor: "grab",
                }}
              >
                نقاط الـ Stations{" "}
                <img
                  src={publicAssetsPath("icons/popout-svgrepo.svg")}
                  width="auto"
                  alt="stations-popout"
                />
              </Typography>
            </Box>
          </WrapperContentGrid>
        </Box>
        <hr />
        <Box className={"request-data-details__wrapper"} mt={6}>
          <Typography className={"request-data-details__title"}>
            الاختبارات
          </Typography>
          <Box>
            <BaseTableCard
              tableSx={{
                td: {
                  borderBottom: "0.1rem solid #EDEFF2",
                },
              }}
              tableHeader={tableHeader}
              content={tableContent}
              isLoading={props.isFetching}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <BaseLoader />
  );
}

LabRequestDetailsField.propTypes = {
  dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stations: PropTypes.array,
  appointmentData: PropTypes.object,
  questionnaireRecoord: PropTypes.object,
  isFetching: PropTypes.bool,
  inspection: PropTypes.object,
  testsData: PropTypes.array,
  layersData: PropTypes.array,
};

export default LabRequestDetailsField;
