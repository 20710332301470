import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import { TABLE_DISPLAY_BY, TABLE_ID } from "constants/Table";
import BaseCard from "core-ui/BaseCard/BaseCard";
import Search from "components/Search/Search";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { adminUsersManagement } from "services/StaticLookup/TableHeader/TableHeader";
import { publicAssetsPath } from "utils/Paths/Paths";
import useBaseTablePagination from "hooks/useBaseTablePagination";
import {
  useGetUserRolesQuery,
  useLazyGetUsersQuery,
} from "modules/Admin/apis/users-management-apis/users-management-apis";
import { UsersJobs } from "services/StaticLookup/UsersJobs";
import { useEffect, useState } from "react";
import { isContentReady } from "utils/Array/Arrays";
import { BaseTableIndex } from "core-ui/BaseTable/Utilities";
import { UsersTypesEnum } from "constants/UsersTypes";
import AddUserButtonAction from "./AddUserButtonAction";

const AdminUsers = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const [userName, setUserName] = useState("");
  const [userJob, setUserJob] = useState("");

  const { page, pageSize, onChangePaginationDataHandler } =
    useBaseTablePagination();

  const [getUsersData, getUsersDataResponse] = useLazyGetUsersQuery();

  const { data: userRoles, isLoading: isUserRolesLoading } =
    useGetUserRolesQuery();

  const isNoSearchData =
    (userName || userJob) &&
    !isContentReady(getUsersDataResponse?.data?.content);

  const emptyTitle = isNoSearchData ? t("No Results") : t("No Users");
  const emptyDesc = isNoSearchData ? t("No Results Desc") : t("No Users Desc");

  useEffect(() => {
    let timer = setTimeout(() => {
      const userType = userJob.type?.toLowerCase();

      const query = {
        page,
        size: pageSize,
        sortId: "id,desc",
        search: userName || "",
        type: userJob.type || "",
      };
      if (userType !== UsersTypesEnum.LABORATORY) {
        query.role = userJob.role || "";
      }
      getUsersData(query);
    }, 500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, pageSize, userName, userJob]);

  const getUsername = (username) => {
    setUserName(username);
  };
  const getUserJob = (userJob) => {
    setUserJob(userJob);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`إدارة المستخدمين`}</title>
      </Helmet>

      <DashboardIntro
        title={t("users management")}
        description={t("تمكنك هذه الصفحة من إضافة وإستعراض معلومات المستخدمين")}
        action={<AddUserButtonAction />}
      />

      <Search
        getSearchValue={getUsername}
        getDropdownValue={getUserJob}
        input={{
          type: "text",
          placeholder: "يمكنك البحث بالاسم أو البريد الإلكتروني",
        }}
        dropdown={{
          label: "الفرز حسب الوظيفة",
          placeholder: "حدد الوظيفة",
          data: userRoles,
          isLoading: isUserRolesLoading,
        }}
      />

      <BaseCard py={"2.5rem"} px={"2.5rem"} mt={6}>
        <BaseTableIndex
          tableHeader={adminUsersManagement}
          content={getUsersDataResponse.data?.content || []}
          isLoading={getUsersDataResponse.isFetching}
          emptyData={{
            title: t(emptyTitle),
            desc: t(emptyDesc),
            img: publicAssetsPath("images/documents2.svg"),
          }}
          injectProps={{
            tableId: TABLE_ID.ADMIN_USERS,
          }}
          withDetails={{
            paramsKey: "id",
            transferData: true,
            to: (id) => `/users-management/${id}`,
          }}
          pagination={{
            displayBy: TABLE_DISPLAY_BY,
            label: "مستخدم",
            onChangePaginationDataHandler,
            totalCount: getUsersDataResponse.data?.totalElements,
          }}
        />
      </BaseCard>
    </>
  );
};

export default AdminUsers;
