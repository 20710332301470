import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LabAddLicenseDetails from "./Lab/LabAddLicenseDetails";
import FieldAddLicenseDetails from "./Field/FieldAddLicenseDetails";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "hooks/useAlert";
import { useGetContractorLicenseMutation } from "modules/Contractor/apis/licenses-apis/licenses-api";
import useBaseDropdown from "hooks/useBaseDropdown";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import { AlertTypes } from "constants/AlertTypes";
import { SELF_SUPERVISION, UsersRolesEnum } from "constants/UsersTypes";
import { licenseYearsData } from "services/StaticLookup/LicensesYears";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { TestTypes } from "constants/TestTypes";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const AddLicenseDetailsIndex = (props) => {
  const { t } = useTranslation(["auth", "dashboard", "common"]);

  const { raiseNewRequestData } = useSelector((store) => store.contractor);

  const currentStage =
    raiseNewRequestData?.[`stage-${props.timeline.currentStage}-formData`];

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    unregister,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...currentStage,
      requestTypeId: UsersRolesEnum.CONSULTANT,
    },
  });

  const formData = watch();

  const location = useLocation();

  const selectedLicenseData = location?.state?.selectedLicenseData;

  const consultantName = watch("consultantName");

  const requestTypeId = watch("requestTypeId");

  const [licenseErrorMessage, setLicenseErrorMessage] = useState(null);

  const [isDisabledRadio, setIsDisabledRadio] = useState(false);

  const [userHasInteracted, setUserHasInteracted] = useState(false);

  const dispatch = useDispatch();

  const isLab = raiseNewRequestData.testType === TestTypes.LAB_TEST;

  const isField = raiseNewRequestData.testType === TestTypes.FIELD_TEST;

  const { alert, showAlertHandler } = useAlert();

  // 1. API call for getting "Contractor License Data"
  const [getContractorLicense, contractorLicenseResponse] =
    useGetContractorLicenseMutation();

  const isSearchBtnDisabled =
    !formData.licenseNumber ||
    !formData.licenseYear ||
    contractorLicenseResponse.isLoading;

  const getContractorLicenseHandler = (licenseNumber, licenseYear) => {
    getContractorLicense({
      licenseNumber: decodeURIComponent(
        licenseNumber || formData.licenseNumber
      ),
      licenseYear: licenseYear || formData.licenseYear?.name,
    })
      .unwrap()
      .then((responseData) => {
        setLicenseErrorMessage(null);
        if (responseData.certificateIssued) {
          throw new Error(ErrorCodes.ALREADY_CERTIFICATED_LICENSE);
        }
        if (responseData.certificateRequestPending) {
          throw new Error(ErrorCodes.CERTIFICATED_LICENSE_PENDING);
        }
        dispatch(
          contractorActions.saveRaiseNewRequestData({
            data: { licenseData: responseData, licenseDataError: null },
          })
        );
      })
      .catch((err) => {
        let errorCode = "";
        if (err.data) {
          errorCode = err.data.errorCode;
        } else {
          errorCode = err.message;
        }

        setLicenseErrorMessage(errorCode);
        showAlertHandler({
          show: true,
          message: errorCode,
          type: AlertTypes.ERROR,
        });
        dispatch(
          contractorActions.saveRaiseNewRequestData({
            data: { licenseData: null, licenseDataError: errorCode },
          })
        );
      });
  };

  const { dropdownRef, initialMainDropdownData, onCachedValueChange } =
    useBaseDropdown({
      pickup: false,
      data: licenseYearsData(),
      name: "licenseYear",
      errors: { ...errors },
      label: t("سنة إصدار الرخصة"),
      placeholder: "حدد السنة",
    });

  useEffect(() => {
    const { licenseData } = raiseNewRequestData || {};
    const { consultant, hasPendingRequest, isSelfSupervision } =
      licenseData || {};

    // Case 1: If there's a consultant or a pending request, disable the radio and set to CONSULTANT.
    if (consultant || hasPendingRequest) {
      setValue("requestTypeId", UsersRolesEnum.CONSULTANT);
      setIsDisabledRadio(true);
    }
    // Case 2: If isSelfSupervision is true and consultantName is not filled, disable the radio but set to SELF_SUPERVISION.
    else if (isSelfSupervision) {
      setValue("requestTypeId", SELF_SUPERVISION);
      setIsDisabledRadio(true);
    }
    // Case 3: Enable the radio for other conditions, allowing the user to choose.
    else {
      setIsDisabledRadio(false);
      // Only set the default value if the user hasn't interacted yet.
      // This might require tracking user interaction as previously described.
    }
  }, [raiseNewRequestData, setValue, consultantName]);

  const onSubmitHandler = (data, event) => {
    event.preventDefault();

    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          [`stage-${props.timeline.currentStage}-formData`]: formData,
        },
      })
    );

    props.timeline.goNext();
  };

  const onErrorHandler = (errors, event) => {
    event.preventDefault();
  };

  const sharedProps = {
    ...props,
    selectedLicenseData,
    control,
    register,
    setValue,
    formData,
    errors,
    unregister,
    requestTypeId,
    licenseErrorMessage,
    isDisabledRadio,
    userHasInteracted,
    dispatch,
    raiseNewRequestData,
    timeline: props.timeline,
    alert,
    showAlertHandler,
    getContractorLicense,
    contractorLicenseResponse,
    isSearchBtnDisabled,
    getContractorLicenseHandler,
    dropdownRef,
    initialMainDropdownData,
    onCachedValueChange,
    setIsDisabledRadio,
    currentStage,
  };

  const render = () => {
    if (isField) {
      return (
        <FieldAddLicenseDetails
          setUserHasInteracted={setUserHasInteracted}
          {...sharedProps}
        />
      );
    } else return <BaseLoader />;
  };

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
    >
      {isLab ? <LabAddLicenseDetails {...sharedProps} /> : render()}
    </form>
  );
};

AddLicenseDetailsIndex.propTypes = {
  timeline: PropTypes.object,
};

export default AddLicenseDetailsIndex;
