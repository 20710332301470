import React from "react";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import {
  RowNumber,
  LayerNumberInput,
  StationPointInput,
  LayerLength,
  LayerTest,
} from "../StationsTable/components";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import messages from "services/AlertMessages/messages";
import PropTypes from "prop-types";
import useStationsTable from "hooks/useStationsTable";
import ResponsiveTable from "../../../../../../../core-ui/BaseTable/BaseTableResponsive/BaseTableResponsive";

function StationsTableResponsive(props) {
  const {
    errors,
    unregister,
    clearErrors,
    setError,
    raiseNewRequestData,
    control,
    layersData,
  } = props;

  const { t } = useTranslation(["dashboard"]);

  const isAsphaltMaterial =
    raiseNewRequestData?.material?.serviceName?.includes("Asphalt");

  const {
    handleAddRow,
    handleRemoveRow,
    stations,
    setStationsHasErrors,
    stationsHasErrors,
    lengthErr,
    setlengthErr,
    isExceededTheLimit,
  } = useStationsTable({
    unregister,
    defaultStations: props.stations,
  });

  const tableHeader = [
    {
      serverKey: "id",
      header: () => "",
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <RowNumber
          elementIndex={index}
          sx={{ margin: 0, padding: 0, marginBottom: 1 }}
        />
      ),
    },
    {
      serverKey: "startPoint",
      header: () => "نقطة البداية",
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <StationPointInput
          t={t}
          placeholder="0+015"
          name="from"
          stationsHasErrors={stationsHasErrors}
          setStationsHasErrors={setStationsHasErrors}
          inputStyles={{ width: "100%" }}
          id={dataItem.id}
          {...props}
        />
      ),
    },
    {
      serverKey: "endPoint",
      header: () => "نقطة النهاية",
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <StationPointInput
          t={t}
          placeholder="1+300"
          name="to"
          stationsHasErrors={stationsHasErrors}
          setStationsHasErrors={setStationsHasErrors}
          inputStyles={{ width: "100%" }}
          id={dataItem.id}
          {...props}
        />
      ),
    },
    !isAsphaltMaterial && {
      serverKey: "layerNumber",
      header: () => "رقم الطبقة",
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <LayerNumberInput
          t={t}
          {...props}
          inputStyles={{ width: "100%" }}
          id={dataItem.id}
        />
      ),
    },
    {
      serverKey: "layerTest",
      header: () => "اختبار مواد الأعمال المدنية",
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <LayerTest
          errors={errors}
          raiseNewRequestData={raiseNewRequestData}
          control={control}
          editableLayersTemplates={layersData}
          id={dataItem.id}
          {...props}
        />
      ),
    },
    {
      serverKey: "length",
      header: () => "طول المقطع",
      value: ({ headerItem, dataItem, index, ...rest }) => (
        <LayerLength
          lengthErr={lengthErr}
          setlengthErr={setlengthErr}
          clearErrors={clearErrors}
          setError={setError}
          id={dataItem.id}
          {...props}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <ResponsiveTable
      tableHeader={tableHeader}
      title={"نقاط الـ Station"}
      description={
        "يرجى كتابة نقطة البداية و النهاية لكل Station ( مثال نقطة البداية 100+1 و نقطة النهاية 900+1 ) مع إضافة رقم الطبقة واختبار مواد الأعمال المدنية."
      }
      content={stations}
      mainWrapperSx={props.mainWrapperSx}
      showRemoveButtonFromIndex={1}
      onRemove={handleRemoveRow}
      helperMessageComponent={(dataItem) => (
        <Box>
          {lengthErr?.includes(dataItem?.id) && (
            <Typography
              sx={{
                color: "red",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              {messages[ErrorCodes.STATIONS_STARTPOINT_IS_BIGGER_THAN_ENDPOINT]}
            </Typography>
          )}
          {stationsHasErrors?.includes(dataItem?.id) && (
            <Typography
              sx={{
                color: "red",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              {messages[ErrorCodes.STATIONS_STARTPOINT_IS_OVERLAPPING]}
            </Typography>
          )}
        </Box>
      )}
      appendComponent={
        isExceededTheLimit ? null : (
          <Box
            sx={{
              display: "flex",
              flexAlign: "center",
              justifyContent: "center",
              mt: 2.5,
            }}
          >
            <AddIcon
              fontSize="large"
              sx={{
                fontWeight: 500,
                color: "primary.colorSoft13",
              }}
            />
            <Typography
              onClick={handleAddRow}
              sx={{
                fontSize: "1.4rem",
                fontWeight: 500,
                color: "primary.colorSoft13",
                cursor: "grab",
              }}
            >
              إضافة نقطة Station جديدة
            </Typography>
          </Box>
        )
      }
    />
  );
}

StationsTableResponsive.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  formData: PropTypes.object,
  unregister: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  raiseNewRequestData: PropTypes.object,
  control: PropTypes.object,
  layersData: PropTypes.array,
  stations: PropTypes.array,
  sm: PropTypes.bool,
  //
  showIndex: PropTypes.bool,
  mainWrapperSx: PropTypes.object,
};

StationsTableResponsive.defaultProps = {
  showIndex: true,
};

export default StationsTableResponsive;
