import adminApi from "..";
import { queryBuilder } from "utils/ApiHelpers";

const adminReassignApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssignments: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/admin/assignments/getAssignments?${q}`,
        };
      },
      providesTags: ["ASSIGN-NEW-TECHNICIAN"],
    }),
    getUnAssignedTechnicians: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/admin/assignments/getNonAssignRequest?${q}`,
        };
      },
      providesTags: ["ASSIGN-NEW-TECHNICIAN"],
    }),
    getTechnicians: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/admin/assignments/getActiveTechnicians?${q}`,
        };
      },
      providesTags: ["ASSIGN-NEW-TECHNICIAN"],
    }),
    reassignTechnician: builder.mutation({
      query: (body) => ({
        url: "/api/admin/assignments/reAssign",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["History"],
    }),
    assignNewTechnician: builder.mutation({
      query: (body) => ({
        url: "/api/admin/assignments/adminAssign",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ASSIGN-NEW-TECHNICIAN"],
    }),
    getIsTechnicianAvailable: builder.query({
      query: (query) => {
        const q = queryBuilder(query);
        return {
          url: `/api/admin/assignments/isTechnicianAvailable?${q}`,
        };
      },
    }),
  }),
});

export const {
  useGetAssignmentsQuery,
  useLazyGetAssignmentsQuery,
  useGetTechniciansQuery,
  useLazyGetTechniciansQuery,
  useReassignTechnicianMutation,
  useLazyGetIsTechnicianAvailableQuery,
  useGetUnAssignedTechniciansQuery,
  useAssignNewTechnicianMutation,
} = adminReassignApi;

export default adminReassignApi;
