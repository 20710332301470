import { publicAssetsPath, isRouteFor } from "utils/Paths/Paths";
import { SIDEBAR_WIDTH } from "constants/Layout";
import { Link, useResolvedPath } from "react-router-dom";

import { Typography, useTheme, useMediaQuery, Stack } from "@mui/material";

import { Box } from "@mui/system";
import { handleOpenPdf } from "utils/File/File";
import PropTypes from "prop-types";

const Footer = (props) => {
  const path = useResolvedPath();

  const isAuthRoute =
    isRouteFor(path.pathname, "auth") || isRouteFor(path.pathname, "login");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: matches ? "flex" : "grid",
        gridTemplateColumns: isAuthRoute ? "1fr" : `${SIDEBAR_WIDTH} 1fr`,
        overflow: isAuthRoute ? "hidden" : "unset",
      }}
    >
      <Box
        sx={{
          gridColumn: isAuthRoute ? 1 : 2,
          padding: isAuthRoute ? "0rem 2rem" : "0rem 4rem",
          width: "100%",
        }}
      >
        <Box
          id="main-footer"
          marginTop={props.marginTop || "0"}
          sx={{
            display: "flex",
            justifyContent:
              isAuthRoute || matches ? "space-around" : "space-between",
            alignItems: "center",
            textAlign: "center",
            borderTop: `1px solid ${palette.borders.colorDark}`,
            paddingBlock: matches ? "3rem" : "1rem",
            height: matches ? "" : "7rem",
            gap: matches ? "2rem" : "1rem",
            flexWrap: "wrap",
          }}
        >
          <Stack direction={"row"} gap={2}>
            <Link to="/support">
              <Typography sx={{ fontSize: "1.4rem", color: "grayDark.color2" }}>
                الدعم و المساعدة
              </Typography>
            </Link>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={handleOpenPdf.bind(
                null,
                `${process.env.PUBLIC_URL}/terms-and-conditions.pdf`
              )}
            >
              <Typography sx={{ fontSize: "1.4rem", color: "grayDark.color2" }}>
                الشروط و الأحكام
              </Typography>
            </Box>
          </Stack>
          <Typography
            sx={{ fontSize: "1.4rem", color: "grayDark.color2", flexGrow: 1 }}
          >
            جميع الحقوق محفوظة لمنصة فحوصات © {new Date().getFullYear()}
          </Typography>
          <Box sx={{ display: "flex", gap: matches ? "1rem" : "7rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "grayDark.color2",
              }}
            >
              <img
                src={publicAssetsPath("icons/support-black.svg")}
                alt="logo"
                width="20rem"
                height="17rem"
              />
              <Typography sx={{ fontSize: "1.4rem", ml: 0.8 }}>
                <a href="tel:920008456" className="color-graydark">
                  920008456
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  marginTop: PropTypes.string,
};

export default Footer;
