import { Breadcrumbs, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import classes from "./BaseBreadCrumb.module.scss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const BaseBreadCrumb = (props) => {
  const { t } = useTranslation(["common", "dashboard"]);

  const isLabelString = (label) => typeof label == "string";

  return (
    <Breadcrumbs
      separator={
        <ChevronLeftIcon
          fontSize="medium"
          sx={{ color: "primary.colorSoft10", borderWidth: "1rem" }}
        />
      }
      aria-label="breadcrumb"
    >
      {props.data.map((link, idx, data) => {
        const isString = isLabelString(link.label);
        let lastItem = idx === data.length - 1;
        return lastItem ? (
          <Typography
            component={isString ? "" : "div"}
            key={link.to || idx}
            color={"grayDark.color1"}
            sx={{
              fontSize: "1.4rem",
              color: "grayDark.color1",
              ...link.style,
            }}
          >
            {isString ? t(link.label, { ns: link?.ns }) : link.label}
          </Typography>
        ) : (
          <Link
            component={isString ? "" : "div"}
            key={link.label}
            style={{
              color: "grayDark.color1",
              fontSize: "1.4rem",
              ...link.style,
            }}
            className={classes["base-breadcrumb__link"]}
            to={link.to}
          >
            {isString ? t(link.label, { ns: link?.ns }) : link.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

BaseBreadCrumb.propTypes = {
  data: PropTypes.array,
};

export default BaseBreadCrumb;
