import DashboardIntro from "components/Dashboard/DashboardIntro/DashboardIntro";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseDataItem from "core-ui/BaseDataItem/BaseDataItem";
import BaseDataWrapper from "core-ui/BaseDataWrapper/BaseDataWrapper";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import BaseButton from "core-ui/BaseButton/BaseButton";
import { useNavigate } from "react-router-dom";
import { SystemSettingsBreadcrumb } from "services/StaticLookup/Breadcrumb";
import { useGetConfigurationListQuery } from "modules/Admin/apis/configuration-apis/configuration-apis";
import { SystemConfigurationCodes } from "constants/Configuration";
import BaseLoader from "core-ui/BaseLoader/BaseLoader";
import { removeLeadingZeros } from "utils/Strings/Strings";

const SystemSettingsDetails = () => {
  const { t } = useTranslation(["dashboard"]);
  const navigate = useNavigate();

  const navigateToEditPage = () => navigate("/system-settings/edit");

  const {
    data: configurationData,
    isFetching: isConfigurationDataFetching,
    isLoading: isConfigurationDataLoading,
  } = useGetConfigurationListQuery(undefined, {
    refetchOnFocus: true,
  });

  const isLoading = isConfigurationDataFetching || isConfigurationDataLoading;

  const technicianTripDuration = configurationData?.find(
    (d) => d?.configCode === SystemConfigurationCodes.TRIP_DURATION
  );
  const technicianMaxRequests = configurationData?.find(
    (d) => d?.configCode === SystemConfigurationCodes.MAXIMUM_REQUESTS_PER_TRIP
  );
  const laboratoryMaxRequests = configurationData?.find(
    (d) =>
      d?.configCode === SystemConfigurationCodes.MAXIMUM_REQUESTS_PER_LABORATORY
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("system settings")}</title>
      </Helmet>

      <DashboardIntro
        title={t("system settings")}
        description={"يمكنك الاطلاع على إعدادات النظام"}
        action={
          <BaseButton
            onClick={navigateToEditPage}
            sx={{ minWidth: "17.3rem" }}
            isLoading={isLoading}
          >
            {t("edit")}
          </BaseButton>
        }
        breadcrumbData={SystemSettingsBreadcrumb()}
      />

      <BaseCard py={"3rem"} px={"5rem"} mt={6} minHeight={"75%"}>
        {isLoading ? (
          <BaseLoader />
        ) : (
          <>
            <BaseDataWrapper
              title={"فني الرقابة"}
              titleStyles={{ marginBottom: "3rem" }}
            >
              <BaseDataItem
                label={"مدة الرحلة"}
                value={`${technicianTripDuration?.newValue} ساعة`}
              />
              <BaseDataItem
                label={"الحد الأقصى من الطلبات"}
                valueComponentAs={"div"}
                value={technicianMaxRequests?.newValue}
              />
            </BaseDataWrapper>
            <hr />
            <BaseDataWrapper
              title={"المختبرات"}
              titleStyles={{ marginBottom: "3rem" }}
            >
              <BaseDataItem
                label={"الحد الاعلى للطلبات المقبولة يوميًا"}
                value={laboratoryMaxRequests?.newValue}
              />
            </BaseDataWrapper>
          </>
        )}
      </BaseCard>
    </>
  );
};

export default SystemSettingsDetails;
