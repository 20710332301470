import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BaseButton from "core-ui/BaseButton/BaseButton";

const ManageUserButtonAction = ({
  user,
  activateUser,
  deActivateUser,
  isUserActionSubmitting,
  showAlertHandler,
  setShowConfirmModal,
}) => {
  const [isUserAccountActive, setIsUserAccountActive] = useState(false);

  const { t } = useTranslation(["dashboard"]);

  useEffect(() => {
    setIsUserAccountActive(!!user?.isActive);
  }, [user]);

  return (
    <BaseButton
      sx={{ minWidth: "17.3rem" }}
      onClick={() => setShowConfirmModal(true)}
      variant={isUserAccountActive ? "secondary" : ""}
      isLoading={isUserActionSubmitting}
    >
      {isUserAccountActive ? t("deactivate account") : t("activate account")}
    </BaseButton>
  );
};

ManageUserButtonAction.propTypes = {
  user: PropTypes.object,
  activateUser: PropTypes.func,
  deActivateUser: PropTypes.func,
  isUserActionSubmitting: PropTypes.bool,
  showAlertHandler: PropTypes.func,
  setShowConfirmModal: PropTypes.func,
};

export default ManageUserButtonAction;
