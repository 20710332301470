import { AlertTypes } from "constants/AlertTypes";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { _scrollToTop } from "utils/DOM/DOM";
import RaiseNewRequestService from "../RaiseNewRequestService";
import { prepareTemplateLayers } from "utils/RaiseNewRequest/RaiseNewRequest";
import { convertFileToBase64 } from "utils/File/File";
import { calculateEndTime } from "utils/BookAppointment/BookAppointment";
import { valueChecker } from "utils/Numbers/Numbers";
import { baseCalendarDateFormatter } from "utils/Date/Date";
import { isContentReady } from "utils/Array/Arrays";
import { TestTypes } from "constants/TestTypes";
import { SELF_SUPERVISION } from "constants/UsersTypes";

export const addNewInspectionLayer = ({
  data,
  resetAndLeave,
  navigateAfterPostNewInsepction,
  onLeavePage,
  showAlert,
  isWalletAvailable,
}) => {
  const isLabAsphalt =
    data.licenseLayer?.inspectionTemplate === 2 &&
    data?.testType === TestTypes.LAB_TEST;

  const firstStageFormData = data?.[`stage-0-formData`];
  const secondStageFormData = data?.[`stage-1-formData`];
  const thirdStageFormData = data?.[`stage-2-formData`];
  const fourthStageFormData = data?.[`stage-3-formData`];

  const isSelfSupervision =
    firstStageFormData?.requestTypeId === SELF_SUPERVISION;

  const isField = data?.testType === TestTypes.FIELD_TEST;

  const licensesData = isField ? [data?.licenseData] : data?.multipleLicenses;

  // # data -> raiseNewRequestData
  const guards = ({ isWalletAvailable, showAlert, onLeavePage }) => {
    if (!isWalletAvailable()) {
      showAlert({
        show: true,
        message: ErrorCodes.CONTRACTOR_BALANCE,
        type: AlertTypes.ERROR,
      });
      _scrollToTop();
      onLeavePage();
      return false;
    }

    if (!isContentReady(data?.layersData)) {
      onLeavePage();
      return false;
    }

    if (!RaiseNewRequestService.isAppointmentTimeValid(data)) {
      showAlert({
        show: true,
        message: ErrorCodes.CONTRACTOR_APPOINTMENT_BOOKING,
        type: AlertTypes.WARNING,
      });
      _scrollToTop();
      return false;
    }
    return true;
  };

  // # data -> fourthStageFormData
  function getAppointmentData() {
    const { endTimeHours, endTimeMinutes } = calculateEndTime(
      fourthStageFormData.date,
      `${fourthStageFormData.time.$H}:${fourthStageFormData.time.$m}:00`
    );

    const scheduledTime = `${
      fourthStageFormData.time.$H < 10
        ? `0${fourthStageFormData.time.$H}`
        : fourthStageFormData.time.$H
    }:${
      fourthStageFormData.time.$m < 10
        ? `0${fourthStageFormData.time.$m}`
        : fourthStageFormData.time.$m
    }`;
    return { endTimeHours, endTimeMinutes, scheduledTime };
  }

  // # data -> raiseNewRequestData
  function newInspectionCommonData({
    data,
    selectedTemplateLayers,
    endTimeHours,
    endTimeMinutes,
    scheduledTime,
  }) {
    const labContractorMobileNumber = secondStageFormData?.contractorMobile;

    const fieldContractorMobileNumber =
      firstStageFormData?.contractorMobile ||
      data?.licenseData?.contractorMobileNumber;

    const contractorMobile =
      data.testType === TestTypes.LAB_TEST
        ? labContractorMobileNumber
        : fieldContractorMobileNumber;

    return {
      licenses: licensesData?.map((license) => {
        return {
          id: license.id,
          consultantName:
            // isSelfSupervision && // false
            // license?.isSelfSupervision && // false
            license?.consultantName // false
              ? license?.consultantName
              : firstStageFormData.consultantName, // <- HERE

          consultantMobileNumber:
            // isSelfSupervision &&
            // license?.isSelfSupervision &&
            license?.consultantMobile
              ? license?.consultantMobile
              : firstStageFormData?.consultantMobileNumber,

          consultantEmail:
            // isSelfSupervision &&
            // license?.isSelfSupervision &&
            license?.consultantEmail
              ? license?.consultantEmail
              : firstStageFormData?.consultantEmail,

          isSelfSupervision: isSelfSupervision,
        };
      }),
      testType: data.testType,
      selectedTemplateLayers,
      appointment: {
        scheduledTime,
        endTime: `${endTimeHours}:${endTimeMinutes}`,
        scheduledDate: baseCalendarDateFormatter(fourthStageFormData?.date),
      },
      ...(contractorMobile && {
        contractorMobileNumber: contractorMobile,
      }),
    };
  }

  async function onSubmitFieldTest(postNewInspectionFn) {
    if (!guards({ onLeavePage, showAlert, isWalletAvailable })) return;

    try {
      const { endTimeHours, endTimeMinutes, scheduledTime } =
        getAppointmentData();

      // layers data
      const selectedTemplateLayers = prepareTemplateLayers(secondStageFormData);

      // full inspection data
      let newInspectionData = newInspectionCommonData({
        data,
        selectedTemplateLayers,
        scheduledTime,
        endTimeHours,
        endTimeMinutes,
      });

      // ------------------------------------------------------------------------

      // posting new inspection data without attachment
      await postNewInspectionFn(newInspectionData)
        .unwrap()
        .then((response) => {
          resetAndLeave();
          navigateAfterPostNewInsepction(response);
        })
        .catch((err) => {
          _scrollToTop();
          showAlert({
            show: true,
            message:
              err?.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
            type: AlertTypes.ERROR,
          });
        });

      // ------------------------------------------------------------------------
    } catch (err) {
      _scrollToTop();
      await showAlert({
        show: true,
        message:
          err?.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
        type: AlertTypes.ERROR,
      });
    }
  }

  // data -> raiseNewRequest
  async function onSubmitLabTest(postNewInspectionFn) {
    if (!guards({ isWalletAvailable, showAlert, onLeavePage })) return;

    try {
      const { endTimeHours, endTimeMinutes, scheduledTime } =
        getAppointmentData();

      // ------------------------------------------------------------------------

      // layers data
      const selectedTemplateLayers = [
        {
          templateLayerId: data?.layersData[0].id,
          selectedForPayment: true, // 👈 this will always be true because we must pay for the only layer that user selected
          formatted_address:
            secondStageFormData?.soilLocation?.formattedAddress || null,
          latitude: secondStageFormData?.soilLocation?.lat || null,
          longitude: secondStageFormData?.soilLocation?.lng || null,
        },
      ];
      // ------------------------------------------------------------------------

      const inspectionSubTestTemplates = data?.subTests?.map((subTest) => {
        // prettier-ignore
        const minPassScore = valueChecker(thirdStageFormData[`minimumMixGrade-${subTest.id}`]);
        // prettier-ignore
        const maxPassScore = valueChecker(thirdStageFormData[`maximumMixGrade-${subTest.id}`]);
        return {
          ...subTest,
          id: subTest.id,
          name: subTest.name,
          minPassScore,
          maxPassScore,
        };
      });

      const inspectionTestTemplates = data?.testsTemplates?.map((test) => {
        // prettier-ignore
        const minPassScore = valueChecker(thirdStageFormData[`minimumSubTestGrade-${test.id}`]);
        // prettier-ignore
        const maxPassScore = valueChecker(thirdStageFormData[`maximumSubTestGrade-${test.id}`]);
        // prettier-ignore
        const singleScore = valueChecker(thirdStageFormData[`singleSubTestGrade-${test.id}`]);

        return {
          ...test,
          id: test.id,
          minPassScore,
          maxPassScore,
          singleScore,
          ...(test.containSubtests && {
            inspectionSubTestTemplates: inspectionSubTestTemplates,
          }),
        };
      });

      const aspeltMixDesignDetails = {
        gb: +secondStageFormData.Gb,
        gsb: +secondStageFormData.Gsb,
        gse: +secondStageFormData.Gse,
      };
      // ------------------------------------------------------------------------
      // full inspection data
      let newInspectionData = {
        ...(isLabAsphalt && {
          inspectionTestTemplates,
          aspeltMixDesignDetails,
        }),
        ...(secondStageFormData?.notes && {
          notes: secondStageFormData?.notes,
        }),

        ...(secondStageFormData?.supplier && {
          supplier: secondStageFormData?.supplier,
        }),

        ...newInspectionCommonData({
          data,
          selectedTemplateLayers,
          scheduledTime,
          endTimeHours,
          endTimeMinutes,
        }),

        aspeltGrade: secondStageFormData?.asphaltGrade,
        sampleLocationDescription:
          secondStageFormData?.soilLocation?.streetAddress,
      };

      // ------------------------------------------------------------------------

      // attachment data
      if (isLabAsphalt) {
        // convert file to base64
        convertFileToBase64(secondStageFormData["contractorAttach"].file).then(
          (base64File) => {
            newInspectionData = {
              ...newInspectionData,
              aspeltBaseCourse: base64File,
            };
            // posting new inspection data with attachment
            postNewInspectionFn(newInspectionData)
              .unwrap()
              .then((res) => {
                resetAndLeave();
                navigateAfterPostNewInsepction(res);
              })
              .catch((err) => {
                _scrollToTop();
                showAlert({
                  show: true,
                  message:
                    err?.data?.errorCode ||
                    ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
                  type: AlertTypes.ERROR,
                });
              });
          }
        );
      } else {
        // posting new inspection data without attachment
        await postNewInspectionFn(newInspectionData)
          .unwrap()
          .then((response) => {
            resetAndLeave();
            navigateAfterPostNewInsepction(response);
          })
          .catch((err) => {
            _scrollToTop();
            showAlert({
              show: true,
              message:
                err?.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
              type: AlertTypes.ERROR,
            });
          });
      }

      // ------------------------------------------------------------------------
    } catch (err) {
      _scrollToTop();
      await showAlert({
        show: true,
        message:
          err?.data?.errorCode || ErrorCodes.UNKNOWN_TRY_AGAIN_ERROR_CODE,
        type: AlertTypes.ERROR,
      });
    }
  }

  return {
    onSubmitFieldTest,
    onSubmitLabTest,
  };
};
