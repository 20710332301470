import { Box, useMediaQuery } from "@mui/material";
import { CertificateChecks } from "constants/CertificateChecks";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseMUITooltip from "core-ui/BaseTooltip/BaseMUITooltip";
import { useLazyGetCheckCertificateEligibleQuery } from "modules/Contractor/apis/inspection-apis/inspection-api";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

const AddCheckupRequestButton = (props) => {
  const { t } = useTranslation(["dashboard"]);
  const { palette, breakpoints } = useTheme();
  const matches = useMediaQuery(breakpoints.down("md"));

  const [getCheckCertificateEligible, getCheckCertificateEligibleResponse] =
    useLazyGetCheckCertificateEligibleQuery();

  useEffect(() => {
    if (props.licenseData?.licenseNumber) {
      getCheckCertificateEligible({
        licenseNumber: encodeURIComponent(props.licenseData?.licenseNumber),
        licenseYear: props.licenseData?.licenseYear,
      });
    }
  }, [props.licenseData]);

  const checkValue = getCheckCertificateEligibleResponse?.data;

  const canIssueCertificate = checkValue === CertificateChecks.CAN_RISE_REQUEST;

  const isCertificateButtonDisabled = !canIssueCertificate;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "end",
        justifyContent: matches ? "start" : "end",
        flexWrap: "wrap",
      }}
    >
      <BaseMUITooltip
        arrow
        backgroundColor="#6E6E6E"
        borderRadius="10px"
        color="white"
        title={
          isCertificateButtonDisabled ? t(checkValue, { ns: "errors" }) : ""
        }
      >
        <Box sx={{ display: "inline-block" }}>
          <BaseButton
            variant="secondary"
            sx={{
              width: "18rem",
              color: `${palette.text.tags.blueSoft}`,
            }}
            disabled={isCertificateButtonDisabled}
            onClick={() => props.setShowConfirmModal(true)}
          >
            {t("request certificate")}
          </BaseButton>
        </Box>
      </BaseMUITooltip>
      <BaseButton
        sx={{ width: "18rem" }}
        disabled={checkValue === CertificateChecks.HAVE_ANOTHER_REQUEST}
        onClick={props.onClick}
      >
        {t("request checkup")}
      </BaseButton>
    </Box>
  );
};

AddCheckupRequestButton.propTypes = {
  licenseData: PropTypes.object,
  onClick: PropTypes.func,
  setShowConfirmModal: PropTypes.func,
};

export default AddCheckupRequestButton;
