import BaseCard from "core-ui/BaseCard/BaseCard";
import { Box, Typography } from "@mui/material";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import PropTypes from "prop-types";

function SettingsForm({ userInfo, companyData }) {
  const address = `${companyData?.buildingNo}, ${companyData?.street}, ${companyData?.district}, ${companyData?.city} ${companyData?.postCode}`;

  return (
    <>
      <BaseCard
        py={"1.9rem"}
        px={"2.5rem"}
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Box className={"request-data-details"}>
          <Box className={"request-data-details__wrapper"}>
            <Typography className={"request-data-details__title"}>
              بيانات المستخدم
            </Typography>
            <WrapperContentGrid gap={4}>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  الاسم
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {userInfo?.name}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  رقم الهوية/الإقامة
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {userInfo?.nationalId}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  البريد الإلكتروني
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {userInfo?.email}
                </Typography>
              </Box>
            </WrapperContentGrid>
          </Box>
        </Box>
      </BaseCard>
      <BaseCard
        mt={3}
        py={"1.9rem"}
        px={"2.5rem"}
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Box className={"request-data-details"}>
          <Box className={"request-data-details__wrapper"}>
            <Typography className={"request-data-details__title"}>
              بيانات شركة المقاولات
            </Typography>
            <WrapperContentGrid gap={4}>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  اسم الشركة
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {companyData?.name}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  الرقم الموحد للمنشأة
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {companyData?.crNumber}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  الرقم الضريبي
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {companyData?.vatNumber}
                </Typography>
              </Box>
              <Box className={"request-data-details__item"}>
                <Typography className={"request-data-details__label"}>
                  العنوان
                </Typography>
                <Typography className={"request-data-details__value"}>
                  {address}
                </Typography>
              </Box>
            </WrapperContentGrid>
          </Box>
        </Box>
      </BaseCard>
    </>
  );
}

SettingsForm.propTypes = {
  userInfo: PropTypes.object,
  companyData: PropTypes.object,
};

export default SettingsForm;
