import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import DashboardDrillingLicensesData from "components/Dashboard/DashboardDrillingLicensesData/DashboardDrillingLicensesData";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseCard from "core-ui/BaseCard/BaseCard";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseDropdown from "core-ui/BaseDropdown/BaseDropdown";
import BaseInput from "core-ui/BaseInput/BaseInput";
import { useTranslation } from "react-i18next";
import { licenseYearsData } from "services/StaticLookup/LicensesYears";
import { contractorActions } from "store/contractor-slice/contractor-slice";
import BaseAlert from "core-ui/BaseAlert/BaseAlert";
import { UsersRolesEnum } from "constants/UsersTypes";
import BaseTableDoubleCard from "core-ui/BaseTable/Utilities/components/BaseTableDoubleCard/BaseTableDoubleCard";
import { multipleLicensesTableHeader } from "services/StaticLookup/TableHeader/TableHeader";
import CloseIcon from "@mui/icons-material/Close";
import { isContentReady } from "utils/Array/Arrays";
import { _scrollToTop } from "utils/DOM/DOM";
import LabConsultantData from "./LabConsultantData/LabConsultantData";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { AlertTypes } from "constants/AlertTypes";
import TimelineCallToActions from "../../../../components/TimelineCallToActions/TimelineCallToActions";
import PropTypes from "prop-types";
import { customValidateLicenseNumber } from "utils/Strings/Strings";
import { useTheme } from "@emotion/react";
import MultipleLicenseResponsiveTable from "./MultipleLicenseResponsiveTable";
import { isProduction, isUAT } from "utils/Env/Env";

const multipleLicensesActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

const MAX_MULTIPLE_LICENSES = 15;

const LabAddLicenseDetails = (props) => {
  const { t } = useTranslation(["auth", "dashboard", "common"]);
  const { breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));

  const isSearchVisible = true;

  const {
    raiseNewRequestData,
    formData,
    alert,
    dispatch,
    control,
    errors,
    register,
    unregister,
    setValue,
    dropdownRef,
    requestTypeId,
    consultantName,
    selectedLicenseData,
    licenseErrorMessage,
    indexShowAlertHandler,
    indexHideAlertHandler,
    initialMainDropdownData,
    contractorLicenseResponse,
    getContractorLicenseHandler,
  } = props;

  const isFlagged = isProduction || isUAT;

  const isSearchBtnDisabled =
    !!errors.licenseNumber ||
    !formData.licenseNumber ||
    !!contractorLicenseResponse.isLoading ||
    (isFlagged && !formData.licenseYear);

  const [multipleLicensesLastAction, setMultipleLicensesLastAction] =
    useState("");

  const resetFields = () => {
    setValue("licenseNumber", undefined);
    setValue("licenseYear", undefined);
  };

  useEffect(() => {
    resetFields();
  }, []);

  useEffect(() => {
    if (
      selectedLicenseData &&
      !isContentReady(raiseNewRequestData?.multipleLicenses) &&
      multipleLicensesLastAction !== multipleLicensesActions.REMOVE &&
      multipleLicensesLastAction !== multipleLicensesActions.ADD
    ) {
      getContractorLicenseHandler(
        selectedLicenseData.licenseNumber,
        selectedLicenseData.licenseYear
      );
      if (isConsultantNotAllowed(selectedLicenseData)) {
        displayError(ErrorCodes.CONSULTANT_INFO_NEEDED);
      }

      setValue("licenseNumber", selectedLicenseData.licenseNumber);

      setValue("licenseYear", {
        id: licenseYearsData().find((year) => {
          return +year.id === +selectedLicenseData.licenseYear;
        })?.id,
        name: selectedLicenseData.licenseYear,
      });
    }
  }, [selectedLicenseData]);

  const onCancelHandler = () => {
    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          licenseData: null,
        },
      })
    );
  };

  function isConsultantNotAllowed(data) {
    const consultantNotAllowed =
      data?.requestTypeId === UsersRolesEnum.CONSULTANT &&
      !raiseNewRequestData?.licenseData?.consultant &&
      !raiseNewRequestData?.licenseData?.hasPendingRequest;

    return consultantNotAllowed;
  }
  function displayError(message) {
    _scrollToTop();
    indexShowAlertHandler({
      show: true,
      message: message,
      type: AlertTypes.ERROR,
    });
  }

  function onAddLicenseHandler(newLicense = undefined) {
    setMultipleLicensesLastAction(multipleLicensesActions.ADD);
    const currentAddedLicense = newLicense || raiseNewRequestData?.licenseData;

    const isLicenseAlreadyAdded = raiseNewRequestData?.multipleLicenses?.find(
      (license) => license.id === currentAddedLicense?.id
    );

    if (isLicenseAlreadyAdded || isConsultantNotAllowed(formData)) {
      let message = () => {
        if (isLicenseAlreadyAdded) {
          return ErrorCodes.LICENSE_ALREADY_ADDED;
        } else if (isConsultantNotAllowed(formData)) {
          return ErrorCodes.CONSULTANT_INFO_NEEDED;
        } else return "";
      };
      displayError(message());
      return;
    }

    if (areLicensesExceededTheMaximum) return;

    dispatch(
      contractorActions.saveRaiseNewRequestData({
        data: {
          multipleLicenses: (
            raiseNewRequestData?.multipleLicenses || []
          ).concat(newLicense || raiseNewRequestData?.licenseData),
          licenseData: null,
        },
      })
    );

    indexHideAlertHandler();

    unregister("consultantName");
    unregister("consultantEmail");
    unregister("consultantMobileNumber");
    resetFields();
  }

  const onRemoveLicenseRowHandler = ({ id }) => {
    setMultipleLicensesLastAction(multipleLicensesActions.REMOVE);
    dispatch(
      contractorActions?.saveRaiseNewRequestData({
        data: {
          multipleLicenses: raiseNewRequestData?.multipleLicenses?.filter(
            (license) => license.id !== id
          ),
        },
      })
    );
  };

  const isAddButtonDisabled = () => {
    if (raiseNewRequestData?.licenseData) {
      if (areLicensesExceededTheMaximum) return true;

      if (raiseNewRequestData?.licenseData?.consultantEmail) {
        return (
          requestTypeId === UsersRolesEnum.CONSULTANT &&
          raiseNewRequestData.licenseData.hasPendingRequest
        );
      } else {
        return (
          !formData?.consultantName ||
          !formData?.consultantEmail ||
          !formData?.consultantMobileNumber ||
          !!errors?.consultantName ||
          !!errors?.consultantEmail ||
          !!errors?.consultantMobileNumber
        );
      }
    } else {
      return true;
    }
  };

  const multipleLicenses = raiseNewRequestData?.multipleLicenses;

  const noMultipleLicenses =
    !multipleLicenses || !isContentReady(multipleLicenses);

  const areLicensesExceededTheMaximum =
    multipleLicenses?.length >= MAX_MULTIPLE_LICENSES;

  const actionRowLabel = areLicensesExceededTheMaximum
    ? "لقد وصلت إلى الحد الأقصى لعدد الرخص المضافة"
    : "يمكنك البحث عن رخصة أخرى و إضافتها إلى قائمة الرخص المضافة";

  return (
    <>
      <Box>
        <BaseCardTitle mb={1}>إضافة رخص الحفر</BaseCardTitle>
        <Typography mb={3} fontSize={"1.6rem"} color={"grayDark.color"}>
          يرجى تعبئة النموذج التالي وإضافة الرخصة إلى قائمة الرخص المضافة
        </Typography>
        <BaseCard variant="primary" px={"4rem"} py={"3rem"}>
          <Grid container>
            <Grid item xs={12} mb={5}>
              {isSearchVisible && (
                <Box>
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    alignItems={"flex-start"}
                    gap="2rem"
                  >
                    <BaseInput
                      inputContainerStyles={{
                        flexGrow: "1",
                        flexBasis: "24rem",
                        alignSelf: "end",
                      }}
                      inputStyles={{
                        direction: "ltr",
                        textAlign: "end",
                      }}
                      htmlFor="licenseNumber"
                      label={"رقم الرخصة"}
                      placeholder={"ادخل رقم الرخصة"}
                      name="licenseNumber"
                      register={{
                        ...register("licenseNumber", {
                          required: {
                            value: noMultipleLicenses,
                            message: `${t("field")} ${t("license number", {
                              ns: "dashboard",
                            })} ${t("required")}`,
                          },
                          maxLength: {
                            value: 15,
                            message: `لا يمكن لرقم الرخصة ان يتجاوز 15 حرف`,
                          },
                          minLength: {
                            value: 4,
                            message: `يجب ان لا يقل رقم الرخصة عن 4 احرف`,
                          },
                          validate: customValidateLicenseNumber,
                        }),
                      }}
                      errors={errors}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "2rem",
                        flexGrow: 1,
                      }}
                    >
                      <BaseDropdown
                        control={control}
                        withReset
                        required={!!(isFlagged && formData.licenseNumber)}
                        name="licenseYear"
                        dropdownRef={dropdownRef}
                        value={formData?.licenseYear || {}}
                        errors={errors}
                        label={t(initialMainDropdownData.label)}
                        placeholder={initialMainDropdownData.placeholder}
                        data={initialMainDropdownData.data}
                        width={"14rem"}
                        labelStyles={{
                          color: "grayDark.color",
                          fontSize: "1.4rem",
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                        mainStyles={{
                          flexGrow: 1,
                        }}
                      />
                      <BaseButton
                        sx={{
                          minWidth: "8rem",
                          alignSelf: "end",
                          flexGrow: 1,
                          height: "4rem",
                        }}
                        variant={isSearchBtnDisabled ? "disabled" : "secondary"}
                        disabled={isSearchBtnDisabled}
                        isLoading={!!contractorLicenseResponse.isLoading}
                        onClick={() =>
                          getContractorLicenseHandler(undefined, undefined)
                        }
                      >
                        بحث
                      </BaseButton>
                    </Box>
                  </Stack>

                  {licenseErrorMessage && (
                    <Box mt={3}>
                      <BaseAlert
                        show={alert.show}
                        type={alert.type}
                        message={alert.message}
                        icon={false}
                        mt={2}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} mb={5}>
              <Typography
                sx={{ fontSize: "1.4rem", fontWeight: "700" }}
                color={"grayDark.color8"}
                mb={2}
              >
                بيانات رخصة الحفر
              </Typography>
              <BaseCard
                variant="secondary"
                py={"0rem"}
                px={"0rem"}
                borderRadius=".9rem"
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ minHeight: "" }}
              >
                <DashboardDrillingLicensesData
                  licenseErrorMessage={licenseErrorMessage}
                  data={raiseNewRequestData?.licenseData}
                  columns={11}
                />
              </BaseCard>
            </Grid>
            <Grid item xs={12} mb={5}>
              <Typography
                sx={{ fontSize: "1.4rem", fontWeight: "700" }}
                color={"grayDark.color8"}
                mb={2}
              >
                بيانات الإشراف
              </Typography>
              <Box>
                <LabConsultantData
                  setValue={setValue}
                  requestTypeId={requestTypeId}
                  isSearchVisible={isSearchVisible}
                  data={raiseNewRequestData?.licenseData}
                  consultantForm={{
                    register: register,
                    errors: errors,
                    control: control,
                    consultantName: consultantName,
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            display={"flex"}
            justifyContent={sm ? "start" : "end"}
            flexWrap={"wrap"}
            gap={3}
          >
            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              onClick={onCancelHandler}
              variant={"secondary"}
              disabled={!raiseNewRequestData?.licenseData}
            >
              إلغاء
            </BaseButton>

            <BaseButton
              sx={{ minWidth: "12.2rem" }}
              onClick={onAddLicenseHandler.bind(null, undefined)}
              disabled={isAddButtonDisabled()}
            >
              إضافة
            </BaseButton>
          </Box>
        </BaseCard>
        <Box my={5}>
          {sm ? (
            <MultipleLicenseResponsiveTable
              actionRowLabel={actionRowLabel}
              areLicensesExceededTheMaximum={areLicensesExceededTheMaximum}
              noMultipleLicenses={noMultipleLicenses}
              content={raiseNewRequestData?.multipleLicenses || []}
              onRemove={onRemoveLicenseRowHandler}
            />
          ) : (
            <BaseTableDoubleCard
              title={"الرخص المضافة"}
              tableHeader={multipleLicensesTableHeader}
              emptyData={{ title: "ستظهر هنا جميع الرخص التي تتم إضافتها" }}
              content={raiseNewRequestData?.multipleLicenses || []}
              withSpecificAction={() => {
                return {
                  ignoreState: true,
                  condition: true,
                  component: (
                    <CloseIcon
                      fontSize="large"
                      sx={{
                        color: "primary.colorSoft5",
                        fontWeight: "bold",
                        cursor: "grab",
                        mt: 1,
                        mr: 1,
                      }}
                    />
                  ),
                  action: onRemoveLicenseRowHandler,
                };
              }}
              withActionRow={
                !noMultipleLicenses && {
                  actionComponent: (
                    <Typography
                      sx={{
                        fontSize: "1.4rem",
                        fontWeight: 500,
                        color: areLicensesExceededTheMaximum
                          ? "red"
                          : "primary.colorSoft13",
                        cursor: "grab",
                      }}
                    >
                      {actionRowLabel}
                    </Typography>
                  ),
                }
              }
            />
          )}
        </Box>
      </Box>
      <TimelineCallToActions
        timeline={props.timeline}
        isPrevButtonDisabled={contractorLicenseResponse.isLoading}
        isNextButtonDisabled={noMultipleLicenses}
      />
    </>
  );
};

LabAddLicenseDetails.propTypes = {
  timeline: PropTypes.object,
  raiseNewRequestData: PropTypes.object,
  formData: PropTypes.object,
  alert: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
  dropdownRef: PropTypes.object,
  requestTypeId: PropTypes.string,
  consultantName: PropTypes.object,
  licenseErrorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  initialMainDropdownData: PropTypes.object,
  contractorLicenseResponse: PropTypes.object,
  getContractorLicenseHandler: PropTypes.func,
  dispatch: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  selectedLicenseData: PropTypes.object,
  indexShowAlertHandler: PropTypes.func,
  indexHideAlertHandler: PropTypes.func,
};

export default LabAddLicenseDetails;
