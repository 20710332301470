export const UsersTypes = [
  "contractor",
  "inspector",
  "laboratory",
  "resaa",
  "center",
  "consultant",
];

export const UsersRoles = [
  "SYSOPS",
  "CONSULTANT",
  "TECHNICIAN",
  "SYSADMIN",
  "LAB_TECHNICIAN",
  "OP_ADMIN",
  "OP_SUPP_ADMIN",
  "QA_QC_ADMIN",
  "QA_QC_SUPP_ADMIN",
];

export const UsersTypesEnum = {
  DEFAULT: "contractor",
  CONTRACTOR: "contractor",
  INSPECTOR: "inspector",
  LABORATORY: "laboratory",
  RESAA: "resaa",
  CENTER: "center",
  CONSULTANT: "consultant",
};

export const UsersRolesEnum = {
  SYSOPS: "SYSOPS",
  CONSULTANT: "CONSULTANT",
  TECHNICIAN: "TECHNICIAN",
  SYSADMIN: "SYSADMIN",
  LAB_TECHNICIAN: "LAB_TECHNICIAN",
  OP_ADMIN: "OP_ADMIN",
  OP_SUPP_ADMIN: "OP_SUPP_ADMIN",
  QA_QC_ADMIN: "QA_QC_ADMIN",
  QA_QC_SUPP_ADMIN: "QA_QC_SUPP_ADMIN",
};

export const SELF_SUPERVISION = "SELF_SUPERVISION";
