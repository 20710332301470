import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ValidEmailPattern } from "constants/Forms";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseInput from "core-ui/BaseInput/BaseInput";
import WrapperContentGrid from "core-ui/WrapperContentGrid/WrapperContentGrid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { customValidateMobileNumber } from "utils/Forms/Forms";

const AddConsultantForm = ({ title = true, ...props }) => {
  const { t } = useTranslation(["auth", "dashboard"]);

  return (
    <Box>
      {title && (
        <BaseCardTitle sx={{ fontSize: "1.6rem" }}>
          {" "}
          بيانات المشرف{" "}
        </BaseCardTitle>
      )}
      <Typography mb={5} sx={{ fontSize: "1.4rem" }}>
        {"يرجى تعبئة بيانات المشرف المسؤول التابع لمقدم الخدمة"}
      </Typography>
      <WrapperContentGrid gap={2}>
        <BaseInput
          inputContainerStyles={{ marginBottom: "0 !important" }}
          htmlFor="consultantName"
          label={t("auth:name")}
          name="consultantName"
          register={{
            ...props.register("consultantName", {
              required: {
                value: true,
                message: `${t("field")} ${t("auth:consultantName")} ${t(
                  "required"
                )}`,
              },
              validate: () => {
                let maxLength = 60;
                if (props.consultantName?.length > maxLength) {
                  return `لقد تجاوت الحد المسموح به`;
                }
              },
            }),
          }}
          errors={props.errors}
        />
        <BaseInput
          htmlFor="consultantMobileNumber"
          label={"رقم الجوال"}
          placeholder="05 xxx xxxx"
          labelStyles={{
            fontWeight: "bold",
            color: "grayDark.color",
          }}
          name="consultantMobileNumber"
          register={{
            ...props.register("consultantMobileNumber", {
              required: {
                value: true,
                message: "حقل رقم الجوال مطلوب",
              },
              validate: customValidateMobileNumber,
            }),
          }}
          errors={props.errors}
        />
        <BaseInput
          inputContainerStyles={{ marginBottom: "0 !important" }}
          type="email"
          htmlFor="consultantEmail"
          label={t("auth:email")}
          name="consultantEmail"
          register={{
            ...props.register("consultantEmail", {
              required: {
                value: true,
                message: `${t("field")} ${t("auth:email")} ${t("required")}`,
              },
              pattern: {
                value: ValidEmailPattern,
                message: `${t("Invalid email address")}`,
              },
            }),
          }}
          errors={props.errors}
        />
      </WrapperContentGrid>
    </Box>
  );
};

AddConsultantForm.propTypes = {
  title: PropTypes.string,
  register: PropTypes.func,
  consultantName: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.object,
};

export default AddConsultantForm;
